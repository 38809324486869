import { If, Else, Then } from "react-if";
import { Button, Link } from "@mui/material";

import { snakeCase } from "utils/formats";

type InsightCardContentProps = {
  title: string;
  description: string;
  image: string;
  button: string;
  modal?: string;
  url?: string;
  handleOnClick: () => void;
};

const InsightCardContent = ({
  title,
  description,
  image,
  button,
  modal,
  url,
  handleOnClick
}: InsightCardContentProps) => {
  const buttonStyles = "self-start w-max";

  const imageUrl = new URL(`../../assets/images/insights/${snakeCase(image)}.svg`, import.meta.url).href;

  return (
    <>
      <h4 className="text-xl mb-4">{title}</h4>

      <div className="flex items-stretch justify-between gap-2 h-full">
        <div className="flex flex-col justify-between gap-2 basis-6/10">
          <p className="text-sm font-medium">{description}</p>

          <If condition={!!modal && modal.length > 0}>
            <Then>
              <Button
                variant="tertiary"
                className={buttonStyles}
                onClick={handleOnClick}
              >
                {button}
              </Button>
            </Then>

            <Else>
              <Button
                component={Link}
                variant="tertiary"
                className={buttonStyles}
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {button}
              </Button>
            </Else>
          </If>
        </div>

        <div className="flex basis-4/10">
          <img
            className="w-full h-auto"
            alt={image}
            src={imageUrl}
          />
        </div>
      </div>
    </>
  );
};

export default InsightCardContent;