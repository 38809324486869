/**
 * Enum for institution payment type
 *
 * TUITION, ROOM_AND_BOARD, FEES
 */
export enum InstitutionPaymentType {
  TUITION = "TUITION",
  ROOM_AND_BOARD = "ROOM_AND_BOARD",
  FEES = "FEES"
}

/**
 * Enum for institution payment status type
 *
 * CANCELLED, EXPIRED, RECEIVED, PENDING, SENT
 */
export enum InstitutionPaymentStatus {
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  RECEIVED = "RECEIVED",
  PENDING = "PENDING",
  SENT = "SENT"
}