import React, { FC } from "react";
import { Box, Stack, Typography, stackClasses, typographyClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";

const Container = styled(Stack)(({ theme }) => ({
  padding: "1.5rem",
  borderRadius: "10px",
  boxShadow: "0 2px 4px 0 rgba(4, 4, 52, .06), 0 0 2px 0 rgba(91, 95, 107, .24)",
  [theme.breakpoints.down("md")]: {
    padding: "1rem",
  },
}));

const ContainerWithHeader = styled(Container)({
  [`&.${stackClasses.root}`]: {
    padding: 0,
  },
});

// Note [Facundo, 5/24]: Standarize colors and define them in the global theme
const Header = styled(Box)({
  backgroundColor: "#FCFCFC",
  display: "flex",
  justifyContent: "space-between",
  padding: "0.8rem 1rem",
  borderBottom: "1px solid #F5F5F5",
});

const Title = styled(Box)({
  display: "flex",
  gap: "0.8rem",
  [`& ${typographyClasses.root}`]: {
    fontWeight: "bold",
  },
});

interface CardProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  defaultOpen?: boolean;
  stylesOverride?: React.CSSProperties;
  className?: string;
}

const Card: FC<CardProps> = ({
  icon,
  title,
  defaultOpen,
  children,
  stylesOverride,
  className
}) => {
  // TODO: Add animation
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpen || false);
  const hasHeader = icon || title;

  if (hasHeader) {
    return (
      <ContainerWithHeader className={className} sx={stylesOverride}>
        <Header borderRadius={isOpen ? "1rem 1rem 0 0" : "1rem"}>
          <Title>
            {icon}
            {typeof title === "string" ? <Typography fontWeight="bold">{title}</Typography> : title}
          </Title>
          {isOpen ? (
            <ExpandLessIcon onClick={() => setIsOpen(false)} />
          ) : (
            <ExpandMoreIcon onClick={() => setIsOpen(true)} />
          )}
        </Header>
        {isOpen && children}
      </ContainerWithHeader>
    );
  }

  return (
    <Container className={clsx("card-old", className)} sx={stylesOverride}>
      {children}
    </Container>
  );
};

export default Card;
