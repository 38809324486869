const TransactionLoadingSkeleton = () => {
  return (
    <div
      role="status"
      className="max-w-screen-md rounded shadow animate-pulse"
    >
      <div className="p-4 md:p-6 flex items-center justify-between border-b border-gray-200">
        <div
          className="w-28 h-2.5 bg-gray-300 rounded-full"
        ></div>
      </div>

      <div className="px-4 pb-4 md:px-6 md:pb-6 space-y-4 divide-y divide-gray-200">
        <div className="flex md:items-center justify-between gap-2 md:gap-4 pt-4">
          <div
            className="w-40 h-2.5 bg-gray-300 rounded-full"
          ></div>

          <div className="flex max-sm:flex-col md:items-center gap-2 md:gap-4">
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full max-sm:hidden"></div>
          </div>
        </div>

        <div className="flex md:items-center justify-between gap-2 md:gap-4 pt-4">
          <div
            className="w-40 h-2.5 bg-gray-300 rounded-full"
          ></div>

          <div className="flex max-sm:flex-col md:items-center gap-2 md:gap-4">
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full max-sm:hidden"></div>
          </div>
        </div>

        <div className="flex justify-between gap-2 md:gap-4pt-4">
          <div
            className="w-40 h-2.5 bg-gray-300 rounded-full"
          ></div>

          <div className="flex max-sm:flex-col md:items-center gap-2 md:gap-4">
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full"></div>
            <div className="w-28 h-2.5 bg-gray-200 rounded-full max-sm:hidden"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionLoadingSkeleton;