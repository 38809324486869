import * as Sentry from "@sentry/react";
import instance from "config/fetch.config";
import type { AccountOwner } from "@lib/types/account";
import { errorMapper } from "utils/errors";

class AuthServices {
  static async requestMFACode(phone_number: string): Promise<string> {
    try {
      const {
        data: { data },
      } = await instance.post("/mfa-codes", { phone_number });
      return data.mfa_code;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError, "UNEXPECTED_ERROR_LOGIN");
    }
  }

  static async login(
    phone_number: string,
    mfa_code: string,
  ): Promise<{ account_owner: AccountOwner; token: string; }> {
    try {
      const {
        data: { data },
      } = await instance.post("/login", { phone_number, mfa_code });

      return data;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError, "UNEXPECTED_ERROR_LOGIN");
    }
  }
}

export default AuthServices;
