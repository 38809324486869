import type { FilterByType } from "@components/Transactions/TransactionsTableFilters";
import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import type { DateRange } from "@lib/types/filter";
import type { Transaction } from "@lib/types/transaction";
import type { TransactionFilters } from "@stores/transactionsStore";

type FilterTransactionsProps = {
  filters: TransactionFilters;
  transactions: Transaction[];
}

type FilterTransactionsByType = {
  type: FilterByType;
  transactions: Transaction[];
}

type FilterTransactionsByDate = {
  dateRange: DateRange;
  transactions: Transaction[];
}

const filterTransactionsByType = ({
  type,
  transactions
}: FilterTransactionsByType) => {
  if (type === InstitutionPaymentStatus.PENDING) {
    return [...transactions].filter((transaction) => ("institution" in transaction) && transaction.status === InstitutionPaymentStatus.PENDING);
  }

  return [...transactions].filter((transaction) => transaction.type === type);
};

const removeTime = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

const filterTransactionsByDate = ({
  dateRange,
  transactions
}: FilterTransactionsByDate) => {
  return [...transactions].filter((transaction) => {
    const createdAtDate = new Date(transaction.created_at);
    const createdAt = removeTime(createdAtDate);

    const startDate = dateRange.startDate ? removeTime(dateRange.startDate) : null;
    const endDate = dateRange.endDate ? removeTime(dateRange.endDate) : null;

    if (startDate && endDate) {
      return (createdAt >= startDate) && (createdAt <= endDate);
    }

    // if no endDate, filter only for startDate
    if (startDate && !endDate) {
      return createdAt.getTime() === startDate.getTime();
    }

    // // if no startDate, filter only for endDate
    if (!startDate && endDate) {
      return createdAt.getTime() === endDate.getTime();
    }

    // if there are no dates, return the transaction
    return true;
  });
};

export const filterTransactions = ({
  filters,
  transactions
}: FilterTransactionsProps) => {
  let filteredTransactions = [...transactions];

  for (const [filter, filterValue] of Object.entries(filters)) {
    if (filter === "type" && filterValue !== "ALL") {
      filteredTransactions = filterTransactionsByType({
        type: filterValue as FilterByType,
        transactions: filteredTransactions
      });
    }

    if (filter === "date" && filterValue !== null) {
      filteredTransactions = filterTransactionsByDate({
        dateRange: filterValue as DateRange,
        transactions: filteredTransactions,
      });
    }

    if (filter === "year" && filterValue !== null) {
      filteredTransactions = filterTransactionsByDate({
        dateRange: {
          startDate: new Date(`01-01-${filterValue}`),
          endDate: new Date(`12-31-${filterValue}`),
        },
        transactions: filteredTransactions,
      });
    }
  }

  return filteredTransactions;
};