import React, { useState, FC, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
  autocompleteClasses,
  stackClasses,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ButtonGroup from "commons/Button/ButtonGroup";
import Spinner from "@components/Spinner/Spinner";

import ApplicationServices from "services/application.services";

import { useGlobalStore } from "@stores/globalStore";

import { getInstitutions } from "@lib/queries/institutionQueries";
import { getState529Plans } from "@lib/queries/state529Queries";
import { QUERY_KEY } from "@lib/queries/constants";

import { PlanType } from "@lib/enums/onboarding";
import type { Questionnaire } from "@lib/types/onboarding";

const InputBlock = styled(Stack)({
  justifyContent: "space-between",
  gap: "0.5rem",
  [`& .${stackClasses.root}`]: {
    flexGrow: "1",
  },
  [`&.${stackClasses.root}`]: {
    marginTop: "0.3rem",
  },
});

const AutocompleteBlock = styled(Stack)({
  [`&.${stackClasses.root}`]: {
    marginTop: "0.3rem",
  },
  [`& .${autocompleteClasses.root}`]: {
    marginTop: "0",
  },
});

interface Details529PropTypes {
  handleNext: any;
  applicationId: string;
}

const Details529: FC<Details529PropTypes> = ({
  handleNext,
  applicationId,
}) => {
  const setInitialState = useGlobalStore((state) => state.setInitialState);

  const {
    data: state529Plans = [],
    isLoading: isState529PlansLoading,
    isError: isState529PlansError,
    error: State529PlansError,
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_PLANS],
    queryFn: getState529Plans,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const {
    data: institutions = [],
    isLoading: isInstitutionsLoading,
    isError: isInstitutionsError,
    error: InstitutionsError,
  } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTIONS],
    queryFn: getInstitutions,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  // 529 plan
  const [plan, setPlan] = useState<string | null>(null);
  const [planType, setPlanType] = useState<PlanType | null>(null);
  const [institution, setInstitution] = useState<string | null>("");

  // beneficiary details
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({
    firstName: "",
    lastName: "",
    studentId: "",
  });

  const [modelOpen, setModelOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    plan: false,
    planType: false,
    institution: false,
  });
  const beneficiaryText =
    "We don't support beneficiary accounts yet, but we're working on it! Please check back soon.";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBeneficiaryDetails({ ...beneficiaryDetails, [e.target.name]: e.target.value });
  };

  const handleBeneficiary = (event: React.MouseEvent<HTMLElement>) => {
    event.currentTarget.blur();
    setModelOpen(true);
  };

  const handleCloseModal = () => {
    setModelOpen(false);
  };

  const handlePlanType = (event: React.MouseEvent<HTMLElement>, newPlanType: PlanType | null) => {
    if (newPlanType === "BENEFICIARY_ONLY") return handleBeneficiary(event);
    setErrors({ ...errors, planType: false });
    setPlanType(newPlanType);
  };

  const validateFormCompletion = () => {
    let hasError = false;
    const addError = (field: string) => {
      hasError = true;
      setErrors((state) => ({ ...state, [field]: true }));
    };

    if (!beneficiaryDetails.firstName) addError("firstName");

    if (!beneficiaryDetails.lastName) addError("lastName");

    if (!plan) addError("plan");

    if (!planType) addError("planType");

    if (!institution) addError("institution");

    return hasError;
  };

  const handleFormSubmit = async () => {
    setError("");
    if (validateFormCompletion()) return;

    if (!state529Plans?.length || !institutions?.length) {
      setError("Required data is missing.");
      return;
    }

    const questionnaire: Questionnaire = {
      // [Santucho: 01/25/2024]: The exclamation is because there is already a validation a few lines up that
      // makes sure that plan and planType are not null.
      state_529_plan_type: planType!,
      state_529_plan_id: state529Plans.find((plan529) => plan529.name === plan)!.id,
      beneficiary_first_name: beneficiaryDetails.firstName,
      beneficiary_last_name: beneficiaryDetails.lastName,
      beneficiary_student_id: beneficiaryDetails.studentId,
      institution_id: institutions.find((dbInstitution) => institution === dbInstitution.name)!
        .id,
    };

    try {
      setLoading(true);
      await ApplicationServices.sendQuestionnaire(questionnaire, applicationId);
      setLoading(false);
      handleNext();
    } catch (err: any) {
      setLoading(false);
      setError(err);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [e.target.name]: !e.target.value });
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [e.target.name]: false });
  };

  const isDisabled = () => {
    return (
      !beneficiaryDetails.firstName ||
      !beneficiaryDetails.lastName ||
      !plan ||
      !planType ||
      !institution ||
      loading
    );
  };

  useEffect(() => {
    if (!!state529Plans?.length && !!institutions?.length) {
      setInitialState({
        state_529_plans: state529Plans,
        institutions
      });
    }
  }, [state529Plans, institutions]);

  // [SM - 09/29/2024]: update this to show the error in a toast
  if (isInstitutionsError || isState529PlansError) {
    const error_message = InstitutionsError || State529PlansError;
    console.error(error_message);
  }

  if (isState529PlansLoading || isInstitutionsLoading) return <div className="h-dvh"><Spinner size="lg" /></div>;

  return (
    <React.Fragment>
      <FormControl>
        <Stack spacing={5} textAlign="left">
          <Stack spacing={2}>
            <Typography variant="action">529 Details</Typography>
            <Stack spacing={2}>
              <Typography>Choose the 529 plan you will use for this beneficiary</Typography>
              <AutocompleteBlock>
                <Autocomplete
                  disablePortal
                  value={plan}
                  // @ts-ignore
                  onChange={(e, newValue) => setPlan(newValue)}
                  options={state529Plans.map((plan) => plan.name)}
                  data-cy="details-plan"
                  renderInput={(params) => (
                    <TextField
                      data-cy="plan-input"
                      name="plan"
                      onBlur={onBlur}
                      onFocus={onFocus}
                      {...params}
                      label="Type to search or select a provider..."
                      error={errors.plan}
                      helperText={errors.plan && "Error: Required Field"}
                    />
                  )}
                  sx={{ marginTop: "0.5rem" }}
                />
              </AutocompleteBlock>

              <Typography>What is your relationship to the 529 Plan?</Typography>

              <ButtonGroup
                value={planType}
                data-cy="planType-button"
                exclusive
                onChange={(e, newValue) => handlePlanType(e, newValue as PlanType)}
                error={errors.planType}
                buttons={[
                  {
                    value: "OWNER_ONLY",
                    displayName: "Owner",
                  },
                  {
                    value: "BENEFICIARY_ONLY",
                    displayName: "Beneficiary",
                  },
                  {
                    value: "OWNER_AND_BENEFICIARY",
                    displayName: "Both",
                  },
                ]}
              />
            </Stack>
          </Stack>

          {/* Second section: Beneficiary Details */}

          <Stack spacing={1}>
            <Typography variant="action">Beneficiary Details</Typography>
            <Stack spacing={2}>
              <Typography>Education Institution</Typography>
              <AutocompleteBlock>
                <Autocomplete
                  disablePortal
                  value={institution}
                  // @ts-ignore
                  onChange={(e, newValue) => setInstitution(newValue)}
                  options={institutions.map((institution) => institution.name)}
                  data-cy="details-institution"
                  renderInput={(params) => (
                    <TextField
                      name="institution"
                      data-cy="institution-input"
                      onBlur={onBlur}
                      onFocus={onFocus}
                      {...params}
                      label="Type to search or select an institution..."
                      error={errors.institution}
                      helperText={errors.institution && "Error: Required Field"}
                    />
                  )}
                />
              </AutocompleteBlock>

              <Typography>Student’s name</Typography>
              <InputBlock direction="row" spacing={2}>
                <Stack spacing={1}>
                  <TextField
                    value={beneficiaryDetails.firstName}
                    data-cy="firstName-input"
                    name="firstName"
                    placeholder="First name"
                    required
                    onChange={handleChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={errors.firstName}
                    helperText={errors.firstName && "Error: Required Field"}
                  />
                </Stack>
                <Stack spacing={1}>
                  <TextField
                    required
                    value={beneficiaryDetails.lastName}
                    name="lastName"
                    data-cy="lastName-input"
                    placeholder="Last name"
                    onChange={handleChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={errors.lastName}
                    helperText={errors.lastName && "Error: Required Field"}
                  />
                </Stack>
              </InputBlock>
              <Typography>Student ID</Typography>
              <InputBlock spacing={1}>
                <TextField
                  value={beneficiaryDetails.studentId}
                  data-cy="studentId-input"
                  name="studentId"
                  placeholder="Optional"
                  onChange={handleChange}
                />
              </InputBlock>
            </Stack>
          </Stack>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            disabled={isDisabled()}
            data-cy="details-button"
            sx={{ alignSelf: "center" }}
          >
            {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : "Next"}
          </Button>
          {!!error && <Typography color="error">{error}</Typography>}
        </Stack>
      </FormControl>
      <Dialog
        sx={{ textAlign: "center" }}
        fullWidth={true}
        open={modelOpen}
        data-cy="not-allowed-modal"
        onClose={handleCloseModal}
      >
        <IconButton
          data-cy="modal-close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack>
          <DialogContent>
            <DialogTitle>529 Beneficiary</DialogTitle>
            <Stack spacing={3} sx={{ alignItems: "center" }}>
              <Typography>{beneficiaryText}</Typography>
              <Button onClick={handleCloseModal} variant="contained">
                Close
              </Button>
            </Stack>
          </DialogContent>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default Details529;