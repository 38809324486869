import { useEffect, useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { useQuery } from "@tanstack/react-query";

import studentsAtUni from "@assets/images/studentsAtUni.svg";

import Button from "@components/Button/Button";
import Spinner from "@components/Spinner/Spinner";

import { ProfileType } from "@lib/enums/userProfile";
import { State529ProviderType } from "@lib/enums/state529";

import { useFormatBeneficiaries } from "@hooks/useFormatBeneficiaries";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getBeneficiaries } from "@lib/queries/beneficiaryQueries";
import { getState529Connection } from "@lib/queries/state529Queries";

import { useOnboardingStore } from "@stores/onboardingStore";
import { useCurrentUserStore } from "@stores/currentUserStore";
import { useUserProfileStore } from "@stores/userProfileStore";

const ConfirmAttendanceScreen = () => {
  const hasBeneficiaries = useCurrentUserStore(useShallow((state) => !!state.currentUser.beneficiaries[0].id.length));

  const setBeneficiaries = useCurrentUserStore((state) => state.setBeneficiaries);
  const updateProfile = useUserProfileStore((state) => state.updateProfile);

  const {
    data: beneficiaries,
    isFetching: isBeneficiariesFetching,
    isFetched: isBeneficiariesFetched,
    refetch: refetchBeneficiaries
  } = useQuery({
    queryKey: [QUERY_KEY.BENEFICIARIES],
    queryFn: getBeneficiaries,
    enabled: !hasBeneficiaries,
    ...DEFAULT_QUERY_OPTIONS
  });

  const state_529_plan_id = useMemo(() => {
    if (!!beneficiaries
      && !!beneficiaries.length
      && !!beneficiaries[0].state_529_plan_id
    ) return beneficiaries[0].state_529_plan_id;

    return "";
  }, [beneficiaries]);

  const {
    refetch: refetchState529Connection,
    isSuccess: isState529ConnectionSuccess
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state_529_plan_id],
    queryFn: () => getState529Connection(state_529_plan_id),
    throwOnError: true,
    enabled: !hasBeneficiaries && !!beneficiaries && !!state_529_plan_id,
    ...DEFAULT_QUERY_OPTIONS
  });

  const {
    formattedBeneficiaries,
    isFetching: isFormattedBeneficiariesFetching,
  } = useFormatBeneficiaries({
    beneficiaries,
    gcTime: 0,
    enable: isState529ConnectionSuccess
      && !!beneficiaries
      && !!beneficiaries.length,
  });

  useEffect(() => {
    if (hasBeneficiaries) return;
    if (beneficiaries && !!beneficiaries.length) return;

    if (!hasBeneficiaries
      && isBeneficiariesFetched
      && !beneficiaries?.length
    ) {
      refetchBeneficiaries();
    }
  }, [hasBeneficiaries]);

  useEffect(() => {
    if (hasBeneficiaries || !state_529_plan_id.length) return;

    if (state_529_plan_id.length
      && !formattedBeneficiaries
      && !isFormattedBeneficiariesFetching) {
      refetchState529Connection();
    }
  }, [state_529_plan_id]);

  // set beneficiaries
  // if beneficiaries do not exist on current user
  useEffect(() => {
    if (hasBeneficiaries) return;
    if (!beneficiaries && !formattedBeneficiaries)return;

    if (!hasBeneficiaries && !!formattedBeneficiaries) {
      const { is_automated_withdrawal_eligible } = formattedBeneficiaries[0].state_529_plan.state_529_connection;

      const linkType = is_automated_withdrawal_eligible
        ? State529ProviderType.AUTOMATED
        : State529ProviderType.MANUAL;

      setBeneficiaries(formattedBeneficiaries);

      updateProfile({
        type: ProfileType.ONBOARDING,
        update: { linkType }
      });
    }
  }, [formattedBeneficiaries]);

  const goToPayTuition = useOnboardingStore((state) => state.goToPayTuition);
  const skipTuitionPayment = useOnboardingStore((state) => state.skipTuitionPayment);

  if (!hasBeneficiaries
    || isBeneficiariesFetching
    || isFormattedBeneficiariesFetching
  ) return <Spinner size="lg" />;

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title w-8/10 sm:self-start">Do you have a Tuition bill to pay?</h2>

        <img
          src={studentsAtUni}
          alt="students chatting infront of university"
          loading="lazy"
          height={262}
          width={272}
          className="h-auto w-full max-w-60 sm:max-w-[300] mt-10 sm:mt-16"
        />
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          onClick={skipTuitionPayment}
        >
          No, not yet
        </Button>

        <Button
          rounded
          fullWidth
          onClick={goToPayTuition}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default ConfirmAttendanceScreen;