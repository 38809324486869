import ErrorScreen from "components/ErrorScreen";

type WithdrawalGeneralErrorProps = {
  handleNext: (action: string) => void;
  nextAction: string;
};

const WithdrawalGeneralError = ({ handleNext, nextAction }: WithdrawalGeneralErrorProps) => {
  return (
    <ErrorScreen
      className="w-80 px-6"
      nextAction={nextAction}
      handleNext={handleNext}
    >
      <div className="text-base">
        <p>
          Something went wrong when we tried to process your withdrawal.
        </p>

        <p className="py-5">
          Please try again, or if you’d prefer you can withdraw funds manually from your 529 into your
          Backpack account.
        </p>

        <p>
          If you have additional questions please contact support.
        </p>
      </div>
    </ErrorScreen>
  );
};

export default WithdrawalGeneralError;
