import data from "../../data/529SupportArticleMapping.json";

import type { ManualLinkType, State529Plan } from "@lib/types/state529";

type State529SupportDetail = {
  "verification_type": ManualLinkType | string;
  "plan_name": State529Plan["name"];
  "linking_guide": string;
  "withdrawal_guide": string;
}

export type State529ArticleMap = {
  [key: State529Plan["id"]]: State529SupportDetail;
}

export const getState529SupportDetails = (state_529_plan_id: State529Plan["id"]) => {
  if (!state_529_plan_id) return null;

  const articleMapping: State529ArticleMap = import.meta.env.VITE_APP_ENV === "production"
    ? data.production
    : data.development;

  return articleMapping[state_529_plan_id];
};