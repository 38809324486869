import { FC, useState } from "react";

import LockPerson from "@mui/icons-material/LockPerson";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const IdentityVerificationStack = styled(Stack)({
  flexGrow: 1,
  justifyContent: "space-around",
  alignItems: "center",
  padding: "1rem 3rem",
});

interface IdentityVerificationPropTypes {
  handleOpenPersona: any;
  error: string;
}

const IdentityVerification: FC<IdentityVerificationPropTypes> = ({
  handleOpenPersona,
  error,
}) => {
  const [loading, setLoading] = useState(false);
  const handleBeginVerification = async () => {
    try {
      setLoading(true);
      await handleOpenPersona(() => setLoading(() => !loading));
    } finally {
      setLoading(false);
    }
  };

  return (
    <IdentityVerificationStack spacing={1}>
      <LockPerson sx={{ fontSize: 100, color: "#033AFC" }}></LockPerson>
      <Typography sx={{ textAlign: "justify", lineHeight: "1.0625rem" }}>
        We utilize a third party identity verification service called Persona to verify who you are.
        You can read more about how Persona protects and stores your data in their{" "}
        <a href="https://withpersona.com/legal/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        .
      </Typography>
      <Stack spacing={2} alignItems={"center"}>
        <Button
          variant="contained"
          onClick={handleBeginVerification}
          disabled={loading}
          data-cy="verification-button">
          {" "}
          {loading ? (
            <CircularProgress size="1.7rem" sx={{ color: "white" }} />
          ) : (
            "Begin Verification"
          )}
        </Button>
        {!!error && (
          <Typography mb="1rem" color="error" data-cy="identity-error">
            {error}
          </Typography>
        )}
        <Typography sx={{ textAlign: "justify" }} variant="legal">
          Backpack does not share this data with anyone outside of Backpack or Persona. You can read
          more about how Backpack uses your data in our{" "}
          <a
            href="https://www.backpack529.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
          .
        </Typography>
      </Stack>
    </IdentityVerificationStack>
  );
};

export default IdentityVerification;
