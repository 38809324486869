import { FC } from "react";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import VerticalCard from "commons/Card/Vertical";

const links = [
  { label: "Terms of Service", href: "https://www.backpack529.com/legal/terms-of-service" },
  { label: "Privacy Policy", href: "https://www.backpack529.com/legal/privacy-policy" },
  { label: "ESIGN Agreement", href: "https://www.backpack529.com/legal/e-sign-consent-agreement" },
];

const Legal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  return (
    <VerticalCard onClose={handleClose}>
      <div className="settings-modal-container">
        <div>
          <h4 className="title">Legal Information</h4>
          <ul>
            {links.map((link) => (
              <li key={link.label}>
                <a href={link.href} target="_blank" rel="noreferrer">
                  {link.label}
                  <OpenInNewIcon />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <Button
          className="w-full rounded-full"
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </VerticalCard>
  );
};

export default Legal;
