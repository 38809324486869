import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Container, Stack, Typography } from "@mui/material";

import type { PaymentInfo } from "@lib/types/institutionPayment";

interface ReviewProps {
  paymentInfo: Partial<PaymentInfo>;
  handleNext?: () => void;
  makePayment: () => void;
}

const Review: FC<ReviewProps> = ({ paymentInfo, makePayment }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePayment = async () => {
    try {
      setError("");
      setLoading(true);
      await makePayment();
    } catch (err: any) {
      setLoading(false);
      setError(err.message);
    }
  };

  const Info = styled(Typography)({
    display: "flex",
    justifyContent: "space-between",
    margin: "0.5rem",
  });

  const ConfirmButton = styled(Button)({
    width: "85%",
    padding: ".625rem 0",
    margin: "3rem auto 1rem auto",
  });

  const displayInfo = {
    Recipient: paymentInfo.recipient?.name,
    Amount: paymentInfo.amount,
    Term: `${paymentInfo.term?.description}`,
    Student: `${paymentInfo.student?.first_name} ${paymentInfo.student?.last_name}`,
    "Student ID": paymentInfo.student?.enrollments[0].student_id,
  };

  const renderInfo = Object.entries(displayInfo).map(([key, value], index) => (
    <Info key={key} data-cy={`payment-info-${index}`}>
      <span>{key} </span> <b>{value}</b>
    </Info>
  ));

  return (
    <Container style={{ padding: "2rem" }}>
      <Stack>{renderInfo}</Stack>

      <Stack>
        <ConfirmButton variant="contained" onClick={handlePayment} data-cy="payment-review-confirm">
          {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : "Make Payment"}
        </ConfirmButton>
      </Stack>

      {!!error && (
        <Typography mt="1rem" color="error" data-cy="payment-review-error">
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default Review;
