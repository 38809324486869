import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

import "assets/fonts/index.css";

import "./styles/settings.scss";
import "./styles/insights.scss";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    action: true;
    legal: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    tertiary: true;
  }
}

export let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    button: {
      textTransform: "none",
    },
    h4: {
      fontSize: 20,
      fontWeight: "bold",
      lineHeight: 1.4,
    },
  },
  palette: {
    primary: {
      main: "#033afc", // electric blue
      light: "#2fb5fccc", // light blue
      dark: "#001663", // midnight blue
    },
    text: {
      primary: "#18181b", // gray-900 (tw zinc-900)
      secondary: "#000000",
    },
    grey: {
      200: "#00000026",
      400: "#d9d9d9",
    },
    warning: {
      main: "#ffbf00",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        action: {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          fontSize: 22,
        },
        legal: {
          color: theme.palette.grey[700],
          fontSize: 12,
          [theme.breakpoints.down("sm")]: {
            fontSize: 11,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Inter, sans-serif";
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          borderRadius: 10,
          width: "15.625rem",
          padding: ".625rem 0",
        },
        contained: {
          color: "#ffffff",
          fontWeight: "bold",
        },
        text: {
          color: theme.palette.primary.main,
          fontSize: "0.9rem",
          padding: "0",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          color: theme.palette.primary.main,
        },
        tertiary: {
          backgroundColor: "#D1DBFF",
          borderRadius: "2px",
          color: theme.palette.primary.main,
          fontSize: 14,
          fontWeight: "bold",
          padding: "8px 12px",
          textDecoration: "none",
          width: "initial",
          "&:hover": {
            backgroundColor: "#DFE8FF",
          },
          "&:active": {
            backgroundColor: "#DFE8FF",
          },
          "&:focus": {
            backgroundColor: "#DFE8FF",
          }
        }
      },
      variants: [
        {
          props: { variant: "tertiary", size: "large" },
          style: {
            fontSize: 18,
            padding: "12px 20px",
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: theme.palette.text.primary,
        },
        root: {
          borderRadius: 10,
          borderBlockWidth: "0.0125rem",
          [`&:hover:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            border: `0.156rem solid ${theme.palette.primary.main}`,
          },
          [`.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            border: `0.156rem solid ${theme.palette.error.main}`,
          },
          [`&.Mui-focused:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
            border: `0.156rem solid ${theme.palette.primary.main}`,
          },
          [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            border: `0.156rem solid ${theme.palette.error.main}`,
          },
        },
        input: {
          fontSize: 16,
          color: theme.palette.text.primary,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
      },
    },
  },
});

export default theme;
