import { useState } from "react";
import { When } from "react-if";

import { Button, CircularProgress } from "@mui/material";

import SingleCharacterInputGroup from "commons/Input/SingleCharacterInputGroup";

import { InputValidator } from "utils/validations";

type VerifyProps = {
  handleNext: (code: string) => void;
};

const WithdrawalVerify = ({ handleNext }: VerifyProps) => {
  const [code, setCode] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState("");

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    if (code.length > 6 || isNaN(Number(code))) return;
    setCode(code);
  };

  const handleCodeSubmit = async () => {
    try {
      setIsPending(true);
      setError("");

      const isValid = InputValidator.isValidCode(code);

      if (!isValid) {
        setError("Please enter a valid code");
        setIsPending(false);
        return;
      }

      await handleNext(code);
      setIsPending(false);
    } catch (error: any) {
      setError(error?.message);
      setIsPending(false);
    }
  };

  return (
    <div className="w-80 px-8 flex flex-col justify-between gap-4 items-center grow">
      <div className="flex flex-col items-center gap-4 mt-6 text-center">
        <p className="w-52 text-xl">You should have just received a text</p>

        <p className="w-72 text-sm">
          Enter the code sent to your phone number from your 529 Account Provider
        </p>
        <SingleCharacterInputGroup
          length={6}
          error={!!error}
          onChange={handleValueChange}
          value={code}
        />
        {error && <p className="flex justify-center text-error">{error}</p>}
      </div>

      <Button
        variant="contained"
        className="rounded-full mt-2"
        disabled={(code.length < 6) || isPending}
        onClick={handleCodeSubmit}
      >
        <When condition={isPending}>
          <CircularProgress
            size="1.7rem"
            sx={{ color: "white" }}
            aria-label="Pending"
          />
        </When>

        <span className={isPending ? "sr-only" : ""}>Continue</span>
      </Button>
    </div>
  );
};

export default WithdrawalVerify;
