import { Button, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const Content = styled(Stack)(({ theme }) => ({
  width: "80%",
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 600,
  textAlign: "center",
  color: theme.palette.primary.main,
}));

export const Text = styled(Typography)({
  textAlign: "center",
  lineHeight: "1.0625rem",
  marginTop: "0",
});

export const ConfirmButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
});

export const TextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.9rem",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
