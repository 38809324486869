import ProgressCircleIcon from "@components/Icons/ProgressCircleIcon";
import { classnames } from "@lib/utils/classnames";

type SpinnerProps = {
  backgroundColor?: string;
  color?: string;
  fullScreen?: boolean;
  size?: "sm" | "md" | "lg";
}

/**
 * Animated spinner to be used to indicate loading state
 *
 * @param color options include blue and white. default is blue
 * @param fullScreen boolean. default is false
 * @param size sm, md, lg. default is sm
 *
 */
const Spinner = ({
  color = "blue",
  size = "sm",
  fullScreen = false
}: SpinnerProps) => {
  let spinnerColors = "";

  switch (color) {
    case "white":
      spinnerColors = "text-gray-300 fill-white";
      break;

    case "blue":
    default:
      spinnerColors = "text-gray-200 fill-blue";
      break;
  }

  const spinnerClassnames = classnames(
    "animate-spin",
    { "size-7": size === "sm" },
    { "size-16": size === "md" },
    { "size-24": fullScreen || size === "lg" },
    spinnerColors
  );

  return (
    <div
      data-testid="spinner"
      role="status"
      className={classnames("flex justify-center items-center size-full", { "fixed inset-0": fullScreen })}
    >
      <ProgressCircleIcon className={spinnerClassnames} />

      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;