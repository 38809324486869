type LoginLayoutProps = {
  children: React.ReactNode;
}

const LoginLayout = ({ children }: LoginLayoutProps) => {
  return (
    <>
      <main className="flex justify-center items-center flex-1 sm:pb-11 sm:py-9 max-w-screen-lg w-full mx-auto">
        {children}
      </main>
    </>
  );
};

export default LoginLayout;