import { FC } from "react";
import { Button } from "@mui/material";

import VerticalCard from "commons/Card/Vertical";
import AccountDetailsCard from "components/AccountDetailsCard";
import PaymentMethodsList from "components/Settings/SettingsAccountInfoPaymentMethods";

import type { BankAccount } from "@lib/types/account";
import { Property } from "components/AccountDetailsCard";

type AccountInformationProps = {
  handleClose: () => void;
  account: BankAccount;
};

const AccountInformation: FC<AccountInformationProps> = ({ handleClose, account }) => {
  const properties: Property[] = [
    {
      label: "Account Number",
      value: account.account_number,
      displayValue: account.account_number,
      variant: "bold",
      allowCopy: true,
    },
    {
      label: "Routing Number",
      value: account.routing_number,
      displayValue: account.routing_number,
      variant: "bold",
      allowCopy: true,
    },
    {
      label: "Account Type",
      value: account.account_type,
      displayValue: account.account_type,
      variant: "bold",
    },
  ];
  return (
    <VerticalCard onClose={handleClose}>
      <div className="settings-modal-container">
        <div className="account-content">
          <div>
            <h4 className="title">Account Information</h4>
            <p className="description">
              Below is the information you will need to add your Backpack account to your 529 as a
              withdrawal method if you need to do it manually.
            </p>
            <p className="description">
              It is also the information you will need to add your Backpack account as a payment
              method for apps like PayPal, Amazon and others.
            </p>
          </div>
          <AccountDetailsCard properties={properties} />
          <PaymentMethodsList />
        </div>
        <Button
          className="w-full rounded-full"
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </VerticalCard>
  );
};

export default AccountInformation;
