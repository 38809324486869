import { Button } from "@mui/material";

const ShopAmazon = () => {
  return (
    <div className="flex flex-col gap-4 px-6 sm:px-0 text-sm md:text-base text-left">
      <h2 className="text-xl md:text-3xl font-semibold text-left">Shop on Amazon with Backpack</h2>

      <p>Using your Backpack account to pay for 529 eligible expenses maximizes your savings, ensures tax-free purchases, and simplifies tracking your expenses for tax time.</p>

      <p>You can buy top 529 eligible items such as:</p>

      <ul className="max-w-md space-y-1 list-none list-inside">
        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://amzn.to/4azA9WM"
          >
            Laptops
          </a>
        </li>

        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://amzn.to/3wUITcn"
          >
            Textbooks
          </a>
        </li>

        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://amzn.to/4bY72O5"
          >
            School Supplies
          </a>
        </li>
      </ul>

      <p>For more information on how to pay on Amazon with your Backpack account, click learn more below.</p>

      <Button
        className="block w-max mt-2 text-sm px-3 py-2"
        variant="tertiary"
        target="_blank"
        rel="noreferrer"
        href="https://intercom.help/backpack-529/en/articles/9390241-how-to-shop-on-amazon-using-your-backpack-account"
      >
        Learn More
      </Button>
    </div>
  );
};

export default ShopAmazon;