import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type {
  AccountOwner,
  BankAccount,
  BankAccountBalance
} from "@lib/types/account";

export type BankAccountBalanceResponse = BankAccountBalance & {
  bank_account_id: BankAccount["id"];
};

export const getAccountOwner = async (): Promise<AccountOwner> => {
  try {
    const { data: { data } } = await instance.get("/account-owners");

    return data.owner;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

// [SM - 07/30/2024]: account owners currently only have 1 account
// if that changes, we'll need to update this and the currentUser store to handle multiple bankAccounts
export const getBankAccount = async (): Promise<BankAccount> => {
  try {
    const { data: { data } } = await instance.get("/bank-accounts");

    return data.bank_accounts[0];
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const getBankAccountBalance = async (bankAccountId: BankAccount["id"]): Promise<BankAccountBalanceResponse> => {
  try {
    const { data: { data } } = await instance.get(`/bank-accounts/${bankAccountId}/balance`);

    return data.balance;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};