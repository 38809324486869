import { FC } from "react";
import { formatCurrency } from "utils/formats";
import { Container, containerClasses, Stack, styled, Typography } from "@mui/material";

import type { TransactionResponse } from "@lib/types/transaction";
import type { InstitutionPayment } from "@lib/types/institutionPayment";


interface TransactionProps {
  transaction: TransactionResponse | InstitutionPayment;
  variant: "transactions" | "payments";
}

const RowContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgba(0,0,0, 0.05)",
  "&:first-of-type": {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  [`&.${containerClasses.root}`]: {
    padding: "1rem",
    maxWidth: "calc(100vw - 2rem)",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: "700",
  [theme.breakpoints.down("md")]: {
    width: "40%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Information = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    width: "60%",
    gap: "0.2rem",
  },
}));

const InformationElement = styled(Stack)(({ theme }) => ({
  fontSize: "0.75rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",
    textAlign: "right",
  },
}));

const Price = styled(InformationElement)({ width: "5rem" });
const Type = styled(InformationElement)({ width: "7rem" });
const Date = styled(InformationElement)(({ theme }) => ({
  width: "5rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const TransactionRow: FC<TransactionProps> = ({ transaction, variant }) => {
  return (
    <RowContainer>
      <Title>{transaction.description}</Title>
      <Information>
        <Price>{formatCurrency(transaction.amount_in_cents)}</Price>
        <Date>{transaction.date}</Date>
        <Type>{variant === "payments" ? "PENDING" : transaction.type}</Type>
      </Information>
    </RowContainer>
  );
};

export default TransactionRow;
