import { ProfileType } from "@lib/enums/userProfile";
import type { UserProfiles } from "@lib/types/userProfile";

export const checkProfileStatus = (type: ProfileType, profile: UserProfiles) => {
  if (type === ProfileType.ONBOARDING) {
    const {
      hasBeneficiary,
      hasInstitutionPayments,
      hasTransactions,
      hasLinked529
    } = profile;

    // if a user has linked their 529 or has transactions
    // onboarding is complete
    let isOnboardingComplete = hasLinked529 || hasTransactions;

    if (hasInstitutionPayments
      && !hasTransactions
      && !hasLinked529
    ) {
      isOnboardingComplete = false;
    }

    return hasBeneficiary && isOnboardingComplete;
  }

  const isComplete = Object.values(profile).every((value) => !!value);

  return isComplete;
};