import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Loader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: "auto",
}));

type LoaderProps = {
  fullScreen?: boolean;
};

export const LoaderComponent = ({ fullScreen }: LoaderProps) => {
  if (fullScreen) {
    return (
      <div className="fixed inset-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  }
  return (
    <div className="flex size-full justify-center items-center">
      <Loader />
    </div>
  );
};

export default LoaderComponent;
