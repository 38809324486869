import ErrorScreen from "components/ErrorScreen";
import WarningIcon from "components/Icons/WarningIcon";

type WithdrawalUnableToInitialiseProps = {
  nextAction: string;
  handleNext: (action: string) => void;
};

const WithdrawalUnableToInitialise = ({
  nextAction,
  handleNext,
}: WithdrawalUnableToInitialiseProps) => {
  return (
    <ErrorScreen
      className="w-80 px-5"
      title="Unable to Initiate Withdrawal"
      subtitle="One of these requirements was not met"
      type="WARNING"
      nextAction={nextAction}
      handleNext={handleNext}
    >
      <div className="flex flex-col items-center gap-8">
        <p className="text-base text-center">Please review them and make any changes necessary</p>

        <ul className="text-sm pl-2 pb-4">
          <li className="flex justify-between items-center gap-4">
            <WarningIcon className="size-8 text-warning-icon" aria-hidden="true" />

            <p className="text-xs">Account Owner on 529 Account matches owner of Backpack Account</p>
          </li>

          <li className="flex justify-between items-center my-8 gap-4">
            <WarningIcon className="size-8 text-warning-icon" aria-hidden="true" />

            <p className="text-xs">
              Beneficiary on 529 Account matches beneficiary on Backpack Account
            </p>
          </li>

          <li className="flex justify-between items-center gap-4">
            <WarningIcon className="size-8 text-warning-icon" aria-hidden="true" />

            <p className="text-xs">Withdrawal amount is less than 95% of total 529 Account balance</p>
          </li>
        </ul>
      </div>
    </ErrorScreen>
  );
};

export default WithdrawalUnableToInitialise;
