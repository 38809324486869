import { When } from "react-if";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, A11y, Autoplay } from "swiper/modules";
import { useShallow } from "zustand/react/shallow";

import Card from "commons/Card";
import InsightCardContent from "@components/Insights/InsightCardContent";

import { State529ConnectionStatus } from "@lib/enums/state529";

import useScreenSize, { SCREEN } from "@hooks/useScreenSize";

import { useCurrentUserStore } from "@stores/currentUserStore";

import type { Insight } from "@lib/types/userInsightAndAction";

type Insights = {
  pinned: Insight[];
  grouped: Insight[];
}

type InsightCardsProps = {
  insights: Insights;
  onHandleInsight: (modal?: string) => void;
}

const MAX_INSIGHTS = 8;

const DEFAULT_SWIPER_OPTIONS = {
  setWrapperSize: true,
  loop: false,
  autoplay: {
    delay: 5000,
  },
  spaceBetween: 20,
  keyboard: {
    enabled: true,
  },
  pagination: {
    clickable: true,
  },
  navigation: true,
  breakpoints: {
    1024: {
      slidesPerView: 1,
    },
    896: {
      slidesPerView: 2,
      spaceBetween: 5,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    768: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    200: {
      slidesPerView: 1,
    },
  }
};

const getGroupedInsights = (insights: Insights, showPinned: boolean) => {
  const combinedInsights = [...insights.pinned, ...insights.grouped];
  const combinedInsightsSorted = combinedInsights.sort((a: Insight, b: Insight) => a.rank - b.rank);

  const groupedInsightsCopy = [...insights.grouped];

  const groupedInsightsTotal = groupedInsightsCopy.length;
  const combinedInsightsTotal = combinedInsights.length;

  let groupedInsights = groupedInsightsCopy;
  let allInsights = combinedInsightsSorted;

  if (groupedInsightsTotal > MAX_INSIGHTS) {
    groupedInsights = groupedInsightsCopy.slice(0, MAX_INSIGHTS);
  }

  if (combinedInsightsTotal > MAX_INSIGHTS) {
    allInsights = combinedInsightsSorted.slice(0, MAX_INSIGHTS);
  }

  if (showPinned) {
    return groupedInsights;
  }

  return allInsights;
};

const InsightCards = ({
  insights,
  onHandleInsight
}: InsightCardsProps) => {
  const state529LinkStatus = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0].state_529_plan.state_529_connection.status));

  const cardStyles = "pb-8 sm:pb-4 md:!p-5 lg:!pb-8 xl:!py-4 m-0 sm:mx-2.5 md:m-0 min-[896px]:mx-2.5 lg:m-0 max-w-full w-full lg:max-w-96";

  const screenSize = useScreenSize();
  const showPinned = ![SCREEN.XS, SCREEN.SM, SCREEN.MD].includes(screenSize);

  const groupedInsights = getGroupedInsights(insights, showPinned);

  let insightsList = groupedInsights;

  if (state529LinkStatus !== State529ConnectionStatus.NOT_LINKED) {
    insightsList = groupedInsights.filter((insight) => insight.modal !== "LINK_529");
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <When condition={showPinned}>
        {insights.pinned.map((insight) => (
          <Card
            key={`pinned-insights-${insight.rank}`}
            className={cardStyles}
          >
            <InsightCardContent
              {...insight}
              handleOnClick={() => onHandleInsight(insight.modal)}
            />
          </Card>
        ))}
      </When>

      <div className="flex justify-center lg:block lg:justify-normal max-w-full lg:max-w-[392px] w-full">
        <Swiper modules={[A11y, Pagination, Navigation, Autoplay]} {...DEFAULT_SWIPER_OPTIONS}>
          {insightsList.map((insight) => (
            <SwiperSlide key={`insights-${insight.rank}`}>
              <Card className={cardStyles}>
                <InsightCardContent
                  {...insight}
                  handleOnClick={() => onHandleInsight(insight.modal)}
                  />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default InsightCards;