import { useState, useEffect, useRef, useMemo } from "react";

const TIMEOUT_DELAY = 150;

export const SCREEN = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "2xl"
};

export const MIN_SCREEN_SIZE = {
  [SCREEN.XS]: 0,
  [SCREEN.SM]: 640,
  [SCREEN.MD]: 768,
  [SCREEN.LG]: 1024,
  [SCREEN.XL]: 1280,
  [SCREEN.XXL]: 1536,
};

const convertScreenSize = (screenSize: number) => {
  // these come from tailwind default screen sizes
  // tailwind has "sm": min-width: 640px
  // "sm" is any screens from 640px - 767px

  if (screenSize >= MIN_SCREEN_SIZE[SCREEN.XXL]) return SCREEN.XXL;
  if (screenSize >= MIN_SCREEN_SIZE[SCREEN.XL]) return SCREEN.XL;
  if (screenSize >= MIN_SCREEN_SIZE[SCREEN.LG]) return SCREEN.LG;
  if (screenSize >= MIN_SCREEN_SIZE[SCREEN.MD]) return SCREEN.MD;
  if (screenSize >= MIN_SCREEN_SIZE[SCREEN.SM]) return SCREEN.SM;

  return SCREEN.XS;
};

const useScreenSize = (debounceDelay: number = TIMEOUT_DELAY) => {
  const [screenSize, setScreenSize] = useState( convertScreenSize(window.innerWidth));

  const resizeTimeout = useRef<number | undefined>();

  const handleResize = () => {
    window.clearTimeout(resizeTimeout.current);

    resizeTimeout.current = window.setTimeout(() => {
      const newScreenSize = convertScreenSize(window.innerWidth);

      // Only update state if the screen size category changes
      if (newScreenSize !== screenSize) {
        setScreenSize(newScreenSize);
      }
    }, debounceDelay);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.clearTimeout(resizeTimeout.current);
    };
  }, [screenSize, debounceDelay]);

  return useMemo(() => screenSize, [screenSize]);
};

export default useScreenSize;