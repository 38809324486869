import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { UUID } from "@lib/types/global";
import type { CreateInstitutionPaymentPayload } from "@lib/types/institutionPayment";

type CreateInstitutionPaymentToken = {
  mfa_code: string;
  institution_id: UUID;
}

export const createInstitutionPaymentToken = async (payload: CreateInstitutionPaymentToken): Promise<string> => {
  try {
    const { data: { data } } = await instance.post("/institution-payments/tokens", payload);

    return data.token;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const requestInstitutionPaymentMFACode = async () => {
  try {
    await instance.post("/institution-payments/mfa-codes");
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const createInstitutionPayment = async (payload: CreateInstitutionPaymentPayload) => {
  try {
    const { data: { data } } = await instance.post("/institution-payments", payload);

    return data.institution_payment;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};