import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { If, Then, Else, When } from "react-if";

import Button from "@components/Button/Button";
import InputAutocomplete from "@components/Input/InputAutocomplete";
import InputText from "@components/Input/InputText";
import Spinner from "@components/Spinner/Spinner";

import { ERROR_TYPE } from "@lib/constants/error";
import { ProfileType } from "@lib/enums/userProfile";

import { useFormValidation } from "@hooks/useFormValidation";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { createBeneficiary } from "@lib/mutations/beneficiaryMutations";

import { DEFAULT_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import { getInstitutions } from "@lib/queries/institutionQueries";
import { getState529Plans } from "@lib/queries/state529Queries";

import { useUserProfileStore } from "@stores/userProfileStore";
import { useOnboardingStore } from "@stores/onboardingStore";

import type { CreateBeneficiaryPayload } from "@lib/types/beneficiary";

const STALE_TIME_30_MINS = 30 * 60 * 1000;

const StudentDetailsScreen = () => {
  const {
    errors,
    isValidForm,
    setErrors,
    setFormErrors,
    validateFormField,
  } = useFormValidation("onboarding-student-details-form");

  const [selectedInstitution, setSelectedInstitution] = useState<string>("");
  const [selectedState529Plan, setSelectedState529Plan] = useState<string>("");
  const [isPendingSubmit, setIsPendingSubmit] = useState<boolean>(false);

  const updateProfile = useUserProfileStore((state) => state.updateProfile);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);

  const {
    data: institutions = [],
    error: institutionsError,
    isLoading: isInstitutionsLoading,
    isError: isInstitutionsError,
  } = useQuery({
    queryKey: [QUERY_KEY.INSTITUTIONS],
    queryFn: getInstitutions,
    staleTime: STALE_TIME_30_MINS,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...DEFAULT_QUERY_OPTIONS,
  });

  const {
    data: state_529_plans = [],
    error: state529PlansError,
    isLoading: isState529PlansLoading,
    isError: isState529PlansError,
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_PLANS],
    queryFn: getState529Plans,
    staleTime: STALE_TIME_30_MINS,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...DEFAULT_QUERY_OPTIONS
  });

  // since this is crucial to the screen,
  // it's time for the react error boundary to shine
  if (isState529PlansError || isInstitutionsError) {
    const error = state529PlansError || institutionsError;

    throw error;
  }

  const {
    mutate,
    error: createBeneficiaryError,
    isPending: isCreateBeneficiaryPending,
  } = useMutation({
    mutationKey: [MUTATION_KEY.CREATE_BENEFICIARY],
    mutationFn: createBeneficiary,
    onError: (error) => {
      if (error.name === ERROR_TYPE.INVALID_FORMAT) {
        setIsPendingSubmit(false);
      } else {
        throw error;
      }
    },
    onSuccess: async () => {
      // when a new beneficiary is created
      // update the onboarding profile
      await updateProfile({
        type: ProfileType.ONBOARDING,
        update: { hasBeneficiary: true }
      });

      goToNextStep();
    },
  });

  const formErrorMessage = useMemo(() => {
    if (createBeneficiaryError && createBeneficiaryError.name === ERROR_TYPE.INVALID_FORMAT) {
      return "There was a problem submitting the form. Fix the issues below and try again.";
    }

    if (!!errors && Object.values(errors).some((error) => !!error.length)) {
      return "Please fill out the required fields.";
    }

    return "";
  }, [errors, createBeneficiaryError]);

  const handleFormSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();

    // disble submit button
    setIsPendingSubmit(true);

    const formData = new FormData(event.target as HTMLFormElement);
    const isValid = (event.target as HTMLFormElement).checkValidity();
    const hasErrors = errors && Object.values(errors).some((error) => !!error.length);


    // if the form is invalid, show an error message
    // and make sure all invalid inputs are in an error state
    if (!isValid) {
      setFormErrors(formData);
      setIsPendingSubmit(false);
      return;
    }

    // if the form is valid but used to have errors
    // courtesey reset all errors
    if (isValid && hasErrors) setErrors(null);

     // since student_id is optional
    // we need to see if it has a value before adding it to the payload
    const student_id = (document.getElementById("student-id-number") as HTMLInputElement).value as string;

    const beneficiary: CreateBeneficiaryPayload = {
      first_name: formData.get("first_name") as string,
      last_name: formData.get("last_name") as string,
      state_529_plan_id: selectedState529Plan,
      enrollments: [
        {
          institution_id: selectedInstitution,
        },
      ],
    };

    if (student_id) {
      beneficiary.enrollments[0].student_id = student_id;
    }

    mutate(beneficiary);
  }, [selectedInstitution, selectedState529Plan]);

  return (
    <div className="modal-screen-footer-fixed">
      <If condition={isInstitutionsLoading || isState529PlansLoading}>
        <Then>
          <Spinner size="lg" />
        </Then>

        <Else>
          <div className="flex flex-col justify-between items-center">
            <h2 className="modal-header-title">Add Student Details</h2>

            <p className="modal-header-description">Let&#39;s add the 529 beneficiary whose education you&#39;re supporting. You&#39;ll be able to add more beneficiaries later too.</p>

            <form
              id="onboarding-student-details-form"
              className="mt-6 flex flex-col gap-4 w-full"
              noValidate
              onSubmit={handleFormSubmit}
            >
              <When condition={formErrorMessage}>
                <span className="text-error font-semibold">{formErrorMessage}</span>
              </When>

              <div className="flex flex-col md:flex-row justify-between gap-8">
                <InputText
                  required
                  id="student-first-name"
                  data-testid="student-first-name"
                  name="first_name"
                  label="Student's First Name"
                  placeholder="Enter first name"
                  error={errors?.first_name}
                  onBlur={(event) => validateFormField({
                    inputName: "first_name",
                    isValid: !!event.currentTarget.value
                  })}
                />

                <InputText
                  required
                  id="student-last-name"
                  data-testid="student-last-name"
                  name="last_name"
                  label="Student's Last Name"
                  placeholder="Enter last name"
                  error={errors?.last_name}
                  onBlur={(event) => validateFormField({
                    inputName: "last_name",
                    isValid: !!event.currentTarget.value
                  })}
                />
              </div>

              <InputAutocomplete
                required
                form="onboarding-student-details-form"
                id="select-university"
                data-testid="university-input-autocomplete"
                name="institution"
                label="University"
                placeholder="Select University"
                options={institutions}
                error={errors?.institution}
                onSelected={(option) => {
                  validateFormField({
                    inputName: "institution",
                    isValid: !!option?.id
                  });

                  setSelectedInstitution(option?.id);
                }}
              />

              <InputText
                id="student-id-number"
                data-testid="student-id-number"
                name="student_id"
                label="Student ID Number (if known)"
                placeholder="Enter student id number"
                error={createBeneficiaryError?.message}
              />

              <InputAutocomplete
                required
                form="onboarding-student-details-form"
                id="select-529-plan-provider"
                data-testid="state-529-input-autocomplete"
                name="state_529_plan"
                label="529 Plan Provider"
                placeholder="Start typing to search"
                options={state_529_plans}
                error={errors?.state_529_plan}
                onSelected={(option) => {
                  validateFormField({
                    inputName: "state_529_plan",
                    isValid: !!option?.id
                  });

                  setSelectedState529Plan(option?.id);
                }}
              />
            </form>
          </div>

          <div className="w-full shrink-0">
            <Button
              type="submit"
              form="onboarding-student-details-form"
              data-testid="student-details-submit-btn"
              data-gtm="onboarding-add-student"
              disabled={!isValidForm || isCreateBeneficiaryPending}
              rounded
              fullWidth
              onClick={() => handleFormSubmit}
            >
              {isPendingSubmit || isCreateBeneficiaryPending
                ? <Spinner color="white" />
                : "Continue"
              }
            </Button>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default StudentDetailsScreen;