export const VERIFICATION_TYPE = {
  HOLD_15_DAY: "15_day_hold_manual",
  HOLD_15_DAY_WITHDRAWAL_SETUP: "15_day_hold_manual_withdrawal_setup",
  MICRODEPOSITS: "microdeposits",
  BANK_FORMS: "bank_forms",
  DIRECT_PAY: "direct_pay",
  PLAID_LINK: "plaid_link",
  OTHER: "other",
  DEFAULT: "default",
  HOLD_30_DAY: "30_day_hold_manual",
} as const;

/**
 * These are the number of days before a tuition
 * deadline date that we may show or perform some action
 */
export const PRE_TUITION_DEADLINE_DAYS = {
  [VERIFICATION_TYPE.HOLD_15_DAY]: 18,
  [VERIFICATION_TYPE.HOLD_15_DAY_WITHDRAWAL_SETUP]: 18,
  [VERIFICATION_TYPE.MICRODEPOSITS]: 5,
  [VERIFICATION_TYPE.BANK_FORMS]: 13,
  [VERIFICATION_TYPE.DIRECT_PAY]: 4,
  [VERIFICATION_TYPE.OTHER]: 18,
  [VERIFICATION_TYPE.DEFAULT]: 18,
  [VERIFICATION_TYPE.HOLD_30_DAY]: 33
};