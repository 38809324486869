import React from "react";

import { Button, CircularProgress, Stack, Typography, FormControl, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import type { State529Plan } from "@lib/types/state529";

const Content = styled(Stack)({
  width: "100%",
  flexGrow: 1,
  margin: "1rem 0",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
});

const FormInputsContainer = styled(Stack)(({ theme }) => ({
  width: "90%",
  textAlign: "left",
  margin: "auto",

  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));

const FormPlaceholder = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "1rem",
});

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  [theme.breakpoints.down("sm")]: {
    width: "85%",
  },
}));

const FormControlContainer = styled(FormControl)({
  margin: "1rem 0",
});

const DisclaimerContainer = styled(Stack)({
  alignItems: "center",
  marginTop: "4rem",
  marginBottom: 0,
});

const DisclaimerText = styled(Typography)({
  width: "80%",
  textAlign: "left",
});

const ErrorText = styled(Typography)({
  marginBottom: "1rem",
});

// const Image = styled("img")(({ theme }) => ({
//   width: "40%",
//   margin: "2rem auto",
//   [theme.breakpoints.down("sm")]: {
//     margin: "3rem auto",
//   },
// }));

const UsernameInput = styled(TextField)({
  marginBottom: "0.2rem",
});

interface Credentials529Props {
  sendCredentials: (username: string, password: string) => void;
  beneficiaryPlan: State529Plan;
}

const Credentials529 = ({ sendCredentials, beneficiaryPlan }: Credentials529Props) => {
  const [info529, setInfo529] = React.useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });
  const [error, setError] = React.useState("");
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInfo529({ ...info529, [event.target.name]: event.target.value });
  };

  const isInvalidInformation = () =>
    !info529.password ||
    info529.password.length < 3 ||
    !info529.username ||
    info529.username.length < 3;

  const handle529Account = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isInvalidInformation()) {
      setError("Please fill in all fields");
      return;
    }

    setSubmitLoading(true);
    await sendCredentials(info529.username, info529.password);
    setSubmitLoading(false);
  };

  return (
    <Content>
      <form onSubmit={handle529Account} className="flex-column items-center justify-center">
        <FormInputsContainer>
          {" "}
          <FormPlaceholder data-cy="link-529-credentials-title">
            {/* To do: Replace with actual Plan name */}
            Enter your 529 Account login details for {beneficiaryPlan?.name}
          </FormPlaceholder>
          <FormControlContainer>
            <UsernameInput
              fullWidth
              name="username"
              label="Username"
              value={info529.username}
              onChange={handleChange}
              placeholder=""
              data-cy="link-529-credentials-username-input"
            />
          </FormControlContainer>
          <FormControl>
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={info529.password}
              onChange={handleChange}
              placeholder=""
              data-cy="link-529-credentials-password-input"
            />
          </FormControl>
          {/* <Image src={beneficiaryPlan.logo} alt="529 Plan Logo" /> */}
        </FormInputsContainer>

        <DisclaimerContainer spacing={4}>
          <DisclaimerText variant="legal" data-cy="link-529-credentials-disclaimer">
            If you need to change the 529 Plan associated with your account please reach out to
            support and request the change.
          </DisclaimerText>

          <ConfirmButton
            variant="contained"
            data-cy="link-529-credentials-confirm-button"
            disabled={isInvalidInformation()}
            type="submit"
          >
            {submitLoading ? (
              <CircularProgress size="1.7rem" sx={{ color: "white" }} />
            ) : (
              "Continue"
            )}
          </ConfirmButton>

          {!!error &&
            <ErrorText color="error" data-cy="link-529-credentials-error">
              {error}
            </ErrorText>
          }
        </DisclaimerContainer>
      </form>
    </Content>
  );
};

export default Credentials529;