import React, { FC } from "react";
import { Button, CircularProgress, Stack, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";

import SingleCharacterInputGroup from "commons/Input/SingleCharacterInputGroup";

import { InputValidator } from "utils/validations";
import PaymentServices from "services/payment.services";
import type { PaymentInfo } from "@lib/types/institutionPayment";

export type EducationInstitution = {
  key: string;
  name: string;
};

const Content = styled(Stack)(({ theme }) => ({
  margin: "1.75rem",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)({
  width: "90%",
  textAlign: "justify",
  marginTop: "1rem",
});

const ConfirmButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
});

const TextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.9rem",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

interface MFAVerificationProp {
  handleNext: () => void;
  updatePaymentInfo: (info: Partial<PaymentInfo>) => void;
  lastPhoneNumberDigits?: string;
  paymentInfo: Partial<PaymentInfo>;
}

const MFAVerification: FC<MFAVerificationProp> = ({
  handleNext,
  updatePaymentInfo,
  lastPhoneNumberDigits,
  paymentInfo,
}) => {
  const [code, setCode] = React.useState<string>("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<"none" | "resend" | "validate">("none");

  React.useEffect(() => {
    PaymentServices.sendPaymentMFA().catch(setError);
  }, []);

  const isValidCode = () => {
    const isValid = InputValidator.isValidCode(code);
    setError(isValid ? "" : "Please enter a valid code");
    return isValid;
  };

  const handleVerifyCode = async () => {
    try {
      if (error || !isValidCode()) return;
      setError("");
      setLoading("validate");
      if (paymentInfo.recipient?.id) {
        const institution_payment_token = await PaymentServices.getPaymentToken(
          code,
          paymentInfo.recipient?.id,
        );
        updatePaymentInfo({ institution_payment_token });
        setLoading("none");
        handleNext();
      } else {
        setLoading("none");
        setError("Institution is invalid, please try again");
      }
    } catch (err: any) {
      setLoading("none");
      setError(err);
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    if (code.length > 6 || isNaN(Number(code))) return;
    setCode(code);
  };

  const handleRetry = async () => {
    try {
      setCode("");
      setLoading("resend");
      await PaymentServices.sendPaymentMFA();
      setLoading("none");
    } catch (err: any) {
      setLoading("none");
      setError("An error occurred, please try again or contact support");
    }
  };

  return (
    <Content>
      <Stack justifyContent="center" alignItems="center" spacing={3}>
        <Typography variant="h5">We just texted you</Typography>
        <Typography sx={{ width: "90%", textAlign: "center" }}>
          Please enter the code we just sent to your phone number ending in {lastPhoneNumberDigits}
        </Typography>
        <SingleCharacterInputGroup
          length={6}
          error={!!error}
          onChange={handleValueChange}
          value={code}
        />
        <ConfirmButton
          data-cy="payment-mfa-confirm"
          variant="contained"
          onClick={handleVerifyCode}
          disabled={!InputValidator.isValidCode(code)}>
          {loading === "validate" ? (
            <CircularProgress size="1.7rem" sx={{ color: "white" }} />
          ) : (
            "Verify code"
          )}
        </ConfirmButton>
        {error && (
          <Typography
            color="error"
            sx={{ display: "flex", justifyContent: "center" }}
            data-cy="payment-mfa-error">
            {error}
          </Typography>
        )}
        <TextButton onClick={handleRetry} variant="text" data-cy="payment-mfa-request">
          {loading === "resend" ? (
            <CircularProgress size="1.7rem" sx={{ color: "gray" }} />
          ) : (
            "Request another code"
          )}
        </TextButton>
        <Stack justifyContent="center" alignItems="center" spacing={2} width={300}>
          <Text sx={{ fontWeight: "bold" }} variant="legal" data-cy="payment-mfa-legal">
            We want to make 100% sure it is you making the payment!
          </Text>
          <Text variant="legal">
            If this is not the correct number or you need to change the number on your account,
            please
            <Link id="intercom_support" href="https://www.backpack529.com/contact" underline="none">
              <b> contact support</b>
            </Link>
            .
          </Text>
        </Stack>
      </Stack>
    </Content>
  );
};

export default MFAVerification;
