import { LinkType } from "@lib/enums/link529";
import type { UUID } from "@lib/types/global";
import type { State529Plan } from "@lib/types/state529";

export enum Actions {
  CANCEL = "cancel",
  CONTINUE = "continue",
  VERIFY_BACKPACK_ACCOUNT = "verify_backpack_account",
  SET_POLICY = "set_policy",
  RESTART = "restart",
  SET_ERROR = "set_error",
  SET_RELINK = "set_relink",
}

export enum Steps {
  TERMS_OF_SERVICE = "529 Account Connection",
  LINK_529_CREDENTIALS = "529 Credentials",
  LINK_529_MFA = "Verification Code",
  ADD_BACKPACK_ACCOUNT = "Ready to Add Account",
  SUCCESS = "Success",
}

export type FlowState = {
  step: Steps;
  stepCount: {
    current: number;
    total: number;
  };
  policy: { id: UUID; url: string };
  hasBackpackAccountAdded: boolean;
  beneficiaryPlan: State529Plan;
  error: "unexpected" | "connection" | "account" | null;
  type: LinkType;
  amount: number;
};

type CreateInitialStateProps = {
  amount?: FlowState["amount"];
  linkType?: FlowState["type"];
  state529Plan: State529Plan;
};

export type Action =
  | { type: Actions.CONTINUE }
  | { type: Actions.CANCEL }
  | { type: Actions.VERIFY_BACKPACK_ACCOUNT; payload: { hasBackpackAccountAdded: boolean } }
  | { type: Actions.SET_POLICY; payload: { policy: { id: UUID; url: string } } }
  | { type: Actions.RESTART }
  | { type: Actions.SET_ERROR; payload: { type: "account" | "connection" | "unexpected" | null } };

const DEFAULT_LINK529_STATE = {
  step: Steps.TERMS_OF_SERVICE,
  stepCount: {
    current: 1,
    total: 5,
  },
  policy: {
    id: "",
    url: "",
  },
  hasBackpackAccountAdded: false,
  beneficiaryPlan: {} as State529Plan,
  error: null,
  type: LinkType.LINK,
  amount: 0,
};

export const link529Reducer = (state: FlowState, action: Action): FlowState => {
  switch (action.type) {
    case Actions.CONTINUE: {
      let step;
      switch (state.step) {
        case Steps.TERMS_OF_SERVICE:
          step = Steps.LINK_529_CREDENTIALS;
          break;
        case Steps.LINK_529_CREDENTIALS:
          step = Steps.LINK_529_MFA;
          break;
        case Steps.ADD_BACKPACK_ACCOUNT:
          step = Steps.SUCCESS;
          break;
        default:
          throw Error(`Unknown step for action ${action.type} with ${state.step}`);
      }

      return {
        ...state,
        step,
        stepCount: {
          ...state.stepCount,
          current: state.stepCount.current + 1,
        },
      };
    }

    case Actions.CANCEL: {
      return DEFAULT_LINK529_STATE;
    }

    case Actions.RESTART: {
      return {
        ...DEFAULT_LINK529_STATE,
        policy: state.policy,
        hasBackpackAccountAdded: state.hasBackpackAccountAdded,
        beneficiaryPlan: state.beneficiaryPlan,
        type: state.type,
        amount: state.amount
      };
    }

    case Actions.VERIFY_BACKPACK_ACCOUNT: {
      const hasBackpackAccountAdded = action.payload.hasBackpackAccountAdded;

      return {
        ...state,
        hasBackpackAccountAdded,
        step: hasBackpackAccountAdded ? Steps.SUCCESS : Steps.ADD_BACKPACK_ACCOUNT,
        stepCount: {
          ...state.stepCount,
          current: hasBackpackAccountAdded
            ? state.stepCount.current + 2
            : state.stepCount.current + 1,
        },
      };
    }

    case Actions.SET_POLICY:
      return {
        ...state,
        policy: action.payload.policy,
      };

    case Actions.SET_ERROR:
      return {
        ...state,
        error: action.payload.type,
      };

    default:
      return state;
  }
};

export const createInitialLink529State = ({
  amount,
  linkType,
  state529Plan
}: CreateInitialStateProps) => {
  const initialState = {
    ...DEFAULT_LINK529_STATE,
    beneficiaryPlan: state529Plan
  };

  if (!!amount && amount > 0) initialState.amount = amount;
  if (linkType) initialState.type = linkType;

  return initialState;
};
