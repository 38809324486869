import { If, Then, Else } from "react-if";

import Well from "components/Well";
import WarningIcon from "components/Icons/WarningIcon";
import HourglassIcon from "components/Icons/HourglassIcon";

import type { State529Connection } from "@lib/types/state529";
import { classnames } from "@utils/classnames";

type Link529StatusNoticeProps = {
  className?: string;
  state529Connection: State529Connection
}

const Link529StatusNotice = ({ className, state529Connection }: Link529StatusNoticeProps) => {
  const { status, is_withdrawal_eligible } = state529Connection;

  if ((status === "LINKED" && is_withdrawal_eligible) || status === "NEEDS_RELINK") {
    return null;
  }

  const wellClasses = classnames("text-sm", className);

  return (
    <If condition={status === "NOT_LINKED"}>
      <Then>
        <Well className={wellClasses}>
          <div className="flex gap-3 items-center">
            <div className="shrink-0">
              <WarningIcon className="text-warning-icon size-9 mt-1" aria-hidden="true" />
            </div>

            <p>Please link your 529 to Backpack and withdraw funds. To get started, <strong>click the “Link 529” button</strong>.</p>
          </div>
        </Well>
      </Then>

      {/* status === "LINKED" && !is_withdrawal_eligible */}
      <Else>
        <Well className={wellClasses}>
          <div className="flex gap-3 items-center">
            <div className="shrink-0">
              <HourglassIcon
                className="text-gray-700 size-9 mt-1"
                type="top"
                aria-hidden="true"
              />
            </div>

            <p>Your 529 is validating your Backpack bank account. This may take up to 15 days. We&apos;ll notify you when you can add funds. Meanwhile, set up tuition payment.</p>
          </div>
        </Well>
      </Else>
    </If>
  );
};

export default Link529StatusNotice;
