import { Switch, Case, Default, If, Then, Else } from "react-if";

import { Button, Stack, typographyClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

import OnboardingSuccess from "assets/images/successStudent.svg";
import { LinkType } from "@lib/enums/link529";

const SuccessConnectionStack = styled(Stack)({
  width: "100%",
  paddingBottom: 0,
  flexGrow: "1",
  alignItems: "center",
  justifyContent: "space-between",
});

const Logo = styled("img")(({ theme }) => ({
  width: "70%",
  backgroundColor: "white",
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const FinishContainer = styled(Stack)({
  width: "85%",
  marginTop: "1rem",
});

const FinishButton = styled(Button)(({ theme }) => ({
  width: "100%",
  marginBottom: "1rem",
  padding: ".625rem 0",
  [typographyClasses.root]: {
    paddingLeft: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
  },
}));

type SuccessConnectionProps = {
  linkType: LinkType;
  goToDashboard: () => void;
  goToWithdrawal: () => void;
}

const SuccessConnection = ({
  linkType,
  goToDashboard,
  goToWithdrawal
}: SuccessConnectionProps) => {
  return (
    <SuccessConnectionStack>
      <Stack>
        <Logo src={OnboardingSuccess} alt={"Backpack Logo"} />

        <Switch>
          <Case condition={linkType === LinkType.RELINK}>
            <p data-cy="link-success-relink-text" className="text-left m-2 font-bold">We have established a connection with your 529 Account.</p>

            <p className="text-left m-2">More good news! We also see that you have your Backpack account set up to receive withdrawals in your 529 account so you are all done!</p>

            <p className="text-left m-2">Click “Finish” to go back to your dashboard.</p>
          </Case>

          <Case condition={linkType === LinkType.RELINK_WITHDRAWAL}>
            <p data-cy="link-success-relink-withdrawal" className="text-left m-2 font-bold">We have established a connection with your 529 Account.</p>

            <p className="text-left m-2">More good news! We also see that you have your Backpack account set up to receive withdrawals in your 529 account so you are all done!</p>

            <p className="text-left m-2">Click “Add 529 Funds” to continue with the withdrawal process.</p>
          </Case>

          <Default>
            <p data-cy="link-success-default-text" className="text-left m-2 font-bold">Your Backpack account has been added to your 529 Account as a withdrawal method.</p>

            <p className="text-left m-2">Note that you may see the Financial Institution associated with your Backpack account listed as OMB Bank.</p>

            <p className="text-left m-2">We partner with OMB Bank, a state chartered Bank regulated by the FDIC. We do this to make sure your money is safe and secure!</p>
          </Default>
        </Switch>
      </Stack>

      <FinishContainer>
        <If condition={linkType !== LinkType.RELINK_WITHDRAWAL}>
          <Then>
            <FinishButton
              data-cy="link-finish-button"
              variant="contained"
              onClick={goToDashboard}
            >
              Finish
            </FinishButton>
          </Then>

          <Else>
            <FinishButton
              data-cy="link-finish-withdrawal-button"
              variant="contained"
              onClick={goToWithdrawal}
            >
              Add 529 Funds
            </FinishButton>

            <FinishButton
              data-cy="link-finish-dashboard-button"
              variant="text"
              onClick={goToDashboard}
            >
              Go to the Dashboard
            </FinishButton>
          </Else>
        </If>
      </FinishContainer>
    </SuccessConnectionStack>
  );
};

export default SuccessConnection;
