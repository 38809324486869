import { SORT_TYPE } from "@lib/enums/global";
import type { Transaction } from "@lib/types/transaction";

export function sortTransactions({
  transactions,
  sort = SORT_TYPE.DESCENDING
}: {
  transactions: Transaction[];
  sort?: "ascending" | "descending"
}) {
  return transactions.sort((a, b) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();

    // descending order (latest date first)
    if (sort === SORT_TYPE.DESCENDING) {
      return dateB - dateA;
    }

    return dateA - dateB;
  });
}

export default { sortTransactions };