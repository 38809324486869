import clsx from "clsx";

export type StepperProps = {
  steps: string[];
  current: number;
};

const Stepper = ({ current, steps }: StepperProps) => {
  return (
    <ol className="w-full flex items-center justify-center gap-1">
      {steps.map((step, index) => (
        <li
          key={`${step}${index}`}
          className={clsx("w-8 h-3 rounded-md", [
            index + 1 > current ? "bg-gray-300" : "bg-[#003afc]",
          ])}
        />
      ))}
    </ol>
  );
};

export default Stepper;
