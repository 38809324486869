import { create } from "@stores/utils";

import { Flow, TuitionPaymentSteps } from "@lib/enums/flows";
import type { CreateInstitutionPaymentPayload } from "@lib/types/institutionPayment";

type TuitionPaymentQueryParam = {
  [Flow.TUITION_PAYMENT]: TuitionPaymentSteps;
};

type TuitionPaymentState = {
  step: {
    current: TuitionPaymentSteps | null;
    prev: TuitionPaymentSteps | null;
  };
  screen: {
    current: TuitionPaymentSteps | null;
    prev: TuitionPaymentSteps | null;
  };
  queryParam: TuitionPaymentQueryParam;
  tuitionPaymentPayload: CreateInstitutionPaymentPayload;
  errorMessage: string | null;
  mfaError: Error | null;
};

type TuitionPaymentAction = {
  goToNextStep: () => void;
  goToPrevStep: () => void;
  setErrorMessage: (errorMessage: string) => void;
  setMFAError: (error: Error) => void;
  clearErrors: () => void;
  updateTuitionPaymentPayload: (update: Partial<CreateInstitutionPaymentPayload>) => void;
  showSkipTuitionDialog: () => void;
  reset: () => void;
};

const DEFAULT_INITIAL_STATE = {
  step: {
    current: TuitionPaymentSteps.NEW,
    prev: null,
  },
  screen: {
    current: TuitionPaymentSteps.NEW,
    prev: null,
  },
  queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW },
  tuitionPaymentPayload: {
    institution_payment_token: "",
    beneficiary_enrollment_id: "",
    institution_period_id: "",
    amount_in_cents: 0
  },
  errorMessage: null,
  mfaError: null
};

export const useTuitionPaymentsStore = create<TuitionPaymentState & TuitionPaymentAction>()((set, get) => ({
  ...DEFAULT_INITIAL_STATE,

  goToNextStep: () => {
    const currentStep = get().step.current;
    let nextStep;
    let prevStep = null;

    let nextScreen: (TuitionPaymentSteps | null) = TuitionPaymentSteps.NEW;
    let prevScreen = null;

    let queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW };

    switch (currentStep) {
      case TuitionPaymentSteps.REVIEW:
        nextStep = TuitionPaymentSteps.CREATED;
        nextScreen = null;

        queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED };
        break;

      case TuitionPaymentSteps.MFA:
        nextStep = TuitionPaymentSteps.REVIEW;
        prevStep = TuitionPaymentSteps.NEW;

        nextScreen = TuitionPaymentSteps.REVIEW;
        prevScreen = TuitionPaymentSteps.NEW;

        queryParam = { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.REVIEW };
        break;

      case TuitionPaymentSteps.NEW:
      default:
        nextStep = TuitionPaymentSteps.MFA;
        prevStep = TuitionPaymentSteps.NEW;

        nextScreen = TuitionPaymentSteps.NEW;
        prevScreen = TuitionPaymentSteps.NEW;
        break;
    }

    set({
      step: {
        current: nextStep,
        prev: prevStep
      },
      screen: {
        current: nextScreen,
        prev: prevScreen
      },
      queryParam
    });
  },

  goToPrevStep: () => {
    set({
      step: {
        current: TuitionPaymentSteps.NEW,
        prev: null
      },
      screen: {
        current: TuitionPaymentSteps.NEW,
        prev: null
      },
      queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW }
    });
  },

  updateTuitionPaymentPayload: (update) => {
    set((state) => ({
      ...state,
      tuitionPaymentPayload: {
        ...state.tuitionPaymentPayload,
        ...update
      }
    }));
  },

  showSkipTuitionDialog: () => {
    set({
      step: {
        current: TuitionPaymentSteps.SKIPPED,
        prev: TuitionPaymentSteps.NEW
      },
      screen: {
        current: TuitionPaymentSteps.NEW,
        prev: TuitionPaymentSteps.NEW
      },
      queryParam: { [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.NEW }
    });
  },

  setErrorMessage: (errorMessage) => {
    set({ errorMessage });
  },

  setMFAError: (error) => {
    set({ mfaError: error });
  },

  clearErrors: () => {
    set({
      errorMessage: null,
      mfaError: null
    });
  },

  reset: () => {
    set(DEFAULT_INITIAL_STATE);
  }
}));