const CardLoadingSkeleton = () => {
  return (
    <div role="status" className="max-w-screen-md p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700 flex max-sm:flex-col justify-between h-28 mb-4">
      <div className="flex flex-col justify-between gap-2 md:gap-6">
        <div className="h-6 bg-gray-200 rounded-full w-40"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-40"></div>
      </div>

      <div className="flex flex-col justify-between gap-2 md:gap-6">
        <div className="h-2.5 bg-gray-200 rounded-full w-52"></div>
        <div className="h-2.5 bg-gray-200 rounded-full w-52"></div>
      </div>
    </div>
  );
};

export default CardLoadingSkeleton;