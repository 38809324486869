import { useMemo } from "react";

import Card from "@components/Card";
import Select from "@components/Select/Select";

import { useTransactionsStore } from "@stores/transactionsStore";

import { formatCurrency } from "utils/formats";

import { TransactionType } from "@lib/enums/transaction";
import type { Transaction } from "@lib/types/transaction";

import { createYearOptions } from "@utils/createYearOptions";

type Calculate529DollarsProps = {
  transactions: Transaction[];
  type: TransactionType;
  year: string | number;
}

// We only support single beneficiary so this is hard coded for now
const BENEFICIARY_OPTIONS = [
  { label: "All Beneficiaries", value: "ALL" },
];

function calculate529Dollars({
  transactions,
  type,
  year
}: Calculate529DollarsProps) {
  let amount = 0;

  transactions.forEach((transaction) => {
    const newDate = new Date(transaction.created_at);
    const isCurrentYear = newDate.getUTCFullYear() === Number(year);

    if ((transaction.type === type) && isCurrentYear) {
      amount += Math.abs(transaction.amount_in_cents);
    }
  });

  return formatCurrency(amount);
}

const TransactionSummary = () => {
  const transactions = useTransactionsStore((state) => state.initialTransactions);
  const selectedTransactionYear = useTransactionsStore((state) => state.filters.year);

  const filterByYear = useTransactionsStore((state) => state.filterByYear);

  const year_options = useMemo(() => createYearOptions(), []);

  const dollarsDeposited = useMemo(() => calculate529Dollars({
    transactions,
    year: selectedTransactionYear,
    type: TransactionType.DEPOSIT,
  }), [selectedTransactionYear, transactions]);

  const dollarsSpent = useMemo(() => calculate529Dollars({
    transactions,
    year: selectedTransactionYear,
    type: TransactionType.WITHDRAWAL,
  }), [selectedTransactionYear, transactions]);

  return (
    <div className="mb-7" data-testid="transactions-summary-component">
      <div className="flex max-sm:flex-col gap-5 justify-between items-start sm:items-center mb-5">
        <h2 className="text-lg text-primary"><span className="sr-only">Transactions </span>Summary</h2>

        <div className="flex flex-wrap sm:flex-nowrap gap-3">
          <div>
            <Select
              hideLabel
              id="transactions_year"
              label="Transactions Year"
              options={year_options}
              className="text-blue text-base font-bold"
              selected={selectedTransactionYear}
              data-testid="transactions-year-select"
              onChange={(event) => filterByYear(event.currentTarget.value)}
            />
          </div>

          <div>
            <Select
              hideLabel
              id="transactions_beneficiaries"
              label="Transactions Beneficiaries"
              options={BENEFICIARY_OPTIONS}
              className="text-blue text-base font-bold"
              data-testid="transactions-beneficiary-select"
              onChange={() => {}}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex max-sm:flex-wrap gap-5">
        <Card containerClassName="w-full">
          <span className="block card-label mb-1">529 Dollars Deposited</span>
          <span className="text-blue font-semibold text-3xl" data-testid="529-dollars-deposited">{dollarsDeposited}</span>
        </Card>

        <Card containerClassName="w-full">
          <span className="block card-label mb-1">529 Dollars Spent</span>
          <span className="text-blue font-semibold text-3xl" data-testid="529-dollars-spent">{dollarsSpent}</span>
        </Card>
      </div>
    </div>
  );
};

export default TransactionSummary;