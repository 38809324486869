export type InsightModals = {
  [key: string]: InsightModalType;
};

export const INSIGHT_MODALS: InsightModals = {
  LINK_529: "link_529",
  UNIVERSITY_EXCLUSIVE: "university_exclusive",
  PURCHASE_LAPTOP: "purchase_exclusive",
  STORE_RECEIPTS: "store_receipts",
  SHOP_AMAZON: "shop_amazon"
};

export type InsightModalType = "link_529"
  | "university_exclusive"
  | "purchase_exclusive"
  | "store_receipts"
  | "shop_amazon"
  | null;