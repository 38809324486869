import { useState } from "react";
import { When } from "react-if";

import { TooltipProps as MUITooltipProps } from "@mui/material";

import Tooltip from "@components/Tooltip";
import CopyIcon from "@components/Icons/CopyIcon";
import { useNotification } from "@lib/hooks/useNotification";
import { classnames } from "@lib/utils/classnames";

type CopyToClipboardProps = {
  value: string;
  children?: React.ReactNode;
  /**
   * use for the copy to clipboard text styles
   *
   * default is text-primary hover:text-blue
   */
  copyTextClassnames?: string;
  /**
   * use for the copy to clipboard icon styles
   */
  notificationMessage?: string;
  showIcon?: boolean;
  tooltip?: {
    /**
     * tooltip text
     *
     * default is "Copy"
     */
    text?: string;
    /**
     * default tooltip place is right
     */
    placement?: MUITooltipProps["placement"];
  }
};

const CopyToClipboard = ({
  children,
  notificationMessage,
  value,
  copyTextClassnames = "text-primary hover:text-blue",
  tooltip = {
    text: "Copy",
    placement: "right"
  },
  showIcon = true,
}: CopyToClipboardProps) => {
  const [copy, setCopy] = useState(tooltip.text);
  const notification = useNotification();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value);

    setCopy("Copied!");

    if (notificationMessage) {
      notification.show(notificationMessage, {
        position: "center"
      });
    }

    setTimeout(() => {
      setCopy(tooltip.text);
    }, 1500);
  };

  return (
    <>
      <Tooltip
        title={copy}
        placement={tooltip.placement}
        arrow
      >
        <div
          role="button"
          tabIndex={0}
          className={classnames("flex justify-between items-center gap-1 cursor-pointer", copyTextClassnames)}
          aria-label="Copy"
          onClick={handleCopy}
        >
          {children}

          <When condition={showIcon}>
            <CopyIcon aria-hidden="true" className="size-5" />
          </When>
        </div>
      </Tooltip>
    </>
  );
};

export default CopyToClipboard;