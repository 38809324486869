import React, { FC } from "react";
import IconButton from "@mui/material/IconButton";

import Accordion from "components/Accordion";

import type { Statement } from "@lib/types/statement";

import SaveAltIcon from "@mui/icons-material/SaveAlt";

type StatementsAccordionProps = {
  statements: Statement[];
  onClick: (statement: Statement) => void;
};

const StatementsAccordion: FC<StatementsAccordionProps> = ({ statements, onClick }) => {
  const statementsByYear = React.useMemo(
    () =>
      statements.reduce(
        (acc, statement) => {
          const year = new Date(statement.start_date).getFullYear();
          if (!acc[year]) acc[year] = [];
          acc[year].push(statement);
          return acc;
        },
        {} as Record<string, Statement[]>,
      ),
    [statements],
  );

  const formatLabel = (statement: Statement, year: string) => {
    const month = new Date(statement.start_date).toLocaleString("default", { month: "long", timeZone: "UTC" });
    return `${month} ${year}`;
  };

  const getStatementRow = (statement: Statement, year: string) => {
    const label = formatLabel(statement, year);
    return (
      <li key={statement.id}>
        {label}
        <IconButton onClick={() => onClick(statement)} aria-label={`Download ${label}`}>
          <SaveAltIcon />
        </IconButton>
      </li>
    );
  };

  if (statements.length === 0) return <p>No statements yet!</p>;

  return Object.entries(statementsByYear).map(([year, statements]) => (
    <Accordion title={year} key={year} allowCollapse>
      <ul className="accordion-list">
        {statements.map((statement) => getStatementRow(statement, year))}
      </ul>
    </Accordion>
  ));
};

export default StatementsAccordion;
