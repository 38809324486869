import React, { FC } from "react";
import { Button } from "@mui/material";

import VerticalCard from "commons/Card/Vertical";
import Loader from "components/Loader";
import StatementsAccordion from "./StatementsAccordion";

import { StatementsService } from "services/statements.services";

import type { BankAccount } from "@lib/types/account";
import type { Statement } from "@lib/types/statement";

type StatementsProps = {
  handleClose: () => void;
  bankAccount: BankAccount;
};

const Statements: FC<StatementsProps> = ({ handleClose, bankAccount }) => {
  const [status, setStatus] = React.useState<"loading" | "loaded" | "error">("loading");
  const [statements, setStatements] = React.useState<Statement[]>([]);

  const getStatements = async () => {
    try {
      const statements = await StatementsService.getStatements(bankAccount.id);
      setStatements(statements);
      setStatus("loaded");
    } catch (err) {
      setStatus("error");
    }
  };

  React.useEffect(() => {
    getStatements();
  }, []);

  const handleDownloadClick = async (statement: Statement) => {
    const link = await StatementsService.getStatementDownloadURL(statement.id);
    // open a new tab with the download link
    window.open(link, "_blank");
  };

  let content;

  if (status === "loading") {
    content = <Loader />;
  } else if (status === "loaded") {
    content = <StatementsAccordion statements={statements} onClick={handleDownloadClick} />;
  }

  return (
    <VerticalCard onClose={handleClose}>
      <div className="settings-modal-container">
        <div>
          <h4 className="title">Statements</h4>
          {content}
        </div>
        <Button
          className="w-full rounded-full"
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          Done
        </Button>
      </div>
    </VerticalCard>
  );
};

export default Statements;
