import { FC } from "react";
import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

type TooltipProps = MUITooltipProps & {
  variant?: "default" | "styled";
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "0.5rem",
    opacity: 1,
    color: theme.palette.primary.dark,
  },
}));

const Tooltip: FC<TooltipProps> = ({ children, variant, ...props }) => {
  if (variant === "styled") return <StyledTooltip {...props}>{children}</StyledTooltip>;
  return <MUITooltip {...props}>{children}</MUITooltip>;
};

export default Tooltip;
