import React, { FC } from "react";
import { Container, Grid, Step, Stepper, Typography, containerClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

const ProgressConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 2px)",
    right: "calc(50% + 2px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 11,
    borderRadius: "9px",
  },
}));

const StepperContainer = styled(Container)({
  [`&.${containerClasses.root}`]: {
    padding: "1.5rem 0 2rem 0",
  },
});

const StepperGrid = styled(Grid)({
  paddingLeft: "40px",
  paddingRight: "40px",
  justifyItems: "center",
  width: "100%",
});

interface StepperProps {
  stepNames: string[];
  activeStep: number;
  children: React.ReactNode;
}

const StepperHeader: FC<StepperProps> = ({ activeStep, stepNames, children }) => {
  const steps = Array.from({ length: stepNames.length + 1 }, (_, index) => index);
  return (
    <>
      <StepperContainer>
        <Typography variant="action">
          <h3 className="text-lg">{stepNames[activeStep - 1]}</h3>
        </Typography>
        <Grid container>
          <StepperGrid item>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ProgressConnector />}>
              {steps.map((label) => (
                <Step key={label}></Step>
              ))}
            </Stepper>
          </StepperGrid>
        </Grid>
      </StepperContainer>
      {children}
    </>
  );
};

export default StepperHeader;
