import { FC } from "react";
import { If, Else, Then } from "react-if";
import Card from "components/Card";

import CopyToClipboard from "@components/CopyToClipboard/CopyToClipboard";
import { classnames } from "@lib/utils/classnames";

export type Property = {
  label: string;
  value: string;
  displayValue: string;
  variant?: "regular" | "bold";
  allowCopy?: boolean;
};

export type AccountDetailCardProps = {
  properties: Property[];
  className?: string;
  rowClassName?: string;
};

/**
 * Deprecated
 * Use @componenents/AccountDetailsCard/AccountDetailsCard
 */
export const AccountDetailCard: FC<AccountDetailCardProps> = ({
  properties,
  className = "",
  rowClassName = "",
}) => {

  return (
    <Card>
      <ul className={classnames("flex flex-col gap-3", className)}>
        {properties.map((property) => (
          <li key={property.label} className={classnames("flex justify-between items-center", rowClassName)}>
            <p className="card-label">{property.label}</p>

            <If condition={property.allowCopy}>
              <Then>
                <CopyToClipboard value={property.value}>
                  <p className={classnames(property.variant === "bold" && "font-bold")}>{property.displayValue}</p>
                </CopyToClipboard>
              </Then>

              <Else>
                <p className={classnames(property.variant === "bold" && "font-bold")}>{property.displayValue}</p>
              </Else>
            </If>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default AccountDetailCard;
