import { NavLink } from "react-router-dom";

import HomeIcon from "@components/Icons/HomeIcon";
import SettingsIcon from "@components/Icons/SettingsIcon";

import { classnames } from "@lib/utils/classnames";
import ChartBar from "@components/Icons/ChartBar";

const NAVIGATION = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: HomeIcon
  },
  {
    name: "Transactions",
    path: "/transactions",
    icon: ChartBar
  },
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsIcon
  },
];

const SidebarNav = () => {
  return (
    <>
      <div className="hidden sticky top-0 z-50 md:flex flex-col w-60 h-full">
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white p-6 xl:py-8">
          <nav className="flex flex-1 flex-col" data-testid="sidebar-nav">
            <ul role="list" className="-mx-2 space-y-1">
              {NAVIGATION.map((item, index) => (
                <li key={item.name}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => classnames(
                      "group flex items-center gap-x-3 rounded-md p-2 text-base text-gray-700 hover:text-blue hover:bg-gray-50 focus:text-blue focus:bg-gray-50 leading-6",
                      [isActive ? "bg-gray-50 font-semibold text-blue" : "font-medium"]
                    )}
                    data-testid="sidebar-nav-link"
                  >
                    {({ isActive }) => (
                      <>
                        <item.icon
                          aria-hidden="true"
                          outline={true}
                          className={classnames(
                            "size-5 shrink-0",
                            index === 0 && "md:mb-px",
                            isActive ? "text-blue" : "text-gray-500 group-hover:text-blue"
                          )}
                        />
                        {item.name}
                      </>
                    )}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* mobile nav */}
      <div className="fixed top-auto -bottom-px z-50 md:hidden bg-white w-full">
        <nav className="border-t border-gray-200" data-testid="sidebar-nav-mobile">
          <ul role="list" className="w-full grid grid-cols-5">
            {NAVIGATION.map((item, index) => (
              <li key={item.name} className="col-span-1 px-4 py-2">
                <div className="flex items-center justify-center">
                  <NavLink
                    to={item.path}
                    className="group flex flex-col items-center justify-center w-fit p-2"
                    data-testid="mobile-sidebar-nav-link"
                  >
                    {({ isActive }) => (
                      <>
                        <item.icon
                          aria-hidden="true"
                          outline={!isActive}
                          className={classnames(
                            "size-8 shrink-0 group-focus:stroke-2 group-active:stroke-2",
                            index === 0 && "md:mb-px",
                            isActive ? "text-blue" : "text-gray-500 group-hover:text-blue"
                          )}
                        />

                        <span className={classnames("text-[10px] mt-1", isActive ? "font-semibold text-blue" : "font-medium text-gray-500 group-hover:text-blue group-hover:font-semibold")}>{item.name}</span>
                      </>
                    )}
                  </NavLink>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {/* mobile nav ends */}
    </>
  );
};

export default SidebarNav;