import * as Sentry from "@sentry/react";
import instance from "config/fetch.config";
import type { UUID } from "@lib/types/global";
import type { InstitutionPayment } from "@lib/types/institutionPayment";
import { errorMapper } from "utils/errors";

class PaymentServices {
  static async getInstitutionPayments(): Promise<InstitutionPayment[]> {
    try {
      const {
        data: { data },
      } = await instance.get("/institution-payments?status=pending");

      return data.institution_payments;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async sendPaymentMFA(): Promise<void> {
    try {
      await instance.post("/institution-payments/mfa-codes");
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async getPaymentToken(mfa_code: string, institution_id: UUID): Promise<string> {
    try {
      const {
        data: { data },
      } = await instance.post("/institution-payments/tokens", { mfa_code, institution_id });

      return data.token;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async createPayment(paymentData: {
    institution_payment_token: string;
    beneficiary_enrollment_id: UUID;
    institution_period_id: UUID;
    amount_in_cents: number;
  }): Promise<{ status: string }> {
    try {
      const {
        data: { data },
      } = await instance.post("/institution-payments", paymentData);

      return data.institution_payment;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default PaymentServices;
