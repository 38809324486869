import { useReducer } from "react";
import { Switch, Case, Default, If, Then, Else, When } from "react-if";

import Stepper from "components/Stepper/Stepper";
import WithdrawalAmountScreen from "./Screens/WithdrawalAmount";
import WithdrawalVerify from "./Screens/WithdrawalVerify";
import WithdrawalSuccess from "./Screens/WithdrawalSuccess";
import WithdrawalConfirmation from "./Screens/WithdrawalConfirmation";
import WithdrawalGeneralError from "./Screens/WithdrawalGeneralError";
import WithdrawalUnableToInitiate from "./Screens/WithdrawalUnableToInitiate";
import Spinner from "@components/Spinner/Spinner";

import BackpackLogoHorizontal from "assets/images/BackpackLogoHorizontal.svg";

import {
  automatedWithdrawalReducer,
  createInitialWithdrawalState,
  Actions,
  Steps
} from "reducers/AutomatedWithdrawalReducer";

import { startCase } from "utils/formats";

import WithdrawalServices from "services/withdrawal.services";

import { WithdrawalError, WithdrawalStatus } from "@lib/enums/withdrawal";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { State529Plan } from "@lib/types/state529";
import type { WithdrawalModalPayload } from "@lib/types/modal";

type WithdrawalProps = {
  amount?: number;
  beneficiary: Beneficiary;
  state529Plan: State529Plan;
  handleClose: (payload?: WithdrawalModalPayload) => void;
};

const Withdrawal = ({ beneficiary, state529Plan, amount, handleClose }: WithdrawalProps) => {
  const [state, dispatch] = useReducer(
    automatedWithdrawalReducer,
    createInitialWithdrawalState({
      beneficiary,
      state529Plan,
      amount,
    }),
  );

  const setAmount = (amount: number) => {
    dispatch({
      type: Actions.SET_AMOUNT,
      payload: { amount },
    });

    goToNextScreen();
  };

  const createWithdrawalRequest = async () => {
    try {
      const withdrawalRequest = await WithdrawalServices.createWithdrawalRequest({
        state529PlanId: state.state529Plan.id,
        payload: {
          amount_in_cents: state.amount,
          beneficiary_id: beneficiary.id,
        }
      });

      if (withdrawalRequest.status === WithdrawalStatus.CREATED) {
        dispatch({
          type: Actions.START_WITHDRAWAL_REQUEST,
          payload: { id: withdrawalRequest.id, status: withdrawalRequest.status },
        });

        return goToNextScreen();
      }

      handleErrorStatus(withdrawalRequest.status);
    } catch (error: any) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: { error: WithdrawalError.UNEXPECTED },
      });
    }
  };

  const handleVerifyMFA = async (code: string) => {
    try {
      if (state.withdrawalId === null) {
        throw new Error("Missing withdrawal request id");
      }

      const { status, id } = await WithdrawalServices.submitWithdrawalRequest({
        withdrawalRequestId: state.withdrawalId,
        state529PlanId: state.state529Plan.id,
        payload: { mfa_code: code }
      });

      if (status === WithdrawalStatus.SUBMITTED) {
        dispatch({
          type: Actions.UPDATE_WITHDRAWAL_REQUEST,
          payload: { status, confirmationId: id }
        });

        return goToNextScreen();
      }

      handleErrorStatus(status);
    } catch (error: any) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: { error: WithdrawalError.UNEXPECTED },
      });
    }
  };

  const handleErrorStatus = (status: WithdrawalStatus) => {
    switch (status) {
      case WithdrawalStatus.INSUFFICIENT_FUNDS: {
        dispatch({
          type: Actions.SET_ERROR,
          payload: { error: WithdrawalError.REQUIREMENTS_NOT_MET, status },
        });

        break;
      }

      case WithdrawalStatus.FAILED: {
        dispatch({
          type: Actions.SET_ERROR,
          payload: { error: WithdrawalError.UNEXPECTED, status },
        });

        break;
      }

      case WithdrawalStatus.FAILED_NEEDS_RELINK: {
        handleCloseModal({
          status: WithdrawalStatus.FAILED_NEEDS_RELINK,
          amount: state.amount,
        });

        break;
      }
    }
  };

  const handleCloseModal = (payload?: WithdrawalModalPayload) => {
    if (payload?.status !== WithdrawalStatus.SUBMITTED) {
      dispatch({
        type: Actions.CANCEL,
      });
    }

    handleClose(payload);
  };

  const goToNextScreen = () => {
    dispatch({ type: Actions.CONTINUE });
  };

  return (
    <div className="flex flex-col items-center overflow-y-auto h-[876px] px-8">
      <If condition={!state.error}>
        <Then>
          <When condition={state.step !== Steps.SUCCESS}>
            <div className="flex flex-col items-center p-0 md:p-4">
              <h3 className="text-blue text-lg text-center mb-4">{startCase(state.step)}</h3>
              <Stepper steps={Object.keys(Steps)} current={state.stepCount.current} />
            </div>
          </When>

          <Switch>
            <Case condition={state.step === Steps.AMOUNT}>
              <WithdrawalAmountScreen defaultValue={amount} handleNext={(amount) => setAmount(amount)} />
            </Case>

            <Case condition={state.step === Steps.CONFIRMATION}>
              <WithdrawalConfirmation
                amount={state.amount}
                state529PlanName={state.state529Plan.name}
                handleNext={createWithdrawalRequest}
              />
            </Case>

            <Case condition={state.step === Steps.VERIFY}>
              <WithdrawalVerify handleNext={handleVerifyMFA} />
            </Case>

            <Case condition={state.step === Steps.SUCCESS}>
              <WithdrawalSuccess
                confirmationDetails={{
                  confirmationId: state.confirmationId,
                  amount: state.amount,
                  state529Plan: state.state529Plan
                }}
                handleNext={() => handleCloseModal({ status: WithdrawalStatus.SUBMITTED })}
              />
            </Case>

            <Default>
              <div className="flex justify-center items-center grow w-80">
                <Spinner size="lg" />
              </div>
            </Default>
          </Switch>
        </Then>

        <Else>
          <Switch>
            <Case condition={state.error === WithdrawalError.UNEXPECTED}>
              <WithdrawalGeneralError
                handleNext={() => dispatch({ type: Actions.RESTART })}
                nextAction="RESTART"
              />
            </Case>

            <Case condition={state.error === WithdrawalError.REQUIREMENTS_NOT_MET}>
              <WithdrawalUnableToInitiate
                handleNext={() => dispatch({ type: Actions.RESTART })}
                nextAction="RESTART"
              />
            </Case>
          </Switch>
        </Else>
      </If>

      <footer data-cy="footer" className="flex justify-center items-center py-8">
        <img alt="Backpack Logo" src={BackpackLogoHorizontal} height={25} />
      </footer>
    </div>
  );
};

export default Withdrawal;
