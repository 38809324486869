import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { BeneficiaryResponse } from "@lib/types/beneficiary";

export const getBeneficiaries = async (): Promise<BeneficiaryResponse[]> => {
  try {
    const { data: { data } } = await instance.get("/beneficiaries");

    return data.beneficiaries;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};