export class InputValidator {
  static isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-z]{2,3}/;
    return emailPattern.test(email);
  }

  static isValidPhoneNumber(phoneNumber: string): boolean {
    const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{9}$/;
    return phonePattern.test(phoneNumber);
  }

  static isValidCode(code: string, length: number = 6): boolean {
    const regexStringExpression = `^[0-9]{${length}}$`;
    const codeRegex = new RegExp(regexStringExpression, "g");
    return codeRegex.test(code);
  }

  static isNumeric(value: string): boolean {
    return /^\d+$/.test(value);
  }
}
