import { State529ConnectionStatus } from "@lib/enums/state529";

import type { CurrentUser } from "@lib/types/currentUser";
import type { BankAccount, BankAccountBalance } from "@lib/types/account";
import type {
  Beneficiary,
  Enrollment,
  BeneficiaryState529Plan
} from "@lib/types/beneficiary";
import type { Institution } from "@lib/types/institution";
import type { State529Connection } from "@lib/types/state529";

export const DEFAULT_529_PLAN_CONNECTION = {
  status: State529ConnectionStatus.NOT_LINKED,
  has_linked_before: false,
  is_withdrawal_eligible: false,
  is_automated_withdrawal_eligible: false,
  has_pending_withdrawal_request: false,
  last_link_event_dt: null
} as State529Connection;

export const DEFAULT_529_PLAN = {
  id: "",
  name: "",
  state: "",
  website_url: "",
  state_529_connection: DEFAULT_529_PLAN_CONNECTION
} as BeneficiaryState529Plan;

export const DEFAULT_BENEFICIARY = {
  id: "",
  first_name: "",
  last_name: "",
  enrollments: [] as Enrollment[],
  enrolled_institutions: [] as Institution[],
  state_529_plan_id: "",
  state_529_plan: DEFAULT_529_PLAN
} as Beneficiary;

export const DEFAULT_CURRENT_USER = {
  id: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  phone_number_last_four_digits: "",
  bank_account: {
    account_number: "000000000",
    routing_number: "000000000"
  } as BankAccount,
  bank_account_balance: {
    available_balance_in_cents: 0,
    current_balance_in_cents: 0
  } as BankAccountBalance,
  beneficiaries: [DEFAULT_BENEFICIARY] as Beneficiary[],
} as CurrentUser;