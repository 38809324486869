import instance from "config/fetch.config";
import { MutationError, QueryError } from "@utils/errors";

import type { BeneficiaryResponse, CreateBeneficiaryPayload, UpdateBeneficiaryEnrollment, UpdateBeneficiaryEnrollmentResponse } from "@lib/types/beneficiary";

export const createBeneficiary = async (beneficiary: CreateBeneficiaryPayload):Promise<BeneficiaryResponse> => {
  try {
    const { data: { data } } = await instance.post("/beneficiaries", beneficiary);

    return data.beneficiary;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};

export const updateBeneficiaryEnrollment = async ({
  beneficiary_id,
  enrollment_id,
  payload
}: UpdateBeneficiaryEnrollment): Promise<UpdateBeneficiaryEnrollmentResponse> => {
  try {
    const url = `/beneficiaries/${beneficiary_id}/enrollments/${enrollment_id}`;
    const { data: { data } } = await instance.patch(url, { ...payload });

    return data.enrollment;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};