import { create } from "@stores/utils";

import type { Institution } from "@lib/types/institution";
import type { State529Plan } from "@lib/types/state529";

type GlobalState = {
  institutions: Institution[];
  state_529_plans: State529Plan[];
}

type GlobalAction = {
  setInitialState: (initialState: GlobalState) => void;
  setInstitutions: (institutions: GlobalState["institutions"]) => void;
  setState529Plans: (state_529_plans: GlobalState["state_529_plans"]) => void;
}

export const useGlobalStore = create<GlobalState & GlobalAction>()((set) => ({
  institutions: [] as GlobalState["institutions"],
  state_529_plans: [] as GlobalState["state_529_plans"],

  setInitialState: (state) => set({ ...state }),

  setInstitutions: (institutions) => set((state) => ({
    ...state,
    institutions,
  })),

  setState529Plans: (state_529_plans) => set((state) => ({
    ...state,
    state_529_plans
  })),
}));