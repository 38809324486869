import DescriptionList, { type DescriptionListProps } from "@components/DescriptionList/DescriptionList";

import type { InstitutionPaymentTransaction } from "@lib/types/transaction";

import { formatDateString } from "@lib/utils/formatDates";
import { startCase } from "utils/formats";


type InstitutionPaymentDetailsProps = {
  transaction: InstitutionPaymentTransaction;
}

const InstitutionPaymentDetails = ({ transaction }: InstitutionPaymentDetailsProps) => {
  const detailsList = [
    { term: "Payment ID", details: transaction.id },
    { term: "Institution", details: transaction.institution.name },
    { term: "Type", details: startCase(transaction.type) },
  ] as DescriptionListProps["list"];

  const statusList = [
    { term: "Payment Status", details: startCase(transaction.status) },
    { term: "Last Status Update", details: formatDateString(transaction.updated_at) },
    { term: "Created On", details: formatDateString(transaction.created_at) },
  ] as DescriptionListProps["list"];

  return (
    <>
      <DescriptionList table title="Details" list={detailsList} />

      <DescriptionList table title="Status" list={statusList} />
    </>
  );
};

export default InstitutionPaymentDetails;