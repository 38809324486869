import instance from "config/fetch.config";
import { MutationError } from "@utils/errors";
import type { UUID } from "@lib/types/global";

type UpdateManual529LinkingPayload = {
  state_529_plan_id: string;
  beneficiary_id: string;
}

export const updateManual529Linking = async (payload: UpdateManual529LinkingPayload): Promise<{
  id: UUID;
  backpack_owner_id: UUID;
  state_529_plan: UUID;
}> => {
  try {
    const { data: { data } } = await instance.post("/state-529-connect/manual-link", payload);

    return data.beneficiary;
  } catch (error: any) {
    throw new MutationError(error.response.data);
  }
};