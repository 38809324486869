import React, { FC } from "react";

import {
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  formControlClasses,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const InputBlock = styled(FormControl)({
  [`&.${formControlClasses.root}`]: {
    marginTop: "0.3rem",
  },
});

const SpacedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`&.${toggleButtonGroupClasses.root}`]: {
    justifyContent: "space-between",
    gap: ".65rem",
    [`.${toggleButtonClasses.root}`]: {
      boxSizing: "border-box",
      width: "33%",
      justifyContent: "flex-start",
      border: `0.2px solid ${theme.palette.primary.dark}`,
      borderRadius: ".5rem",
      color: theme.palette.text.primary,
      fontWeight: "bold",
      padding: ".6875rem 1rem",
      "&:focus,:hover": {
        boxShadow: `inset 0 0 0 0.156rem ${theme.palette.primary.dark}`,
      },
      [`&.${toggleButtonClasses.selected}`]: {
        boxShadow: `inset 0 0 0 0.156rem ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
}));

type ButtonProps = {
  value: string;
  displayName: string;
};

interface ButtonGroupProps extends ToggleButtonGroupProps {
  value: string | null;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
  buttons: ButtonProps[];
  error: boolean;
}

const ButtonGroup: FC<ButtonGroupProps> = ({ value, onChange, buttons, error, ...props }) => {
  return (
    <InputBlock>
      <SpacedToggleButtonGroup value={value} exclusive onChange={onChange} {...props}>
        {buttons.map(({ value, displayName }) => (
          <ToggleButton key={value} value={value}>
            {displayName}
          </ToggleButton>
        ))}
      </SpacedToggleButtonGroup>
      {error && (
        <FormHelperText color="error" error>
          Error: Required Field
        </FormHelperText>
      )}
    </InputBlock>
  );
};

export default ButtonGroup;
