import React from "react";
import { When } from "react-if";
import { Button } from "@mui/material";

import Withdrawal from ".";
import Modal from "components/Modal";
import Dialog from "commons/Modal/Dialog";

import { WithdrawalStatus } from "@lib/enums/withdrawal";

import type { State529Plan } from "@lib/types/state529";
import type { Beneficiary } from "@lib/types/beneficiary";
import type { WithdrawalModalPayload } from "@lib/types/modal";

type WithdrawalModalProps = {
  amount?: number;
  state529Plan?: State529Plan;
  beneficiary: Beneficiary;
  open: boolean;
  handleClose: (payload?: WithdrawalModalPayload) => void;
};

const WithdrawalModal = ({
  amount,
  beneficiary,
  open,
  state529Plan,
  handleClose,
}: WithdrawalModalProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

  const attemptToClose = () => setConfirmationModalOpen(true);

  const handleModalClose = (payload?: WithdrawalModalPayload) => {
    if (!!payload &&
      (payload.status === WithdrawalStatus.FAILED_NEEDS_RELINK ||
      payload.status === WithdrawalStatus.SUBMITTED)
    ) {
      setConfirmationModalOpen(false);
      handleClose(payload);
      return;
    }

    attemptToClose();
  };

  const keepOpen = () => setConfirmationModalOpen(false);

  const closeConfirmation = () => {
    setConfirmationModalOpen(false);
    handleClose();
  };

  if (!state529Plan) return null;

  return (
    <>
      <Modal
        maxWidth="xs"
        open={open}
        handleClose={attemptToClose}
      >
        <Withdrawal
          beneficiary={beneficiary}
          state529Plan={state529Plan}
          amount={amount}
          handleClose={handleModalClose}
        />
      </Modal>

      <When condition={confirmationModalOpen}>
        <Dialog open={confirmationModalOpen} onClose={keepOpen}>
          <div className="flex flex-col items-center gap-6">
            <h2 className="text-lg">Exiting Withdrawal Request</h2>
            <div className="text-center">
              <p>Your withdrawal has not been made yet.</p>
              <p>Are you sure you want to leave?</p>
            </div>
            <Button variant="contained" onClick={keepOpen}>
              Continue Withdrawal
            </Button>
            <Button variant="text" onClick={closeConfirmation}>
              Exit Withdrawal
            </Button>
          </div>
        </Dialog>
      </When>
    </>
  );
};

export default WithdrawalModal;
