import { Box, Modal as MuiModal } from "@mui/material";
import React, { FC } from "react";
import { styled } from "@mui/material";

const CenteredCard = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  confirmation?: React.ReactNode;
  dataCy?: string;
}

const Modal: FC<ModalProps> = ({ open, handleClose, children, confirmation, dataCy = "modal" }) => {
  return (
    <>
      <MuiModal
        data-cy={dataCy}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <CenteredCard>{children}</CenteredCard>
      </MuiModal>
      {confirmation}
    </>
  );
};

export default Modal;
