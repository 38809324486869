import React from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "@components/Icons/Icon";

const LinkIcon = React.memo(function LinkIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={true} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
    </Icon>
  );
});

export default LinkIcon;