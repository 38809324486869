import { useCallback, useMemo } from "react";

import { useNotificationStore } from "@stores/notificationStore";
import type { Notification, NotificationOptions } from "@lib/types/notification";

type NotificationPayload = Notification & NotificationOptions;

const DEFAULT_TYPE = "success";
const DEFAULT_DELAY = 5000; // 5 seconds
const DEFAULT_POSITION = "right";

export const useNotification = () => {
  const addNotification = useNotificationStore((state) => state.addNotification);
  const clearNotification = useNotificationStore((state) => state.clearNotification);

  const showNotification = useCallback(({
    message,
    type = DEFAULT_TYPE,
    position = DEFAULT_POSITION,
    delay = DEFAULT_DELAY
  }: NotificationPayload) => {
    addNotification({ message, position, type });

    // [SM - 08/15/2024] - update this to include a clearTimeout when another notification is triggered
    setTimeout(() => clearNotification(), delay);
  }, [addNotification, clearNotification]);

  return useMemo(() => ({
    show: (message: string, {
      type = DEFAULT_TYPE,
      position = DEFAULT_POSITION,
      delay = DEFAULT_DELAY
    }: NotificationOptions) => showNotification({
      message,
      position,
      delay,
      type
    }),
    clear: () => clearNotification()
  }), [showNotification, clearNotification]);
};