import React from "react";
import { TextField, Button } from "@mui/material";

import { formatCurrency, sanitizeAmount } from "utils/formats";

type AmountProps = {
  defaultValue?: number;
  handleNext: (amount: number) => void;
};

const WithdrawalAmountScreen = ({ defaultValue = 0, handleNext }: AmountProps) => {
  const [amount, setAmount] = React.useState(formatCurrency(defaultValue) || "");

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numbers = e.target.value.replace(/[^0-9]/g, "");
    if (!numbers) return;
    const formattedValue = formatCurrency(parseInt(numbers, 10));
    setAmount(formattedValue);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleNext(sanitizeAmount(amount));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-80 px-5 flex flex-col justify-between gap-4 items-center grow"
    >
      <div>
        <p className="text-base font-bold my-8">
          Enter the amount you want to withdraw from your 529 Account
        </p>

        <TextField
          name="amount"
          label="Amount"
          variant="outlined"
          placeholder="$0.00"
          value={amount}
          onChange={handleAmountChange}
          inputProps={{ inputMode: "numeric" }}
          fullWidth
        />
      </div>

      <Button
        type="submit"
        variant="contained"
        className="rounded-full mt-2"
        disabled={!sanitizeAmount(amount)}
      >
        Continue
      </Button>
    </form>
  );
};

export default WithdrawalAmountScreen;
