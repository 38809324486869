import { InstitutionPaymentStatus } from "@lib/enums/institutionPayment";
import { TransactionStatus, TransactionType } from "@lib/enums/transaction";

export const TRANSACTION_TYPE = {
  INSTITUTION_PAYMENT: "INSTITUTION_PAYMENT",
  TRANSACTION: "TRANSACTION",
};

export enum TUITION_PAYMENT_BADGE_STATUS {
  PENDING = "Pending",
  INITIATED = "Initiated",
  SENT = "Sent",
  CANCELLED = "Cancelled",
}

export const TUITION_PAYMENT_BADGE_TYPE = {
  [TRANSACTION_TYPE.TRANSACTION]: {
    [TransactionStatus.PENDING]: TUITION_PAYMENT_BADGE_STATUS.INITIATED,
    [TransactionStatus.PROCESSING]: TUITION_PAYMENT_BADGE_STATUS.INITIATED,
    [TransactionStatus.SENT]: TUITION_PAYMENT_BADGE_STATUS.SENT,
    [TransactionStatus.CANCELLED]: TUITION_PAYMENT_BADGE_STATUS.CANCELLED,
  },
  [TRANSACTION_TYPE.INSTITUTION_PAYMENT]: {
    [InstitutionPaymentStatus.PENDING]: TUITION_PAYMENT_BADGE_STATUS.PENDING,
    [InstitutionPaymentStatus.SENT]: TUITION_PAYMENT_BADGE_STATUS.SENT,
    [InstitutionPaymentStatus.CANCELLED]: TUITION_PAYMENT_BADGE_STATUS.CANCELLED,
    [InstitutionPaymentStatus.EXPIRED]: TUITION_PAYMENT_BADGE_STATUS.CANCELLED,
  }
};

export const TUITION_PAYMENT_BADGE_COLOR = {
  [TUITION_PAYMENT_BADGE_STATUS.PENDING]: "yellow",
  [TUITION_PAYMENT_BADGE_STATUS.INITIATED]: "blue",
  [TUITION_PAYMENT_BADGE_STATUS.SENT]: "green",
  [TUITION_PAYMENT_BADGE_STATUS.CANCELLED]: "red",
};

export const TUITION_PAYMENT_BADGE_DESCRIPTION = {
  [TUITION_PAYMENT_BADGE_STATUS.PENDING]: "The tuition payment is pending.",
  [TUITION_PAYMENT_BADGE_STATUS.INITIATED]: "The tuition payment has been initiated.",
  [TUITION_PAYMENT_BADGE_STATUS.SENT]: "The tuition payment has been sent to the university.",
  [TUITION_PAYMENT_BADGE_STATUS.CANCELLED]: "The tuition payment has been cancelled.",
};

export const TRANSACTION_TYPE_FILTER_OPTIONS = [
  { label: "All Types", value: "ALL" },
  { label: "Deposit", value: TransactionType.DEPOSIT },
  { label: "Withdrawal", value: TransactionType.WITHDRAWAL },
  { label: "Pending", value: InstitutionPaymentStatus.PENDING },
];

export default {
  TRANSACTION_TYPE,
  TUITION_PAYMENT_BADGE_STATUS,
  TUITION_PAYMENT_BADGE_TYPE,
  TUITION_PAYMENT_BADGE_COLOR,
  TUITION_PAYMENT_BADGE_DESCRIPTION,
  TRANSACTION_TYPE_FILTER_OPTIONS
};