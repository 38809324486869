import React from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "@components/Icons/Icon";

/**
 * ArrowUpTrayIcon can be also be used as the logout icon when turned to the side
 */
const ArrowUpTrayIcon = React.memo(function ArrowUpTrayIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={true} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"/>
    </Icon>
  );
});

export default ArrowUpTrayIcon;
