import TransactionsTable from "@components/Transactions/TransactionsTable";
import TransactionsTableFilters from "@components/Transactions/TransactionsTableFilters";

const Transactions = () => {
  return (
    <div>
      <h2 className="text-lg text-primary mb-3.5">Transactions<span className="sr-only"> table</span></h2>

      <TransactionsTableFilters />

      <TransactionsTable data-testid="transactions-table-component" />
    </div>
  );
};

export default Transactions;
