import { FC } from "react";
import { Stack, Box } from "@mui/material";

import { Content, Title, Text, ConfirmButton } from "../commons";

import Success from "assets/images/processSuccess.png";

import { styled } from "@mui/material/styles";

const Block = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

interface PaymentCompleteProp {
  educationInstitutionName: string;
  goToDashboard: () => void;
}

const PaymentComplete: FC<PaymentCompleteProp> = ({ educationInstitutionName, goToDashboard }) => {
  return (
    <Content>
      <Title variant="h1">Payment Sent!</Title>

      <Block>
        <img height={86} src={Success} alt={"Payment Success"} data-cy="payment-sent-logo" />
      </Block>
      <Stack spacing={3}>
        <Stack>
          <Text>We have alerted</Text>
          <Text>
            <b>{educationInstitutionName}</b>
          </Text>
          <Text>to your incoming payment.</Text>
        </Stack>
        <Stack>
          <Text>Track your payment in the</Text>
          <Text>Backpack Dashboard.</Text>
        </Stack>
      </Stack>

      <Stack width={"100%"}>
        <ConfirmButton variant="contained" onClick={goToDashboard} data-cy="payment-sent-exit">
          Go to Dashboard
        </ConfirmButton>
      </Stack>
    </Content>
  );
};

export default PaymentComplete;
