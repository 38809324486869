import React, { FC } from "react";
import { Checkbox, Stack, Typography, Button, CircularProgress } from "@mui/material";

import { styled } from "@mui/material/styles";
import { Else, If, Then } from "react-if";

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

const Text = styled(Typography)({
  textAlign: "justify",
  lineHeight: "1rem",
  fontSize: ".875rem",
});

const Row = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
});

const CheckboxIcon = styled(Checkbox)(({ theme }) => ({
  paddingLeft: 0,
  paddingTop: "8px",
  color: theme.palette.grey[500],
}));

const TermOfServiceMessage = styled(Typography)({
  textAlign: "justify",
  cursor: "pointer",
  fontSize: ".625rem",
});

interface ReadyToAddAccountProps {
  handleAddWithdrawalAccount: () => void;
  displayInfo: {
    accountName: string;
    accountNumber: string;
    routingNumber: string;
  };
  reAddAccount: boolean;
}

const ReadyToAddAccount: FC<ReadyToAddAccountProps> = ({
  handleAddWithdrawalAccount,
  displayInfo,
  reAddAccount,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);
    await handleAddWithdrawalAccount();
    setLoading(false);
  };

  return (
    <Content>
      <Stack marginY="2rem" spacing={6}>
        <Stack spacing={3}>
          <If condition={reAddAccount}>
            <Then>
              <Text>
                Below are your Backpack account details that will be added to your 529 Account as a
                withdrawal method:
              </Text>
            </Then>
            <Else>
              <Text>
                <b data-cy="link-connection-success">
                  We have established a connection with your 529 Account.{" "}
                </b>
              </Text>
              <Text>
                The next step is to add your Backpack account to your 529 Account as a withdrawal
                method. This will allow us to withdraw funds into your Backpack account.
              </Text>
              <Text>Click “Add Account” below to add your account in less than 15 seconds.</Text>
            </Else>
          </If>
        </Stack>
        <Stack spacing={3}>
          <Row>
            <Text>Account Name</Text>
            <Text>
              <b data-cy="link-account-name">{displayInfo.accountName}</b>
            </Text>
          </Row>
          <Row>
            <Text>Account Number</Text>
            <Text>
              <b data-cy="link-account-number">{displayInfo.accountNumber}</b>
            </Text>
          </Row>
          <Row>
            <Text>Routing Number</Text>
            <Text>
              <b data-cy="link-routing-number">{displayInfo.routingNumber}</b>
            </Text>
          </Row>
        </Stack>
      </Stack>

      <Stack alignItems="center" spacing={3}>
        <Stack spacing={1} flexDirection="row" alignItems="flex-start">
          <CheckboxIcon
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            aria-label="Terms and Policies"
            data-cy="terms-and-policies-checkbox"
            disableRipple
          />
          <TermOfServiceMessage
            // @ts-ignore
            onClick={(e) => setChecked((check) => !check)}
            data-cy="terms-of-service-message"
            variant="legal"
          >
            By checking this checkbox, I authorize Backpack to add my Backpack account to my 529
            Account as a withdrawal method.
          </TermOfServiceMessage>
        </Stack>
        <Button
          disabled={!checked}
          variant="contained"
          onClick={handleClick}
          data-cy="link-add-account-button"
        >
          {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : " Add Account"}
        </Button>
      </Stack>
    </Content>
  );
};

export default ReadyToAddAccount;
