const DEFAULT_DATE_FORMAT_OPTIONS = {
  month: "short",
  day: "numeric",
  year: "numeric"
} as Intl.DateTimeFormatOptions;

export function formatDateString(
  date: string | undefined,
  options: Intl.DateTimeFormatOptions | undefined = DEFAULT_DATE_FORMAT_OPTIONS
): string {
  if (!date) return "";

  const newDate = new Date(date);

  return newDate.toLocaleDateString("en-US", options);
}

export default { formatDateString };