import instance from "config/fetch.config";
import type { UUID } from "@lib/types/global";

type CreateInquiryReponse = {
  id: UUID;
  inquiry_id: string;
  status: string;
  session_token?: string;
}

export class PersonaService {
  public static async createInquiry(
    applicationId: string,
  ): Promise<CreateInquiryReponse> {
    const {
      data: { data },
    } = await instance.post(`/applications/${applicationId}/inquiries`);
    return data.inquiry;
  }

  public static async getInquiries(applicationId: string) {
    const {
      data: { data },
    } = await instance.get(`/applications/${applicationId}/inquiries`);
    return data.inquiries;
  }

  public static async resumeInquiry(applicationId: string, inquiryId: string): Promise<string> {
    const {
      data: { data },
    } = await instance.post(`/applications/${applicationId}/inquires/${inquiryId}/resume`);
    return data.session_token;
  }
}
