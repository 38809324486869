import { useMemo } from "react";

import { COLOR, SIZE, VARIANT } from "@lib/constants/theme";
import { classnames } from "@utils/classnames";

import type { Treatment, Variant } from "@lib/types/theme";

/**
 * @typedef {Object} ButtonProps
 * @extends {Treatment} Default is square
 * @extends {Pick<Variant, "primary" | "secondary">} Default is primary
 * @extends {React.ButtonHTMLAttributes<HTMLButtonElement>}
 */
type ButtonProps = {
  /**
   * The content to display inside the button
   */
  children: React.ReactNode;
  /**
   * The color variant. Default is blue.
   */
  color?: "blue"
  /**
   * If true, the button will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * The size of the button. Default is md.
   */
  size?: "sm" | "md" | "lg";
  /**
   * The callback function triggered when the button is clicked.
   */
  onClick: (event: React.MouseEvent) => void;
} & Treatment
  & Pick<Variant, "primary" | "secondary" | "text">
  & React.ButtonHTMLAttributes<HTMLButtonElement>;

// [SM - 09/25/2024]: these styles may need to be moved to index.css in order for us to better build out dark mode styles
const BUTTON_BASE = "inline-flex items-center justify-center gap-x-1.5 font-bold shadow-sm border-none hover:border-none focus-visible:outline focus-visible:outline-2 disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-200 disabled:ring-gray-200";

const BUTTON_VARIANT = {
  [VARIANT.PRIMARY]: "",
  [VARIANT.SECONDARY]: "ring-1 ring-inset hover:ring-2 active:ring-2",
  [VARIANT.TEXT]: "shadow-none"
};

const BUTTON_COLORS = {
  [COLOR.BLUE]: {
    [VARIANT.PRIMARY]: "bg-blue text-white hover:bg-blue-800 active:bg-blue-900 focus-visible:outline-blue-400",
    [VARIANT.SECONDARY]: "text-blue bg-white ring-blue hover:bg-white hover:ring-blue active:bg-white active:ring-blue focus-visible:outline-blue-400",
    [VARIANT.TEXT]: "bg-white text-blue hover:bg-sky-50 hover:ring-blue-300 active:bg-white active:ring-blue-300 focus-visible:outline-blue-400"
  }
};

const BUTTON_SIZE = {
  [SIZE.SM]: "px-3 py-2 text-sm",
  [SIZE.MD]: "px-5 py-2.5 text-base",
  [SIZE.LG]: "px-5 py-3 text-lg"
};

/**
 * A reusable Button component that supports various sizes, colors, and shapes.
 *
 * @param {ButtonProps} props - The props for the Button component.
 * @returns {JSX.Element} The rendered Button component.
 *
 * @example
 * <Button color="blue" size="md" primary onClick={handleClick}>
 *   Click Me
 * </Button>
 */
const Button = ({
  children,
  className,
  color = "blue",
  fullWidth = false,
  square = true,
  rounded = false,
  pill = false,
  size = "md",
  primary = true,
  secondary = false,
  text = false,
  type = "button",
  ...buttonAttrs
}: ButtonProps) => {
  const buttonClassName = useMemo(() => {
    return classnames(
      BUTTON_BASE,
      BUTTON_SIZE[size],
      {
        [BUTTON_VARIANT[VARIANT.PRIMARY]]: primary,
        [BUTTON_COLORS[color][VARIANT.PRIMARY]]: primary,
        [BUTTON_VARIANT[VARIANT.SECONDARY]]: secondary,
        [BUTTON_COLORS[color][VARIANT.SECONDARY]]: secondary,
        [BUTTON_VARIANT[VARIANT.TEXT]]: text,
        [BUTTON_COLORS[color][VARIANT.TEXT]]: text,
        "rounded-sm": square,
        "rounded-md": rounded,
        "rounded-full": pill,
        "w-full": fullWidth
      },
      className
    );
  }, [
    color,
    square,
    rounded,
    pill,
    size,
    primary,
    secondary,
    text,
    fullWidth,
    className
  ]);

  return (
    <button
      type={type}
      className={buttonClassName}
      data-testid="button-component"
      {...buttonAttrs}
    >
      {children}
    </button>
  );
};

export default Button;