import { Case, Switch } from "react-if";

import { classnames } from "@utils/classnames";
import type { ComponentType } from "react";
import type { OutlineIconProps } from "@components/Icons/Icon";

export type ProgressStep = {
  step: number;
  title: string;
  description: string;
  icons: {
    active: ComponentType<OutlineIconProps>;
    inactive: ComponentType<OutlineIconProps>;
    completed: ComponentType<OutlineIconProps>;
  }
}

type ProgressStepperType = {
  active: number;
  steps: ProgressStep[];
  className?: string;
}

const ProgressStepper = ({
  className,
  steps,
  active = 1
}: ProgressStepperType) => {
  return (
    <div className={className}>
      <ol role="list" className="sm:-mb-8">
        {steps.map(({ step, title, description, icons }: ProgressStep, index) => (
          <li key={`progress-step-${step}`} className={classnames({ "max-sm:hidden": active !== step })}>
            <div className="relative sm:pb-5">
              {index !== steps.length - 1 ? (
                <span aria-hidden="true" className="max-sm:hidden absolute left-6 top-4 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}

              <div className="relative flex space-x-4">
                <div>
                  <span
                    className="flex size-12 items-center justify-center rounded-xl ring-1 ring-gray-300 bg-white">
                    <Switch>
                      <Case condition={active !== step && active > step}>
                        <icons.completed
                          data-testid="progress-stepper-icon-completed"
                          aria-hidden="true"
                          className="size-6 text-success"
                        />
                      </Case>

                      <Case condition={active === step}>
                        <icons.active
                          data-testid="progress-stepper-icon-active"
                          aria-hidden="true"
                          outline
                          strokeWidth={2}
                          className="size-6 text-blue"
                        />
                      </Case>

                      <Case condition={active !== step && active < step}>
                        <icons.inactive
                          data-testid="progress-stepper-icon-inactive"
                          aria-hidden="true"
                          outline
                          strokeWidth={2}
                          className="size-6 text-gray-500"
                        />
                      </Case>
                    </Switch>
                    <span className="sr-only">`Step ${step}`</span>
                  </span>
                </div>

                <div className={classnames("flex flex-col justify-between", active === step ? "text-gray-900" : "text-gray-500")}>
                  <p className="text-base font-medium">
                    {title}
                  </p>

                  <p className="text-sm">
                    {description}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ProgressStepper;