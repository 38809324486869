import { Button } from "@mui/material";

const PurchaseLaptop = () => {
  return (
    <div className="flex flex-col gap-4 px-6 sm:px-0 text-sm md:text-base text-left">
      <h2 className="text-xl md:text-3xl font-semibold text-left">Using Backpack to Purchase a Laptop</h2>

      <p>Using your Backpack account to pay for 529 eligible expenses maximizes your savings, ensures tax-free purchases, and simplifies tracking your expenses for when Tax time comes around.</p>

      <p>You can shop the following Merchants using your Backpack account.</p>

      <ul className="max-w-md space-y-1 list-none list-inside">
        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://amzn.to/4azA9WM"
          >
            Amazon
          </a>
        </li>

        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://www.bestbuy.com/site/promo/laptop-and-computer-deals"
          >
            Apple
          </a>
        </li>

        <li>
          <a
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
            href="https://www.bestbuy.com/site/promo/laptop-and-computer-deals"
          >
            Best Buy
          </a>
        </li>
      </ul>

      <p>For more information on how to pay on Amazon or through PayPal with your Backpack account click learn more below.</p>

      <Button
        className="block w-max mt-2 text-sm px-3 py-2"
        variant="tertiary"
        target="_blank"
        rel="noreferrer"
        href="https://intercom.help/backpack-529/en/articles/9390182-how-to-shop-online-with-your-backpack-account"
      >
        Learn More
      </Button>
    </div>
  );
};

export default PurchaseLaptop;