const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export async function backoff(
  cb: () => any,
  {
    maxRetries = 3,
    conditionOfSuccess,
  }: {
    maxRetries?: number;
    conditionOfSuccess?: (result: any) => { conditionMet: boolean; errorMessage: string };
  },
) {
  let retryCount = 0;
  let delayInMilliseconds = 500;
  let error;

  while (retryCount < maxRetries) {
    try {
      const result = await cb();

      if (conditionOfSuccess) {
        const { conditionMet, errorMessage } = conditionOfSuccess(result);
        if (conditionMet) {
          return result;
        } else {
          throw new Error(errorMessage);
        }
      } else return result;
    } catch (e) {
      // Capture error that can be re-thrown
      error = e;
      console.log(
        `Attempt ${retryCount + 1} failed. Retrying in ${delayInMilliseconds / 1000} seconds...`,
      );

      // Backoff
      await wait(delayInMilliseconds);

      // Increment delay and retry count
      delayInMilliseconds *= 2;
      retryCount += 1;
    }
  }

  // Maximum number of retries reached so throw error
  throw error;
}
