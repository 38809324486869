import { Button } from "@mui/material";

import type { State529Plan } from "@lib/types/state529";
import { formatCurrency, formatDate } from "utils/formats";

import SuccessImage from "assets/images/successStudent.svg";

type SuccessProps = {
  confirmationDetails: {
    confirmationId: string | null;
    amount: number;
    state529Plan: State529Plan;
  }
  handleNext: () => void;
};

const WithdrawalSuccess = ({
  confirmationDetails,
  handleNext,
}: SuccessProps) => {
  if (!confirmationDetails.confirmationId) return null;

  const { confirmationId, amount, state529Plan } = confirmationDetails;

  return (
    <div className="w-80 flex flex-col justify-between gap-4 items-center grow">
      <div className="flex flex-col items-center">
        <h3 className="text-blue text-lg mb-4">Success!</h3>

        <img src={SuccessImage} alt="success" className="size-56" />

        <h4 className="w-56 text-xl font-bold my-8 text-center">
          Your withdrawal has been initiated
        </h4>

        <dl className="w-72 my-4 text-sm">
          <div className="flex justify-between gap-1 mb-4">
            <dt className="w-full">Withdrawal Id</dt>
            <dd className="w-full font-bold text-right">{confirmationId}</dd>
          </div>

          <div className="flex justify-between gap-1 mb-4">
            <dt className="w-full">529 Plan Provider</dt>
            <dd className="w-full font-bold text-right">{state529Plan.name}</dd>
          </div>

          <div className="flex justify-between gap-1 mb-4">
            <dt className="w-full">Amount</dt>
            <dd className="w-full font-bold text-right">{formatCurrency(amount)}</dd>
          </div>

          <div className="flex justify-between gap-1 mb-4">
            <dt className="w-full">Date</dt>
            <dd className="w-full font-bold text-right">{formatDate(new Date())}</dd>
          </div>
        </dl>
      </div>

      <Button
        variant="contained"
        className="rounded-full mt-2"
        onClick={handleNext}
      >
        Finish
      </Button>
    </div>
  );
};

export default WithdrawalSuccess;
