/* eslint-disable react/prop-types */
import React from "react";

import { classnames } from "@utils/classnames";
import type { CustomSVGProps } from "@lib/types/ui";

export type SolidIconProps = {
  className?: string;
} & CustomSVGProps;

export type OutlineIconProps = {
  outline?: boolean;
} & SolidIconProps;

type IconProps = OutlineIconProps & {
  children: React.ReactNode;
}

const ICON_SVG_TYPE = {
  SOLID: "solid",
  OUTLINE: "outline"
};

const ICON_SVG_ATTRIBUTES = {
  [ICON_SVG_TYPE.SOLID]: {
    fill: "currentColor"
  },
  [ICON_SVG_TYPE.OUTLINE]: {
    fill: "none",
    strokeWidth: 1.5,
    stroke: "currentColor"
  },
};

const Icon = React.memo(function Icon({
  children,
  className,
  outline,
  strokeWidth = 1.5,
  "aria-hidden": ariaHidden = true,
  ...svgProps
}: IconProps) {
  const svgAttrs = React.useMemo(() => {
    const baseAttrs = outline
      ? { ...ICON_SVG_ATTRIBUTES[ICON_SVG_TYPE.OUTLINE], strokeWidth }
      : ICON_SVG_ATTRIBUTES[ICON_SVG_TYPE.SOLID];

    return { ...svgProps, ...baseAttrs };
  }, [
    outline,
    strokeWidth,
    svgProps
  ]);

  const memoizedClassName = React.useMemo(() => {
    return classnames("text-primary size-6", className);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden={ariaHidden}
      className={memoizedClassName}
      {...svgAttrs}
    >
      {children}
    </svg>
  );
});

export default Icon;