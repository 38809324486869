import { memo, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from "@headlessui/react";

import { useAuth } from "@hooks/useAuth";

import XMarkIcon from "@components/Icons/XMarkIcon";
import Bars3Icon from "@components/Icons/Bars3Icon";
import HelpIcon from "@components/Icons/HelpIcon";
import ArrowUpTrayIcon from "@components/Icons/ArrowUpTrayIcon";
import InformationCircleIcon from "@components/Icons/InformationCircleIcon";

import BackpackLogo from "assets/images/BackpackLogoHorizontal.svg";

import { classnames } from "@lib/utils/classnames";

const HEADER_LINKS = [
  {
    name: "Help Center",
    href: "https://intercom.help/backpack-529/en/",
    target: "_blank",
    rel: "noreferrer",
    icon: InformationCircleIcon,
  },
  {
    name: "Support",
    href: "https://www.backpack529.com/contact",
    target: "_blank",
    rel: "noreferrer",
    icon: HelpIcon
  },
  {
    name: "Logout",
    href: "#",
    icon: ArrowUpTrayIcon,
  },
];

const MainHeader = memo(function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { logout } = useAuth();

  const handleOnClick = (
    event: React.MouseEvent,
    linkName: string
  ) => {
    if (linkName === "Logout") {
      event.preventDefault();

      logout(false);
    }
  };

  return (
    <>
      <header className="bg-white z-50 sticky top-0 px-4 md:px-5 border-bottom border-gray-200" data-testid="main-header">
        <div className="flex h-16 items-center justify-between">
          <img
            alt="Backpack"
            src={BackpackLogo}
            className="h-6 w-auto shrink-0"
          />

          <div className="hidden md:ml-6 md:block">
            <div className="flex space-x-4 items-center">
              {HEADER_LINKS.map((item) => (
                <a
                  key={item.name}
                  data-testid="main-header-link"
                  className="text-primary hover:bg-gray-100 hover:text-primary focus:bg-gray-100 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold"
                  href={item.href}
                  target={item.target && item.target}
                  rel={item.rel && item.rel}
                  onClick={(event) => handleOnClick(event, item.name)}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>

          <button
            type="button"
            className="p-2 inline-flex items-center justify-center icon-button focus:ring-inset md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <Bars3Icon aria-hidden="true" className="size-6" />
          </button>
        </div>
      </header>

      {/* mobile menu */}
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop transition className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0" />

        <div className="fixed inset-y-0 right-0 w-full flex justify-end">
          <DialogPanel transition className="relative ml-16 flex w-full max-w-xs flex-1 transition duration-300 ease-in-out data-[closed]:translate-x-full">
            <TransitionChild>
              <div className="absolute right-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6 text-white" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white p-4">
              <ul role="list" className="space-y-1">
                {HEADER_LINKS.map((item) => (
                  <li key={`mobile-${item.name}`}>
                    <a
                      data-testid="main-header-mobile-link"
                      className="group flex items-center gap-x-2 rounded-md p-2 text-base font-medium leading-6 text-primary hover:bg-gray-100 hover:text-primary focus:bg-gray-100 focus:text-primary"
                      href={item.href}
                      target={item.target && item.target}
                      rel={item.rel && item.rel}
                      onClick={(event) => handleOnClick(event, item.name)}
                    >
                      <item.icon
                        aria-hidden="true"
                        outline={item.name !== "Logout"}
                        className={classnames(
                          "size-6 shrink-0 text-gray-500 group-hover:text-primary group-focus:text-primary",
                          item.name === "Logout" && "rotate-90"
                        )}
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
      {/* end of mobile menu */}
    </>
  );
});

export default MainHeader;