import { Button } from "@mui/material";
import StEdwardsBanner from "assets/images/insights/modals/StEdwardsAustinBanner.jpg";

const UniversityExclusive = () => {
  return (
    <div className="flex flex-col px-6 sm:px-0">
      <img
        height="auto"
        width="100%"
        alt="St. Edward's Austin Banner"
        src={StEdwardsBanner}
      />

      <div className="flex flex-col gap-4 pt-4 text-sm md:text-base text-left">
        <h2 className="text-xl md:text-3xl font-semibold text-left">Exclusive to St Edward’s Families</h2>

        <p>Get 5% cashback on non-tuition expenses such as laptops, textbooks, computer accessories, stationery and more when you use your Backpack account to pay.</p>

        <p>Click below to learn how to use your Backpack funds on Amazon or any retailer with PayPal.</p>

        <Button
          className="block w-max mt-2 text-sm px-3 py-2"
          variant="tertiary"
          target="_blank"
          rel="noreferrer"
          href="https://intercom.help/backpack-529/en/articles/9390182-how-to-shop-online-with-your-backpack-account"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default UniversityExclusive;