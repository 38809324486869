import { useState, FC } from "react";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { FormControl } from "@mui/base/FormControl";
import { FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

import { InputValidator } from "utils/validations";
import ApplicationServices from "services/application.services";
import type { UUID } from "@lib/types/global";
import MFAVerificationModal from "commons/Modal/MFAVerificationModal";

interface EmailVerificationPropTypes {
  applicationId: UUID;
  handleNext: any;
}

const EmailVerificationContent = styled(Stack)({
  margin: "2rem 1.75rem 0 1.75rem",
});

const EmailVerificationButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
});

const EmailVerification: FC<EmailVerificationPropTypes> = ({ handleNext, applicationId }) => {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onBlur = () => {
    setValidationError(!isValidEmail());
  };

  const onFocus = () => {
    setValidationError(false);
  };

  const isValidEmail = () => {
    return InputValidator.isValidEmail(email);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = async () => {
    try {
      if (!isValidEmail() || loading) return;
      setError("");
      setIsLoading(true);
      await ApplicationServices.saveEmail(applicationId, email);
      setIsLoading(false);
      setOpen(true);
    } catch (err: any) {
      setIsLoading(false);
      setError(err);
    }
  };

  const handleVerification = async (verificationCode: string) => {
    await ApplicationServices.verifyEmail(applicationId, verificationCode);
    handleNext();
  };

  return (
    <>
      <EmailVerificationContent>
        <FormControl>
          <FormGroup>
            <TextField
              data-cy="emailVerification-input"
              value={email}
              placeholder="Enter email address"
              label="Email address"
              helperText={validationError ? "Error: Invalid Email" : ""}
              error={validationError}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <Typography mt={1} variant="legal">
            We’ll email you a verification code
          </Typography>

          <EmailVerificationButton
            variant="contained"
            onClick={handleOpenModal}
            disabled={loading || !email || !isValidEmail()}
            data-cy="emailVerification-submit-button"
          >
            {loading ? <CircularProgress size="1.7rem" sx={{ color: "white" }} /> : "Next"}
          </EmailVerificationButton>
        </FormControl>

        {!!error && (
          <Typography mb="1rem" color="error">
            {error}
          </Typography>
        )}

        <Stack spacing={1} sx={{ textAlign: "justify" }}>
          <Typography variant="legal">
            IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government
            fight the funding of terrorism and money laundering activities, Federal law requires all
            financial institutions to obtain, verify and record information that identifies each
            person who opens an account.
          </Typography>
          <Typography variant="legal">
            When you open an account, we will ask for your name, address, date of birth and other
            information that will allow us to identify you. We may also ask to see your driver’s
            license or other identifying documents.
          </Typography>
        </Stack>
      </EmailVerificationContent>

      <MFAVerificationModal
        title={"We just emailed you."}
        description={`Please enter the code we just sent to your email starting with ${email.slice(0, 3)}****`}
        open={open}
        handleCloseModal={handleCloseModal}
        handleNext={handleVerification}
        retry={handleOpenModal}
      />
    </>
  );
};

export default EmailVerification;
