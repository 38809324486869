const BackpackIcon = () => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" height="20"
      width="20"
      aria-hidden="true"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99997 20C15.5229 20 20 15.5228 20 9.99997C20 4.47715 15.5229 0 9.99997 0C4.47715 0 0 4.47715 0 9.99997C0 15.5228 4.47715 20 9.99997 20ZM12.2143 5.59823C13.4344 6.3105 14.2209 7.5849 14.2209 9.4196V15.5017C14.2209 15.9978 13.8188 16.3999 13.3227 16.3999H13.0104V14.0342C13.0104 12.2979 11.6605 10.942 9.92416 10.942C8.18786 10.942 6.72276 12.2979 6.72276 14.0342V16.3999H6.49826C6.00215 16.3999 5.6 15.9985 5.6 15.5024V9.4196C5.6 7.63266 6.46241 6.32265 7.63454 5.59762C7.75794 4.47409 8.39734 3.30805 9.92416 3.30805C11.4509 3.30805 12.0656 4.50225 12.2143 5.59823ZM11.6105 14.0342V16.3999H8.12273V14.0342C8.12273 13.0711 8.99267 12.4382 9.92416 12.4688C10.8222 12.4982 11.6105 13.0711 11.6105 14.0342Z" fill="#033AFC" />
    </svg>
  );
};

export default BackpackIcon;
