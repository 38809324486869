import React, { useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import { IconButton } from "@mui/material";

import EyeIcon from "@components/Icons/EyeIcon";
import CopyToClipboard from "@components/CopyToClipboard/CopyToClipboard";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { classnames } from "@utils/classnames";

type AccountDetailsNumbersProps = {
  list?: boolean;
  containerClassName?: string;
  className?: string;
  showAccountType?: boolean;
}

const AccountDetailsNumbers = React.memo(function AccountDetailsNumbers({
  containerClassName,
  className,
  list = true,
  showAccountType = false
}: AccountDetailsNumbersProps) {
  const Component = list ? "ul" : "div";
  const NestedComponent = list ? "li" : "div";

  const routing_number = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account.routing_number));

  const account_number = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account.account_number));

  const account_type = useCurrentUserStore(useShallow((state) => state.currentUser.bank_account.account_type));

  const [show, setShow] = useState(false);

  const hiddenAccountNumber = useMemo(() => {
    return account_number.slice(0, 4) + "*".repeat(account_number.length - 5) + account_number.slice(-1);
  }, [account_number]);

  const componentClassName = useMemo(() => {
    return classnames("flex flex-col gap-2.5", {
      "justify-end": !list
    }, containerClassName);
  }, [list, containerClassName]);

  const nestedComponentClassName = useMemo(() => {
    return classnames("flex items-center justify-between", {
      "text-base font-bold gap-8": !list,
      "text-sm gap-2 md:gap-4": list
    }, className);
  }, [list, className]);

  const itemClassName = useMemo(() => {
    return classnames("flex items-center justify-end gap-1.5 w-40", {
      "pr-8": !list,
      "pr-0 md:pr-5 font-semibold": list
    });
  }, [list]);

  return (
    <Component className={componentClassName}>
      <NestedComponent className={nestedComponentClassName}>
        <span className="font-normal shrink-0">Routing Number</span>

        <div className={itemClassName} data-testid="routing-number">
          <CopyToClipboard
            value={routing_number}
            tooltip={{
              text: "Click to copy",
              placement: "top"
            }}
            showIcon={false}
            notificationMessage="Routing Number copied"
          >
            {routing_number}
          </CopyToClipboard>
        </div>
      </NestedComponent>

      <NestedComponent className={nestedComponentClassName}>
        <span className="font-normal shrink-0">Account Number</span>

        <div className={classnames(itemClassName, "!pr-0")} data-testid="account-number">
          <CopyToClipboard
            value={account_number}
            tooltip={{
              text: "Click to copy",
              placement: "top"
            }}
            showIcon={false}
            notificationMessage="Account Number copied"
          >
            {show ? account_number : hiddenAccountNumber}
          </CopyToClipboard>

          <IconButton
            type="button"
            className="p-0 hover:bg-transparent text-primary hover:text-blue"
            disableRipple
            onClick={() => setShow(!show)}
          >
            <span className="sr-only">Show Account Number</span>

            <EyeIcon
              className={list ? "size-4" : "size-5"}
              aria-hidden="true"
              slash={!show}
              strokeWidth={2}
            />
          </IconButton>
        </div>
      </NestedComponent>

      <When condition={showAccountType}>
        <NestedComponent className={nestedComponentClassName}>
          <span className="font-normal shrink-0">Account Type</span>

          <div className={itemClassName}>
            {account_type || "Checking"}
          </div>
        </NestedComponent>
      </When>
    </Component>
  );
});

export default AccountDetailsNumbers;