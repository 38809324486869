import { create } from "@stores/utils";
import { useCurrentUserStore } from "@stores/currentUserStore";

import { formatCurrency, startCase } from "utils/formats";

import { sortTransactions } from "@helpers/sortTransactions";
import { filterTransactions } from "@helpers/filterTransactions";

import type { Transaction, TransactionResponse } from "@lib/types/transaction";
import type { InstitutionPayment } from "@lib/types/institutionPayment";
import type { FilterByType } from "@components/Transactions/TransactionsTableFilters";
import type { DateRange } from "@lib/types/filter";
import { SORT_TYPE } from "@lib/enums/global";

export type TransactionFilters = {
  type: FilterByType;
  date: DateRange | null;
  year: string;
};

type TransactionFilterTypes = "type" | "date";

type TransactionDetailModalPayload = {
  transaction: Transaction;
  badge?: React.ReactNode;
}

type TransactionsState = {
  initialTransactions: Transaction[];
  transactions: Transaction[];
  sort: SORT_TYPE.ASCENDING | SORT_TYPE.DESCENDING;
  filters: TransactionFilters;
  appliedFiltersAmt: number;
  modal: TransactionDetailModalPayload | null;
};

type TransactionsAction = {
  setInitialTransactions: (allTransactions: (TransactionResponse | InstitutionPayment)[]) => void;
  filterByType: (type: FilterByType) => void;
  filterByDate: (dateRanges: DateRange) => void;
  filterByYear: (year: string) => void;
  removeFilter: (filter?: TransactionFilterTypes) => void;
  setModal: (payload?: TransactionsState["modal"]) => void;
};

const DEFAULT_FILTERS = {
  type: "ALL" as FilterByType,
  date: null,
  beneficiary: "ALL",
  year: new Date().getFullYear().toString()
};

export const useTransactionsStore = create<TransactionsState & TransactionsAction>()((set, get) => ({
  initialTransactions: [] as Transaction[],
  transactions: [] as Transaction[],
  sort: SORT_TYPE.DESCENDING,
  filters: {
    ...DEFAULT_FILTERS
  },
  appliedFiltersAmt: 0,
  modal: null,

  setInitialTransactions: (allTransactions) => {
    const currentUser = useCurrentUserStore.getState().currentUser;

    const formattedTransactions = allTransactions.map((transaction) => {

      if ("institution" in transaction) {
        const beneficiary_fullname = `${transaction.beneficiary.first_name} ${transaction.beneficiary.last_name}`;

        return {
          ...transaction,
          transaction_name: transaction.institution.name,
          amount: `-${transaction.amount}`,
          beneficiary_fullname,
        };
      }

      // since we only have 1 beneficiary, we're grabbing their details from the currentUser store
      // when we allow users to choose their beneficiary/multi-beneficiary,
      // we'll need to update this since it could be returning from the transaction
      const beneficiary_fullname = "metadata.institution_payment" in transaction
        ? `${transaction.metadata?.institution_payment.beneficiary.first_name} ${transaction.metadata?.institution_payment.beneficiary.last_name}`
        : `${currentUser.beneficiaries[0].first_name} ${currentUser.beneficiaries[0].last_name}`;



      const formattedAmount = formatCurrency(transaction.amount_in_cents);
      let amount = formattedAmount;

      // not 100% sure if we need this since the mocks are returning negative amounts
      if ((Math.sign(transaction.amount_in_cents) === 1) && transaction.type === "WITHDRAWAL") {
        amount = `-${formattedAmount}`;
      }

      // if there is no company name or enriched description, show the description
      const transaction_name = transaction.company_name && startCase(transaction.company_name)
        || transaction.enriched_description
        || transaction.description;

      return {
        ...transaction,
        transaction_name,
        amount,
        beneficiary_fullname
      };
    });

    // return formattedTransactions as Transaction[];
    const sortedTransactions = sortTransactions({
      transactions: formattedTransactions,
      sort: SORT_TYPE.DESCENDING
    });

    set({
      transactions: sortedTransactions,
      initialTransactions: sortedTransactions,
      sort: SORT_TYPE.DESCENDING
    });
  },

  filterByType: (type) => {
    const currentFilters = get().filters;

    const currentTypeFilter = get().filters.type;
    const appliedFilterCount = get().appliedFiltersAmt;

    const initialTransactions = get().initialTransactions;
    let newAppliedFilterCount = appliedFilterCount;

    const filters = {
      ...currentFilters,
      type: type
    };

    const filteredTransactions = filterTransactions({
      filters: filters,
      transactions: [...initialTransactions]
    });

    if (type === "ALL") {
      newAppliedFilterCount = Math.max(appliedFilterCount - 1, 0);
    }

    if (currentTypeFilter === "ALL" || !appliedFilterCount) {
      newAppliedFilterCount = appliedFilterCount + 1;
    }

    set({
      transactions: [...filteredTransactions],
      filters: filters,
      appliedFiltersAmt: newAppliedFilterCount
    });
  },

  filterByDate: ({ startDate, endDate }) => {
    const currentFilters = get().filters;

    const currentDateFilter = get().filters.date;
    const dateFilter = !startDate && !endDate ? null : { startDate, endDate };

    const appliedFilterCount = get().appliedFiltersAmt;
    let newAppliedFilterCount = appliedFilterCount;

    if (!currentDateFilter) {
      newAppliedFilterCount = appliedFilterCount + 1;
    }

    const initialTransactions = get().initialTransactions;

    const filters = {
      ...currentFilters,
      date: dateFilter
    } as TransactionFilters;

    const filteredTransactions = filterTransactions({
      filters: filters,
      transactions: [...initialTransactions]
    });

    set({
      transactions: [...filteredTransactions],
      filters: filters,
      appliedFiltersAmt: newAppliedFilterCount
    });
  },

  filterByYear: (year) => {
    const currentFilters = get().filters;

    const appliedFilterCount = get().appliedFiltersAmt;
    const newAppliedFilterCount = year === DEFAULT_FILTERS.year
      ? appliedFilterCount
      : appliedFilterCount + 1;

    const initialTransactions = get().initialTransactions;

    const filters = {
      ...currentFilters,
      year
    } as TransactionFilters;

    const filteredTransactions = filterTransactions({
      filters: filters,
      transactions: [...initialTransactions]
    });

    set({
      transactions: [...filteredTransactions],
      filters: filters,
      appliedFiltersAmt: newAppliedFilterCount
    });
  },

  removeFilter: (filter) => {
    const initialTransactions = get().initialTransactions;
    const currentFilters = get().filters;
    const appliedFilterCount = get().appliedFiltersAmt;

    // if no filter name is provided
    // reset all the filters
    if (!filter) {
      set({
        transactions: [...initialTransactions],
        filters: { ...DEFAULT_FILTERS },
        appliedFiltersAmt: 0
      });

      return;
    }

    const filters = {
      ...currentFilters,
      [filter]: DEFAULT_FILTERS[filter]
    };

    const filteredTransactions = filterTransactions({
      filters: filters,
      transactions: [...initialTransactions]
    });

    set({
      transactions: [...filteredTransactions],
      filters: filters,
      appliedFiltersAmt: Math.max(appliedFilterCount - 1, 0)
    });
  },

  setModal: (payload) => {
    const currentPayload = get().modal;

    if (!payload || payload.transaction.id === currentPayload?.transaction.id) {
      set({ modal: null });
      return;
    }

    set({ modal: payload });
  }
}));