import { FC } from "react";
import { Stack, Typography, styled } from "@mui/material";

import TransactionRow from "components/dashboard/partials/transactions/transactionRow";

import type { TransactionResponse } from "@lib/types/transaction";
import type { InstitutionPayment } from "@lib/types/institutionPayment";

const TransactionList = styled(Stack)({
  maxHeight: "calc(3.5rem * 3)",
  overflowY: "scroll",
  scrollbarWidth: "none" /* Chrome, Edge, Firefox, Opera */,
  "&:MsOverflowStyle": "none" /* Internet Explorer 10+ */,
  "&::-webkit-scrollbar": /* Safari */ {
    WebkitAppearance: "none",
    width: 0,
    height: 0,
  },
});

const NoTransactions = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "3rem",
  color: theme.palette.grey[500],
}));

interface TransactionsProps {
  transactions?: TransactionResponse[] | InstitutionPayment[];
  variant?: "transactions" | "payments";
}

const Transactions: FC<TransactionsProps> = ({ transactions, variant = "transactions" }) => {
  if (!transactions) return null;

  return (
    <>
      {transactions.length > 0 ? (
        <TransactionList>
          {transactions.map((transaction) => (
            <TransactionRow key={`${transaction.id}`} transaction={transaction} variant={variant} />
          ))}
        </TransactionList>
      ) : (
        <NoTransactions>
          <Typography>No transactions</Typography>
        </NoTransactions>
      )}
    </>
  );
};

export default Transactions;
