import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { Institution } from "@lib/types/institution";

export const getInstitutions = async (): Promise<Institution[]> => {
  try {
    const { data: { data } }= await instance.get("/institutions");

    return data.institutions as Institution[];
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};