import React  from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "components/Icons/Icon";

const XCircleIcon = React.memo(function XCircleIcon({
  outline = false,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={outline} { ...iconProps }>
      {outline ?
        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        :
        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd"/>
      }
    </Icon>
  );
});

export default XCircleIcon;
