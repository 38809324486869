import { Suspense, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { Case, Default, Else, If, Switch, Then } from "react-if";

import AccountDetailsCard from "@components/AccountDetails/AccountDetailsCard";
import Button from "@components/Button/Button";
import Spinner from "@components/Spinner/Spinner";

import { ProfileType } from "@lib/enums/userProfile";
import { VERIFICATION_TYPE } from "@components/Link529/Link529Manual/constants";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { updateManual529Linking } from "@lib/mutations/state529Mutations";

import { useLink529Store } from "@stores/link529Store";
import { useCurrentUserStore } from "@stores/currentUserStore";
import { useUserProfileStore } from "@stores/userProfileStore";

const LinkYour529Screen = () => {
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));
  const hasLinked529 = useUserProfileStore(useShallow((state) => state.profiles.onboarding.hasLinked529));

  const planType = useLink529Store(useShallow((state) => state.planType));
  const linking_guide = useLink529Store(useShallow((state) => state.guides.linking));

  const nextButtonText = useMemo(() => {
    if (hasLinked529) return "Continue";

    return "I've linked my 529";
  }, [hasLinked529]);

  const updateProfile = useUserProfileStore((state) => state.updateProfile);

  const showSkipDialog = useLink529Store((state) => state.showSkipManualLinkingDialog);
  const goToNextStep = useLink529Store((state) => state.goToNextStep);

  const {
    mutate,
    isPending: isUpdateLinkingPending,
  } = useMutation({
    mutationKey: [MUTATION_KEY.UPDATE_MANUAL_LINK],
    mutationFn: updateManual529Linking,
    throwOnError: true,
    onSuccess: async () => {
      updateProfile({
        type: ProfileType.ONBOARDING,
        update: { hasLinked529: true }
      });

      goToNextStep();
    }
  });

  // if a user has already linked their 529
  // go to the next step
  const handleNextStep = () => {
    if (hasLinked529) {
      return goToNextStep();
    }

    mutate({
      beneficiary_id: beneficiary.id,
      state_529_plan_id: beneficiary.state_529_plan_id
    });
  };

  const openIntercom = (event: React.MouseEvent) => {
    event.preventDefault();

    const intercomIframe = document.getElementById("intercom-frame");

    if (intercomIframe) {
      // @ts-expect-error
      window.Intercom("show");
    } else {
      window.open("https://www.backpack529.com/contact", "_blank");
    }
  };

  return (
    <Suspense fallback={<Spinner size="lg"/>}>
      <div className="modal-screen-footer-fixed">
        <div className="flex flex-col justify-between">
          <h2 className="modal-header-title">Link your 529</h2>

          <p className="modal-header-description">
            Follow the steps below to add Backpack as a new bank account in you 529&apos;s online portal.
          </p>

          <div className="pb-11 text-sm">
            <If condition={planType === VERIFICATION_TYPE.OTHER}>
              <Then>
                <p className="pt-4 pb-2">
                  As you selected “Other” for your 529 plan, <a href="#" target="_blank" rel="noreferrer" className="link" onClick={(event) => openIntercom(event)}>start a conversation with our support team here</a> to get personalized steps for linking your account and making a withdrawal.
                  <br/><br/>
                  If you use a Financial Advisor we are happy to provide them with an onboarding guide for your Backpack account. Please contact our support team to request.
                </p>
              </Then>

              <Else>
                <ol className="list-inside list-decimal space-y-2 py-5">
                  <Switch>
                    <Case condition={planType === VERIFICATION_TYPE.BANK_FORMS}>
                      <li>
                        Request a bank letter from Backpack (<a href="mailto:support@backpack529.com?subject=Request%20for%20Bank%20Letter&body=Hello%20Support%20Team,%20I%20would%20like%20to%20request%20a%20bank%20letter.%0A%0AThank%20you,%0A[Your%20Name]" className="link">click here to request</a>)
                      </li>

                      <li>
                        Complete the Add/Update Bank Information form
                      </li>
                    </Case>

                    <Case condition={planType === VERIFICATION_TYPE.PLAID_LINK}>
                      <li>Log in to your 529&apos;s online portal</li>

                      <li>Add a “New Bank Account”, using your Backpack account & routing numbers below.</li>

                      <li>Your 529 uses Plaid to verify new bank accounts. Search “Backpack” when prompted and enter the email address associated with your Backpack account.</li>
                    </Case>

                    <Default>
                      <li>Log in to your 529&apos;s online portal</li>

                      <li>Add a “New Bank Account”, using your Backpack account & routing numbers below.</li>
                    </Default>
                  </Switch>
                </ol>
              </Else>
            </If>

            <If condition={planType === VERIFICATION_TYPE.BANK_FORMS}>
              <Then>
                <p>
                  See our guide for links to forms and detailed steps on <a href={linking_guide} target="_blank" rel="noreferrer" className="link">how to link your 529 Plan to Backpack</a>
                </p>
              </Then>

              <Else>
                <p>
                  See our guide for detailed steps on <a href={linking_guide} target="_blank" rel="noreferrer" className="link">how to link your 529 Plan to Backpack</a>.
                </p>
              </Else>
            </If>
          </div>

          <AccountDetailsCard showIcon className="border" />
        </div>

        <div className="flex flex-col gap-2.5 shrink-0 w-full">
          <Button
            rounded
            fullWidth
            disabled={isUpdateLinkingPending}
            onClick={handleNextStep}
          >
            {isUpdateLinkingPending
              ? <Spinner color="white" />
              : nextButtonText
            }
          </Button>

          <Button
            rounded
            fullWidth
            secondary
            onClick={showSkipDialog}
          >
            I&#39;ll do it later
          </Button>
        </div>
      </div>
    </Suspense>
  );
};

export default LinkYour529Screen;