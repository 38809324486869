import React, { FC } from "react";
import { Dialog as MuiDialog, IconButton, paperClasses } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material";

const AbsoluteCloseIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: ".5rem",
  right: ".5rem",
  color: theme.palette.primary.dark,
}));

const CustomDialog = styled(MuiDialog)({
  [`& .${paperClasses.root}`]: {
    borderRadius: "14px",
    padding: "2.5rem 2rem",
    maxWidth: "28rem",
  },
});

interface DialogProps {
  open: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

const Dialog: FC<DialogProps> = ({ open, children, onClose }) => {
  return (
    <CustomDialog onClose={onClose} open={open}>
      <AbsoluteCloseIcon aria-label="close" onClick={onClose} data-cy="dialog-close">
        <CloseIcon />
      </AbsoluteCloseIcon>
      {children}
    </CustomDialog>
  );
};

export default Dialog;
