import Accordion from "../Accordion";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import PayPalLogo from "assets/logos/paypal.svg";
import AmazonLogo from "assets/logos/amazon.svg";

const PAYMENT_METHODS = [
  {
    name: "PayPal",
    img: PayPalLogo,
    width: "100",
    href: "https://intercom.help/backpack-529/en/articles/9390245-how-to-shop-online-using-paypal-with-your-backpack-account",
  },
  {
    name: "Amazon",
    img: AmazonLogo,
    width: "80",
    href: "https://intercom.help/backpack-529/en/articles/9390241-how-to-shop-on-amazon-using-your-backpack-account",
  },
];

const PaymentMethodsList = () => {
  return (
    <Accordion title="Add Backpack as a payment method">
      <ul className="payment-methods">
        {PAYMENT_METHODS.map((method) => (
          <a key={method.name} href={method.href} target="_blank" rel="noreferrer">
            <li>
              <img src={method.img} alt={method.name} width={method.width} />
              <AddCircleOutlineIcon />
            </li>
          </a>
        ))}
      </ul>
    </Accordion>
  );
};

export default PaymentMethodsList;
