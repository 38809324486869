const StoreReceipts = () => {
  return (
    <div className="flex flex-col gap-4 px-6 sm:px-0 text-sm md:text-base text-left">
      <h2 className="text-xl md:text-3xl font-semibold text-left">Store Your Receipts with Backpack</h2>

      <p>Using your Backpack account to track 529 eligible expenses takes the stress out of tax time and simplifies expense management.</p>

      <p>Submit your receipts via email to <a href="mailto:receipts@backpack529.com">receipts@backpack529.com</a>. If the IRS ever requires proof of purchase, simply contact Backpack support for receipt retrieval.</p>

      <p>We are also working on exciting new features for receipt storage, retrieval and 529 eligibility... coming soon!</p>
    </div>
  );
};

export default StoreReceipts;