import React, { SyntheticEvent } from "react";
import {
  Button,
  Stack,
  Typography,
  FormControl,
  FormGroup,
  Autocomplete,
  TextField,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import type { Institution } from "@lib/types/institution";
import type { PaymentInfo } from "@lib/types/institutionPayment";

const Content = styled(Stack)(({ theme }) => ({
  margin: "2.75rem 1.75rem 1.75rem 1.75rem",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    margin: "2.75rem 0",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  width: "90%",
  textAlign: "justify",
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

type InstitutionSelectionProp = {
  institutions: Institution[];
  selectedInstitution: Institution | null;
  handleNext: () => void;
  handleSelectedInstitution: (institution: Institution | null) => void;
  updatePaymentInfo: (info: Partial<PaymentInfo>) => void;
}

const InstitutionSelection = ({
  institutions,
  selectedInstitution,
  handleNext,
  handleSelectedInstitution,
  updatePaymentInfo
}: InstitutionSelectionProp) => {
  const [error, setError] = React.useState("");

  const handleConfirmInstitutions = () => {
    if (!selectedInstitution) {
      setError("Please select an Institution");
      return;
    }
    updatePaymentInfo({ recipient: selectedInstitution });
    handleNext();
  };

  // @ts-ignore
  const onInstitutionSelection = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const selected = institutions.find((inst) => inst.name === newValue);

    handleSelectedInstitution(selected || null);
  };

  return (
    <Content>
      <FormControl sx={{ alignItems: "center" }}>
        <FormGroup>
          <Autocomplete
            data-cy="payment-institution"
            disablePortal
            value={selectedInstitution?.name}
            onChange={onInstitutionSelection}
            options={institutions.map((institution) => institution.name)}
            renderInput={(params) => (
              <TextField
                name="institutions"
                {...params}
                label="Recipient"
                helperText="Confirm you want to pay this Education Institution"
              />
            )}
          />
        </FormGroup>
      </FormControl>

      <Stack alignItems="center">
        <Text variant="legal">
          If it isn’t the right University, or you want to change the University your account is
          associated with, please contact support.
        </Text>

        <ConfirmButton
          variant="contained"
          data-cy="payment-confirm-institution"
          onClick={handleConfirmInstitutions}
          disabled={!selectedInstitution}>
          Confirm
        </ConfirmButton>

        {!!error && (
          <Typography mb="1rem" color="error" data-cy="payment-institution-error">
            {error}
          </Typography>
        )}

        <Typography>
          <Link id="intercom_support" href="https://www.backpack529.com/contact" underline="none">
            Contact support
          </Link>
        </Typography>
      </Stack>
    </Content>
  );
};

export default InstitutionSelection;