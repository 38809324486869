import { create } from "@stores/utils";

import type { Notification, NotificationOptions } from "@lib/types/notification";

type NotificationState = {
  notification: Notification & Omit<NotificationOptions, "delay">;
};

type NotificationAction = {
  addNotification: (notification: NotificationState["notification"]) => void;
  clearNotification: () => void;
};

export const useNotificationStore = create<NotificationState & NotificationAction>()((set) => ({
  notification: {} as NotificationState["notification"],
  addNotification: (notification) => set({
    notification: { ...notification }
  }),
  clearNotification: () => set({
    notification: {} as NotificationState["notification"]
  })
}));