import { create } from "@stores/utils";

type AuthState = {
  token: string | null;
};

type AuthAction = {
  setToken: (authToken: string | null) => void;
  removeToken: () => void;
  isAuthed: () => boolean;
};

export const useAuthStore = create<AuthState & AuthAction>()((set, get) => ({
  token: localStorage.getItem("token"),

  setToken: (authToken) => {
    if (authToken) {
      localStorage.setItem("token", authToken);
    } else {
      localStorage.removeItem("token");
    }
    set({ token: authToken });
  },

  removeToken: () => {
    localStorage.removeItem("token");
    set({ token: null });
  },

  isAuthed: () => {
    const token = get().token;
    return !!token;
  }
}));