import { Switch, Case, When } from "react-if";

import { Button } from "@mui/material";

import Card from "components/Card";
import Tooltip from "components/Tooltip";
import Link529StatusNotice from "components/Link529/Link529StatusNotice";

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import WarningIcon from "components/Icons/WarningIcon";

import { LinkType } from "@lib/enums/link529";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { State529Connection } from "@lib/types/state529";

type BeneficiaryProps = {
  beneficiary: Beneficiary;
  state529Connection: State529Connection;
  openLink529Modal: (linkType: LinkType) => void;
  openPayTuitionModal: () => void;
  openWithdrawalModal: () => void;
};

const Beneficiary = ({
  beneficiary,
  state529Connection,
  openLink529Modal,
  openPayTuitionModal,
  openWithdrawalModal
}: BeneficiaryProps) => {
  const {
    status,
    has_linked_before,
    is_withdrawal_eligible,
    is_automated_withdrawal_eligible
  } = state529Connection;

  const beneficiaryFullName = `${beneficiary.first_name} ${beneficiary.last_name}`;

  const handleLink529ButtonClick = (linkType: LinkType) => {
    if (is_automated_withdrawal_eligible) {
      openLink529Modal(linkType);
    }
  };

  const linkButtonId = is_automated_withdrawal_eligible
    ? "automated_link_eligible"
    : "automated_link_uneligible";

  const showLinked529Icon = !!state529Connection
    && has_linked_before
    && status === "LINKED";

  return (
    <div className="flex flex-col gap-y-2.5">
      <span className="block sm:hidden text-sm">Beneficiary</span>

      <Card
        data-cy="Beneficiary"
        containerClassName="w-full"
        className="flex flex-col gap-y-4 sm:gap-y-5"
      >
        <div className="flex flex-col sm:flex-row justify-between gap-x-2 gap-y-4 sm:gap-y-5">
          <div className="flex flex-col justify-between gap-4 lg:basis-2/5">
            <h3 className="text-xl md:text-2xl text-blue relative mr-6 break-words text-wrap">
              {beneficiaryFullName}
              <When condition={showLinked529Icon}>
                <Tooltip
                  title="529 Linked"
                  placement="top"
                  arrow
                >
                  <VerifiedOutlinedIcon className="size-5 text-blue absolute ml-0.5" data-cy="has-529-linked"/>
                </Tooltip>
              </When>
            </h3>

            <span className="hidden sm:block text-md xl:text-base mb-2">Beneficiary</span>
          </div>

          <Link529StatusNotice className="sm:hidden" state529Connection={state529Connection} />

          <div className="flex-1 flex flex-col gap-2.5">
            <Button
              data-cy="dashboard-initiate-payment"
              variant="contained"
              className="h-12 px-5 py-2.5 self-end w-full sm:max-w-60 xl:w-60 bg-gradient font-bold text-base"
              onClick={openPayTuitionModal}
            >
              Pay Tuition
            </Button>

            <Switch>
              <Case condition={status === "NOT_LINKED"}>
                <Button
                  data-cy="link-529-button"
                  variant="outlined"
                  className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                  id={linkButtonId}
                  onClick={() => handleLink529ButtonClick(LinkType.LINK)}
                >
                  Link 529
                </Button>
              </Case>

              <Case condition={status === "NEEDS_RELINK"}>
                <Button
                  data-cy="relink-529-button"
                  variant="contained"
                  className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end font-bold text-base gap-1.5 bg-red-500 hover:bg-red-600"
                  onClick={() => handleLink529ButtonClick(LinkType.RELINK)}
                >
                  <WarningIcon
                    outline
                    strokeWidth={2}
                    aria-hidden="true" className="size-5 text-white -mb-0.5"
                  /> Re-Link 529
                </Button>
              </Case>

              <Case condition={status === "LINKED" && is_automated_withdrawal_eligible}>
                <Button
                  data-cy="add-529-funds-button"
                  variant="outlined"
                  className="h-12 w-full sm:max-w-60 xl:w-60 px-5 py-2.5 self-end text-base secondary-button"
                  disabled={!is_withdrawal_eligible}
                  onClick={openWithdrawalModal}
                >
                  Add 529 Funds
                </Button>
              </Case>
            </Switch>
          </div>
        </div>

        <Link529StatusNotice className="max-sm:hidden" state529Connection={state529Connection} />
      </Card>
    </div>
  );
};

export default Beneficiary;