export const ERROR_TYPE = {
  DUPLICATE_ROW_EXCEPTION: "DUPLICATE_ROW_EXCEPTION",
  DUPLICATE_APPLICATION: "DUPLICATE_APPLICATION",
  DUPLICATE_RECORD: "DUPLICATE_RECORD",
  MFA_CODE_EXCEPTION: "MFA_CODE_EXCEPTION",
  VERIFY_MFA_ERROR: "VERIFY_MFA_ERROR",
  UNEXPECTED_ERROR_LOGIN: "UNEXPECTED_ERROR_LOGIN",
  RECORD_NOT_FOUND: "RECORD_NOT_FOUND",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
  UNKNOWN_EXCEPTION: "UNKNOWN_EXCEPTION",
  ACCESS_DENIED_EXCEPTION: "ACCESS_DENIED_EXCEPTION",
  INVALID_FORMAT: "INVALID_FORMAT"
} as const;