export const RouteType = {
  ROOT: "root",
  LOGIN: "login",
  SIGNUP: "signup",
  DASHBOARD: "dashboard",
  TRANSACTIONS: "transactions",
  SETTINGS: "settings",
  ONBOARDING: "onboarding",
} as const;

export const BASE_ROUTE = {
  [RouteType.ROOT]: "/",
  [RouteType.LOGIN]: "/login",
  [RouteType.SIGNUP]: "/signup",
  [RouteType.DASHBOARD]: "/dashboard",
  [RouteType.TRANSACTIONS]: "/transactions",
  [RouteType.SETTINGS]: "/settings",
  [RouteType.ONBOARDING]: "/onboarding",
};