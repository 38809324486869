import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { State529Connection, State529Plan } from "@lib/types/state529";

export const getState529Plans = async (): Promise<State529Plan[]> => {
  try {
    const {
      data: { data },
    } = await instance.get("/state-529-plans");

    return data.plans as State529Plan[];
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};

export const getState529Connection = async (
  state529PlanId: State529Plan["id"],
): Promise<State529Connection> => {
  try {
    const {
      data: { data },
    } = await instance.get(`/state-529-connect/${state529PlanId}/status`);

    return data;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};