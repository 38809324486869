import React, { FC } from "react";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CardFooter from "./CardFooter";

import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";

const CardComponent = styled(Stack)(({ theme }) => ({
  position: "relative",
  borderRadius: "0.75rem",
  boxShadow: "0 2px 4px 0 rgba(4, 4, 52, .06), 0 0 2px 0 rgba(91, 95, 107, .24)",
  minHeight: "35rem",
  width: "27.5rem",
  textAlign: "center",
  padding: "1rem 2.5rem",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    borderRadius: 0,
    boxShadow: "none",
    padding: "1rem",
  },
}));

const AbsoluteIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "2rem",
  color: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    top: "1rem",
  },
}));

const RightAbsoluteIcon = styled(AbsoluteIcon)(({ theme }) => ({
  right: "2rem",
  [theme.breakpoints.down("sm")]: {
    right: "0.5rem",
  },
}));

const LeftAbsoluteIcon = styled(AbsoluteIcon)(({ theme }) => ({
  left: "2rem",
  [theme.breakpoints.down("sm")]: {
    left: "0.5rem",
  },
}));

interface CardModuleProps {
  children: React.ReactNode;
  onClose?: () => void;
  goBack?: (() => void) | null;
}

const Card: FC<CardModuleProps> = ({ children, onClose, goBack }) => {
  return (
    <CardComponent>
      {onClose && (
        <RightAbsoluteIcon aria-label="close" onClick={onClose} data-cy="close-modal">
          <ClearIcon />
        </RightAbsoluteIcon>
      )}
      {goBack && (
        <LeftAbsoluteIcon aria-label="back" onClick={goBack}>
          <ArrowBackIcon />
        </LeftAbsoluteIcon>
      )}
      {children}
      <CardFooter />
    </CardComponent>
  );
};

export default Card;
