import { createTheme } from "@mui/material";
import theme from "theme";

export const moduleTheme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
        },
      },
    },
  },
});
