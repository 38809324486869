import { COLOR } from "@lib/constants/theme";

export const CHIP_BUTTON_COLOR = {
  [COLOR.GRAY]: "hover:bg-gray-500/20",
  [COLOR.RED]: "hover:bg-red-600/20",
  [COLOR.YELLOW]: "hover:bg-yellow-600/20",
  [COLOR.GREEN]: "hover:bg-green-600/20",
  [COLOR.BLUE]: "hover:bg-blue-600/20",
  [COLOR.INDIGO]: "hover:bg-indigo-600/20",
  [COLOR.PURPLE]: "hover:bg-purple-600/20",
  [COLOR.PINK]: "hover:bg-pink-600/20",
};

export const CHIP_BUTTON_ICON_COLOR = {
  [COLOR.GRAY]: "stroke-gray-700/50 group-hover:stroke-gray-700/75",
  [COLOR.RED]: "stroke-red-700/50 group-hover:stroke-red-700/75",
  [COLOR.YELLOW]: "stroke-yellow-800/50 group-hover:stroke-yellow-800/75",
  [COLOR.GREEN]: "stroke-green-800/50 group-hover:stroke-green-800/75",
  [COLOR.BLUE]: "stroke-blue-800/50 group-hover:stroke-blue-800/75",
  [COLOR.INDIGO]: "stroke-indigo-700/50 group-hover:stroke-indigo-700/75",
  [COLOR.PURPLE]: "stroke-purple-700/50 group-hover:stroke-purple-700/75",
  [COLOR.PINK]: "stroke-pink-800/50 group-hover:stroke-pink-800/75",
};

export default {
  CHIP_BUTTON_COLOR,
  CHIP_BUTTON_ICON_COLOR
};