import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Combines class names into a single string, intelligently merging Tailwind CSS classes
 * to avoid specificity conflicts..
 *
 * @param {...ClassValue[]} args - A spread of class values that can include strings,
 * arrays of strings, or conditional class names (e.g., undefined, null, or false
 * values are ignored).
 *
 * @returns {string} A single string of merged class names, with conflicting Tailwind
 * classes resolved based on the order of appearance.
 *
 */
export function classnames(...args: ClassValue[]) {
  return twMerge(clsx(args));
}
