import React from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "components/Icons/Icon";

const ShieldExclamationIcon = React.memo(function ShieldExclamationIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={outline} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
    </Icon>
  );
});

export default ShieldExclamationIcon;
