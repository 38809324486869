/**
 * Enum for a state 529's connection status
 *
 * used as part of State529Connection["status"]
 *
 * LINKED, NEEDS_RELINK, NOT_LINKED
 */
export enum State529ConnectionStatus {
  LINKED = "LINKED",
  NEEDS_RELINK = "NEEDS_RELINK",
  NOT_LINKED = "NOT_LINKED"
}

/**
 * Enum for a state 529's plan provider type
 *
 * AUTOMATED, MANUAL
 */
export enum State529ProviderType {
  AUTOMATED = "automated",
  MANUAL = "manual"
}