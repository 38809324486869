import * as Sentry from "@sentry/react";
import { ErrorInfo } from "react";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import defaultFallbackRender from "components/errorBoundary/defaultFallbackRender";

const logError = (error: Error, info: ErrorInfo) => {
  console.error(error, info.componentStack);
  // Log error to external service

  Sentry.captureException(error);
};

// Note [Santucho, 12/15/2023]: This error boundary layout allows us to catch errors in any of the routes without
// having to wrap each route in an error boundary.
const GlobalErrorBoundary = () => (
  <ErrorBoundary fallbackRender={defaultFallbackRender} onError={logError}>
    <Outlet />
  </ErrorBoundary>
);

export default GlobalErrorBoundary;
