const config = {
  api: {
    url: import.meta.env.VITE_APP_BACKPACKPAY_API_HOST,
    version: import.meta.env.VITE_APP_BACKPACKPAY_API_VERSION,
    baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
  },
  env: import.meta.env.NODE_ENV,
};

export default config;
