import { WithdrawalStatus, WithdrawalError } from "@lib/enums/withdrawal";

import type { Beneficiary } from "@lib/types/beneficiary";
import type { UUID } from "@lib/types/global";
import type { State529Plan } from "@lib/types/state529";

export enum Steps {
  AMOUNT = "amount",
  CONFIRMATION = "confirmation",
  VERIFY = "verify",
  SUCCESS = "success"
}

export enum Actions {
  SET_AMOUNT = "set_amount",
  START_WITHDRAWAL_REQUEST = "start_withdrawal_request",
  UPDATE_WITHDRAWAL_REQUEST = "update_withdrawal_request",
  SET_ERROR = "set_error",
  CONTINUE = "continue",
  CANCEL = "cancel",
  RESTART = "restart"
}

export type Policy = {
  name: string;
  version: Date;
}

type AutomatedWithdrawalState = {
  step: Steps;
  stepCount: {
    current: number;
    total: number;
  };
  beneficiary: Beneficiary;
  state529Plan: State529Plan;
  amount: number; // in cents
  withdrawalId: UUID | null;
  confirmationId: string | null;
  status: WithdrawalStatus | null;
  error: WithdrawalError | null;
}

export type Action = |
{
  type: Actions.SET_AMOUNT,
  payload: { amount: number; };
}
  | {
    type: Actions.START_WITHDRAWAL_REQUEST,
    payload: { id: UUID; status: WithdrawalStatus;};
  }
  | {
    type: Actions.UPDATE_WITHDRAWAL_REQUEST,
    payload: {
      confirmationId: string;
      status: WithdrawalStatus;
    };
  }
  | {
    type: Actions.SET_ERROR,
    payload: { error: WithdrawalError; status?: WithdrawalStatus };
  }
  | {
    type: Exclude<
      Actions,
      | Actions.SET_AMOUNT
      | Actions.START_WITHDRAWAL_REQUEST
      | Actions.UPDATE_WITHDRAWAL_REQUEST
      | Actions.SET_ERROR
    >;
  };


type CreateInitialStateProps = Pick<AutomatedWithdrawalState, "beneficiary" | "state529Plan"> & {
  amount?: number;
}

const DEFAULT_AUTOMATED_WITHDRAWAL_STATE: AutomatedWithdrawalState = {
  step: Steps.AMOUNT,
  stepCount: {
    current: 1,
    total: Object.keys(Steps).length
  },
  beneficiary: {} as Beneficiary,
  state529Plan: {} as State529Plan,
  amount: 0,
  withdrawalId: null,
  confirmationId: null,
  status: null,
  error: null,
};

export const automatedWithdrawalReducer = (state: AutomatedWithdrawalState, action: Action): AutomatedWithdrawalState => {
  switch (action.type) {
    case Actions.SET_AMOUNT: {
      const { amount } = action.payload;

      return {
        ...state,
        amount
      };
    }

    case Actions.START_WITHDRAWAL_REQUEST: {
      const { id, status } = action.payload;

      return {
        ...state,
        status,
        withdrawalId: id,
      };
    }

    case Actions.UPDATE_WITHDRAWAL_REQUEST: {
      const { confirmationId, status } = action.payload;

      return {
        ...state,
        status,
        confirmationId
      };
    }

    case Actions.SET_ERROR: {
      const { error, status } = action.payload;

      return {
        ...state,
        status: status || state.status,
        error,
      };
    }

    case Actions.CONTINUE: {
      let step;
      switch (state.step) {
        case Steps.AMOUNT:
          step = Steps.CONFIRMATION;
          break;

        case Steps.CONFIRMATION:
          step = Steps.VERIFY;
          break;

        case Steps.VERIFY:
          step = Steps.SUCCESS;
          break;

        default:
          throw Error(`Unknown step for action ${action.type} with ${state.step}`);
      }

      return {
        ...state,
        step,
        stepCount: {
          ...state.stepCount,
          current: state.stepCount.current++
        }
      };
    }

    case Actions.CANCEL:
    case Actions.RESTART:
      return {
        ...DEFAULT_AUTOMATED_WITHDRAWAL_STATE,
        beneficiary: state.beneficiary,
        state529Plan: state.state529Plan,
      };

    default:
      return state;
  }
};

export const createInitialWithdrawalState = ({
  beneficiary,
  amount,
  state529Plan,
}: CreateInitialStateProps) => {

  const initialState = {
    ...DEFAULT_AUTOMATED_WITHDRAWAL_STATE,
    beneficiary,
    state529Plan,
  };

  if (!!amount && amount > 0) {
    initialState.amount = amount;
    initialState.step = Steps.CONFIRMATION;
    initialState.stepCount.current = 2;

    return initialState;
  }

  return initialState;
};
