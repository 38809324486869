export const parseRow = (value: string) =>
  value.slice(0, 4) + "*".repeat(value.length - 5) + value.slice(-1);

/**
 * Deprecated
 * Use formatCurrency in @lib/utils/formatAmounts
 * */
export const formatCurrency = (value: number): string => {
  const dollars = value / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(dollars);
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = phoneNumber.replace(/\D/g, "");
  let formattedNumber;

  if (cleaned.length >= 1 && cleaned.length <= 3) {
    formattedNumber = "(" + cleaned;
  } else if (cleaned.length > 3 && cleaned.length <= 6) {
    formattedNumber = "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3);
  } else if (cleaned.length > 6) {
    formattedNumber =
      "(" + cleaned.substring(0, 3) + ") " + cleaned.substring(3, 6) + "-" + cleaned.substring(6);
  }

  return formattedNumber || phoneNumber;
};

export const sanitizeNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, "");

/**
 * Deprecated
 * Use sanitizeAmount in @lib/utils/formatAmounts
 */
export const sanitizeAmount = (amount: string) => Number(amount.replace(/[^0-9]/g, ""));

export const snakeCase = (string: string) => {
  return string
    .replace(/\d+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
};

export const startCase = (sentence: string) => {
  if (typeof sentence !== "string") {
    return "";
  }

  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// format date to YYYY-MM-DD
export const formatDate = (date: Date) => {
  return date.toISOString().split("T")[0];
};
