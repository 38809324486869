import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useShallow } from "zustand/react/shallow";
import { When } from "react-if";

import Button from "@components/Button/Button";
import DescriptionList from "@components/DescriptionList/DescriptionList";
import Well from "@components/Well";
import Tooltip from "@components/Tooltip/Tooltip";
import InformationCircleIcon from "@components/Icons/InformationCircleIcon";
import Spinner from "@components/Spinner/Spinner";

import { MUTATION_KEY } from "@lib/mutations/constants";
import { createInstitutionPayment } from "@lib/mutations/institutionPaymentMutations";

import { useCurrentUserStore } from "@stores/currentUserStore";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";

import { formatAmountInCentsToCurrency } from "@utils/formatAmounts";

const StatusTooltip = () => {
  return (
    <Tooltip text="Awaiting funds transfer from your 529 Plan to Backpack. Payment will be completed once funds are received." position="right">
      <span className="flex gap-1 items-center">
        Status
        <InformationCircleIcon
          outline
          aria-hidden={true}
          strokeWidth={3}
          className="size-4 text-gray-500"
        />
      </span>
    </Tooltip>
  );
};

const TuitionPaymentReviewScreen = () => {
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));
  const tuitionPaymentPayload = useTuitionPaymentsStore(useShallow((state) => state.tuitionPaymentPayload));

  const errorMessage = useTuitionPaymentsStore(useShallow((state) => state.errorMessage));
  const setErrorMessage = useTuitionPaymentsStore((state) => state.setErrorMessage);

  const goToPrevStep = useTuitionPaymentsStore((state) => state.goToPrevStep);
  const goToNextStep = useOnboardingStore((state) => state.goToNextStep);

  const termName = useMemo(() => {
    const { institution_period_id } = tuitionPaymentPayload;

    if (!institution_period_id) return "";

    const term = beneficiary.enrolled_institutions[0].periods.find((period) => period.is_active && (period.id === tuitionPaymentPayload.institution_period_id));

    return term?.description || "";
  }, [beneficiary, tuitionPaymentPayload]);

  const tuitionPaymentDetails = useMemo(() => {
    return [{
      term: "Recipient",
      details: beneficiary.enrolled_institutions[0].name
    },
    {
      term: "Student",
      details: `${beneficiary.first_name} ${beneficiary.last_name}`
    },
    {
      term: "Student ID Number",
      details: beneficiary.enrollments[0].student_id,
    },
    {
      term: "Amount",
      details: formatAmountInCentsToCurrency(tuitionPaymentPayload.amount_in_cents, true),
    },
    {
      term: "Term",
      details: termName
    },
    {
      term: <StatusTooltip />,
      details: "Pending"
    }];
  }, [beneficiary, tuitionPaymentPayload, termName]);

  const createTuitionPayment = useMutation({
    mutationKey: [MUTATION_KEY.CREATE_INSTITUTION_PAYMENT],
    mutationFn: createInstitutionPayment,
    onSuccess: () => goToNextStep(),
    onError: (error) => {
      setErrorMessage(error.message);
    }
  });

  return (
    <div className="modal-screen-footer-fixed">
      <div className="flex flex-col justify-between items-center">
        <h2 className="modal-header-title">Review tuition payment details</h2>

        <p className="modal-header-description">We&#39;ll notify {beneficiary.enrolled_institutions[0].name} that your payment is in process and confirm your attendance. Your payment will be marked as pending until the funds are transferred to Backpack.</p>

        <When condition={!!errorMessage}>
          <Well color="error" className="font-semibold">
            {errorMessage}
          </Well>
        </When>

        <div className="w-full mt-8">
          <DescriptionList list={tuitionPaymentDetails} listItemClassName="py-3.5" />
        </div>
      </div>

      <div className="w-full shrink-0 flex justify-between gap-4">
        <Button
          secondary
          rounded
          fullWidth
          onClick={goToPrevStep}
        >
          Back
        </Button>

        <Button
          rounded
          fullWidth
          disabled={createTuitionPayment.isPending}
          onClick={() => createTuitionPayment.mutate(tuitionPaymentPayload)}
        >
          {createTuitionPayment.isPending
            ? <Spinner color="white" />
            : "Create Payment"
          }
        </Button>
      </div>
    </div>
  );
};

export default TuitionPaymentReviewScreen;