import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { ERROR_TYPE } from "@lib/constants/error";

import { useAuth } from "@hooks/useAuth";

import { DEFAULT_QUERY_OPTIONS, FOREVER_STALE_QUERY_OPTIONS, QUERY_KEY } from "@lib/queries/constants";
import {
  getAccountOwner,
  getBankAccount,
  getBankAccountBalance
} from "@lib/queries/accountQueries";

import type { CurrentUser } from "@lib/types/currentUser";

export const useFetchCurrentUser = () => {
  const { logout } = useAuth();
  let currentUser = {} as CurrentUser;

  const {
    data: account_owner,
    isLoading: isAccountOwnerLoading,
    isSuccess: isAccountOwnerSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.ACCOUNT_OWNER],
    queryFn: getAccountOwner,
    throwOnError: (error) => {
      if (error?.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION) {
        logout();
        return false;
      }

      return true;
    },
    ...FOREVER_STALE_QUERY_OPTIONS
  });

  const {
    data: bank_account,
    isLoading: isBankAccountLoading,
    isSuccess: isBankAccountSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.BANK_ACCOUNT],
    queryFn: async () => {
      const results = await getBankAccount();

      const {
        id,
        account_number,
        routing_number,
        account_type,
        status
      } = results;

      return {
        id,
        account_number,
        routing_number,
        account_type,
        status
      };
    },
    throwOnError: (error) => {
      if (error?.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION) {
        logout();
        return false;
      }

      return true;
    },
    enabled: !!account_owner,
    ...FOREVER_STALE_QUERY_OPTIONS
  });

  const bankAccountId = useMemo(() => bank_account?.id || "", [bank_account?.id]);

  const {
    data: bank_account_balance,
    isLoading: isBalanceLoading,
    isSuccess: isBalanceSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.BANK_ACCOUNT_BALANCE, bankAccountId],
    queryFn: async () => {
      const results = await getBankAccountBalance(bankAccountId);

      const {
        available_balance_in_cents,
        current_balance_in_cents
      } = results;

      return {
        available_balance_in_cents,
        current_balance_in_cents
      };
    },
    throwOnError: (error) => {
      if (error?.name === ERROR_TYPE.ACCESS_DENIED_EXCEPTION) {
        logout();
        return false;
      }

      return true;
    },
    enabled: !!bankAccountId && !!bankAccountId.length,
    ...DEFAULT_QUERY_OPTIONS
  });

  const isLoading = isAccountOwnerLoading
    || isBankAccountLoading
    || isBalanceLoading;

  const isSuccess = isAccountOwnerSuccess
    && isBankAccountSuccess
    && isBalanceSuccess;

  if (isSuccess) {
    currentUser = {
      ...account_owner,
      bank_account,
      bank_account_balance,
    } as CurrentUser;
  }

  return {
    currentUser,
    isLoading,
    isSuccess
  };
};