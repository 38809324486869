const DEFAULT_OLDEST_YEAR = 2024;

export function createYearOptions(oldestYear: number = DEFAULT_OLDEST_YEAR): { label: string, value: string; }[] {
  const currentYear = new Date().getFullYear();
  const yearOptions = [];

  for (let year = currentYear; year >= oldestYear; year--) {
    yearOptions.push({ label: year.toString(), value: year.toString() });
  }

  return yearOptions;
}