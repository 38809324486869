/**
 * Enum for withdrawal status
 *
 * CREATED, FAILED, INSUFFICIENT_FUNDS, SUBMITTED, FAILED_NEEDS_RELINK
 */
export enum WithdrawalStatus {
  CREATED = "CREATED",
  FAILED = "FAILED",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  SUBMITTED = "SUBMITTED",
  FAILED_NEEDS_RELINK = "FAILED_NEEDS_RELINK"
}

/**
 * Enum for withdrawal error types
 *
 * UNEXPECTED, REQUIREMENTS_NOT_MET
 */
export enum WithdrawalError {
  UNEXPECTED = "unexpected",
  REQUIREMENTS_NOT_MET = "requirements_not_met"
}