import React from "react";

import Icon from "@components/Icons/Icon";
import type { OutlineIconProps } from "@components/Icons/Icon";

const ChevronUpDownIcon = React.memo(function ChevronUpDownIcon({
  outline,
  ...iconProps
}: OutlineIconProps) {
  return (
    <Icon outline={true} { ...iconProps }>
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
    </Icon>
  );
});

export default ChevronUpDownIcon;