import { FC } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

const ErrorPageStack = styled(Stack)({
  marginTop: "3rem",
  alignItems: "center",
  flexGrow: 1,
  gap: "2rem",
});

const ErrorIcon = styled(ErrorTwoToneIcon)(({ theme }) => ({
  width: "100px",
  height: "100px",
  color: theme.palette.error.main,
}));

const ContactLink = styled("a")({
  display: "flex",
  justifyContent: "center",
});

interface ContactSupportProps {
  title: string;
  link: string;
}

const ContactSupport: FC<ContactSupportProps> = ({ title, link }) => {
  return (
    <ErrorPageStack spacing={2}>
      <Typography data-cy="contact-support-title" variant="h5">
        <h3>{title}</h3>
      </Typography>
      <ErrorIcon />
      <Stack spacing={3}>
        <Typography data-cy="contact-support-information">
          Please contact support to rectify the issue.
        </Typography>
        <ContactLink
          data-cy="contact-support-link"
          href={link}
          id="intercom_support"
          target="_blank"
          rel="noreferrer">
          <Button variant="contained" fullWidth>
            Contact Support
          </Button>
        </ContactLink>
      </Stack>
    </ErrorPageStack>
  );
};

export default ContactSupport;
