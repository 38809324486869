import React from "react";
import { useIdleTimer, EventsType } from "react-idle-timer";

const TIME_TO_IDLE_IN_MILISENCONDS = 1_000 * 60 * 10; // 10 minutes
const TIME_TO_LOGOUT_FROM_PROMPT_IN_MILISENCONDS = 1_000 * 60 * 5; // 5 minute

const events: EventsType[] = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "visibilitychange",
];

const state = {
  active: "active",
  idle: "idle",
} as const;

type State = (typeof state)[keyof typeof state];

export const useIdleDetector = (onIdleCallback: () => any) => {
  const [currentState, setCurrentState] = React.useState<State>("active");

  const onActive = () => {
    setCurrentState(state.active);
  };

  const onIdle = () => {
    setCurrentState(state.idle);
  };

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if (currentState === state.idle) {
      // call logout after 1 minute of propmt being displayed
      interval = setInterval(() => {
        onIdleCallback();
      }, TIME_TO_LOGOUT_FROM_PROMPT_IN_MILISENCONDS);
    }

    return () => interval && clearInterval(interval);
  }, [currentState]);

  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    timeout: TIME_TO_IDLE_IN_MILISENCONDS,
    debounce: 1_000,
    events,
  });

  return { currentState, keepAlive: activate };
};
