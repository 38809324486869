import React, { FC } from "react";
import { When } from "react-if";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AccordionProps = {
  title: string;
  icon?: React.ReactNode;
  allowCollapse?: boolean;
  children: React.ReactNode;
};

const Accordion: FC<AccordionProps> = ({ title, icon, children, allowCollapse = false }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const toggleOpen = () => setIsOpen(!isOpen);
  const Icon = isOpen ? ExpandLessIcon : ExpandMoreIcon;
  return (
    <div className={`accordion ${isOpen ? "open" : "close"}`}>
      <header className={"accordion-header"}>
        <h4>
          {icon}
          {title}
        </h4>
        <When condition={allowCollapse}>
          <button aria-label={isOpen ? "open" : "close"} onClick={toggleOpen}>
            <Icon aria-hidden="true" />
          </button>
        </When>
      </header>
      {isOpen && children}
    </div>
  );
};

export default Accordion;
