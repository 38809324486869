import * as Sentry from "@sentry/react";
import instance from "config/fetch.config";
import { errorMapper } from "utils/errors";
import type { UUID } from "@lib/types/global";
import type { Statement } from "@lib/types/statement";

export class StatementsService {
  public static async getStatements(bankAccountId: UUID): Promise<Statement[]> {
    try {
      const {
        data: { data },
      } = await instance.get(`/bank-accounts/${bankAccountId}/statements`);
      return data.statements;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  public static async getStatementById(statementId: UUID): Promise<Statement> {
    try {
      const {
        data: { data },
      } = await instance.get(`/statements/${statementId}/statements`);
      return data;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
  public static async getStatementDownloadURL(statementId: UUID): Promise<string> {
    try {
      const {
        data: { data },
      } = await instance.get(`/statements/${statementId}/pdf-url`);
      return data.url;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}
