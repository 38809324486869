import * as Sentry from "@sentry/react";
import instance from "config/fetch.config";

import type { TransactionResponse } from "@lib/types/transaction";

import { errorMapper } from "utils/errors";

export const getTransactions = async (): Promise<TransactionResponse[]> => {
  try {
    const { data: { data } } = await instance.get("/transactions");

    return data.transactions;
  } catch (error: any) {
    Sentry.captureException(error);

    const processedError = error.response.data;
    throw errorMapper(processedError);
  }
};