import React, { FC } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import SingleCharacterInputGroup from "commons/Input/SingleCharacterInputGroup";

import { InputValidator } from "utils/validations";
import Link529Services from "services/link529.services";

const Content = styled(Stack)(({ theme }) => ({
  width: "100%",
  margin: "1rem 0",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    margin: "1.75rem 0",
  },
}));

const MFAContainer = styled(Stack)({
  justifyContent: "center",
  alignItems: "center",
});

const VerificationSubtitle = styled(Typography)({
  textAlign: "center",
});

const ConfirmButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
});

const ErrorText = styled(Typography)({
  display: "flex",
  justifyContent: "center",
});

interface MFAVerificationProp {
  handleNext: (hasBackpackAccountAdded: boolean) => void;
}

const LinkMFAVerification: FC<MFAVerificationProp> = ({ handleNext }) => {
  const [code, setCode] = React.useState<string>("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState<"none" | "resend" | "validate">("none");

  const isValidCode = () => {
    const isValid = InputValidator.isValidCode(code);
    setError(isValid ? "" : "Please enter a valid code");
    return isValid;
  };

  const handleVerifyCode = async () => {
    try {
      if (!isValidCode()) return;
      setError("");
      setLoading("validate");
      const { bank_account_exists } = await Link529Services.verifyMFA(code);
      setLoading("none");
      handleNext(bank_account_exists);
    } catch (err: any) {
      setLoading("none");
      setError(err);
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    if (code.length > 6 || isNaN(Number(code))) return;
    setCode(code);
  };

  return (
    <Content>
      <MFAContainer spacing={3}>
        <Typography variant="h5" data-cy="link-mfa-title">
          You should have just received a text
        </Typography>
        <VerificationSubtitle>
          Enter the code sent to your phone number from your 529 Account Provider
        </VerificationSubtitle>
        <SingleCharacterInputGroup
          data-cy="link-mfa-code-input"
          length={6}
          error={!!error}
          onChange={handleValueChange}
          value={code}
        />
        <ConfirmButton
          data-cy="link-mfa-button"
          variant="contained"
          onClick={handleVerifyCode}
          disabled={!InputValidator.isValidCode(code)}
        >
          {loading === "validate" ? (
            <CircularProgress size="1.7rem" sx={{ color: "white" }} />
          ) : (
            "Verify code"
          )}
        </ConfirmButton>
        {error && (
          <ErrorText color="error" data-cy="link-mfa-error">
            {error}
          </ErrorText>
        )}
      </MFAContainer>
    </Content>
  );
};

export default LinkMFAVerification;
