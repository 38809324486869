import clsx from "clsx";
import { When } from "react-if";

import { Button } from "@mui/material";

import WarningIcon from "components/Icons/WarningIcon";

type WithdrawalErrorScreenProps = {
  className?: string;
  contactSupport?: boolean;
  title?: string;
  type?: "ERROR" | "WARNING";
  subtitle?: string;
  children: React.ReactNode;
  nextAction: "RESTART" | (string & {});
  handleNext: (action: string) => void;
};

const ErrorScreen = ({
  className,
  children,
  nextAction,
  subtitle,
  title = "An Error Occurred",
  type = "ERROR",
  contactSupport = false,
  handleNext,
}: WithdrawalErrorScreenProps) => {
  return (
    <div className={clsx("flex flex-col gap-6 justify-between items-center grow", className)}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-center gap-9 p-4">
          <h3 className={clsx("text-lg text-center", type === "ERROR" ? "text-error w-20" : "text-warning px-2")}>
            {title}
          </h3>

          <WarningIcon className={clsx("size-28", type === "WARNING" ? "text-warning-icon" : "text-error-icon")} aria-hidden="true" />

          <When condition={!!subtitle && subtitle.length > 0}>
            <h4 className="text-xl text-center">{subtitle}</h4>
          </When>
        </div>

        {children}
      </div>

      <div className="flex flex-col items-center gap-4">
        <When condition={contactSupport}>
          <Button
            variant="outlined"
            id="intercom_support"
            href="https://www.backpack529.com/contact"
            className="rounded-full"
          >
            Contact Support
          </Button>
        </When>

        <Button
          variant="contained"
          className="rounded-full"
          onClick={() => handleNext(nextAction)}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default ErrorScreen;
