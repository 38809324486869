import { useState } from "react";
import { When } from "react-if";

import { Button, Checkbox, CircularProgress } from "@mui/material";

import { formatCurrency } from "utils/formats";

import type { State529Plan } from "@lib/types/state529";

type WithdrawalConfirmationProps = {
  amount: number;
  state529PlanName: State529Plan["name"];
  handleNext: () => void;
};

enum WithdrawalPolicy {
  WITHDRAWAL_POLICY_1 = "WITHDRAWAL_POLICY_1",
  WITHDRAWAL_POLICY_2 = "WITHDRAWAL_POLICY_2",
  WITHDRAWAL_POLICY_3 = "WITHDRAWAL_POLICY_3",
}

const TERMS_OF_SERVICE: Record<keyof typeof WithdrawalPolicy, string> = {
  WITHDRAWAL_POLICY_1:
    "I confirm that I am authorized to make a withdrawal from my linked 529 Account.",
  WITHDRAWAL_POLICY_2:
    "I confirm that I intend to use this withdrawal for Qualified Education Expenses in the same calendar year as I am making this withdrawal.",
  WITHDRAWAL_POLICY_3:
    "I confirm my understanding that this withdrawal will be made pro rata across all portfolios in my 529 Account.",
};

type TermsOfServiceKeys = keyof typeof TERMS_OF_SERVICE;

type EntryOfTermOfService = [TermsOfServiceKeys, string];
const POLICIES: EntryOfTermOfService[] = Object.entries(TERMS_OF_SERVICE) as EntryOfTermOfService[];

const WithdrawalConfirmation = ({
  amount,
  state529PlanName,
  handleNext
}: WithdrawalConfirmationProps) => {
  const [termsOfService, setTermsOfService] = useState<Record<TermsOfServiceKeys, boolean>>({
    WITHDRAWAL_POLICY_1: false,
    WITHDRAWAL_POLICY_2: false,
    WITHDRAWAL_POLICY_3: false,
  });

  const [isPending, setIsPending] = useState(false);

  const handleTermSelection = (policy: TermsOfServiceKeys) => {
    setTermsOfService({
      ...termsOfService,
      [policy]: !termsOfService[policy],
    });
  };

  const handleSubmit = async () => {
    setIsPending(true);
    await handleNext();
    setIsPending(false);
  };

  return (
    <div className="w-80 px-3.5 flex flex-col justify-between gap-4 items-center grow">
      <div>
        <h4 className="text-base my-8">
          Confirm the below details for the withdrawal from your 529 Account
        </h4>

        <dl className="mt-12 text-sm">
          <div className="flex justify-between gap-1 mt-8">
            <dt className="whitespace-nowrap">529 Plan Provider</dt>
            <dd className="font-bold text-right">{state529PlanName}</dd>
          </div>

          <div className="flex justify-between mt-8">
            <dt className="whitespace-nowrap">Amount</dt>
            <dd className="font-bold text-right">{formatCurrency(amount)}</dd>
          </div>
        </dl>
      </div>

      <div className="flex flex-col items-center">
        {POLICIES.map(([policy, text]) => (
          <div key={policy} className="flex items-start mt-8">
            <Checkbox
              id={policy}
              checked={!!termsOfService[policy]}
              onChange={() => handleTermSelection(policy)}
              className="p-0 mt-0.5 mr-4"
              disableRipple
            />
            <label htmlFor={policy} className="text-xxs hover:cursor-pointer">
              {text}
            </label>
          </div>
        ))}

        <p className="text-gray-500 text-justify text-xxxs w-64 mt-10">
          By clicking “Submit”, I authorize Backpack to initiate a withdrawal from my linked 529
          Account
        </p>

        <Button
          variant="contained"
          className="rounded-full mt-6"
          disabled={Object.values(termsOfService).some((policy) => !policy) || isPending}
          onClick={handleSubmit}
        >
          <When condition={isPending}>
            <CircularProgress
              size="1.7rem"
              sx={{ color: "white" }}
              aria-label="Pending"
            />
          </When>

          <span className={isPending ? "sr-only" : ""}>Submit</span>
        </Button>
      </div>
    </div>
  );
};

export default WithdrawalConfirmation;
