import React, { FC, useEffect, useState } from "react";
import { When } from "react-if";

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import MFAVerificationModal from "commons/Modal/MFAVerificationModal";
import Card from "@components/Card";
import BackpackLogo from "assets/images/BackpackLogoHorizontal.svg";

import { useAuth } from "@hooks/useAuth";

import { InputValidator } from "utils/validations";
import { formatPhoneNumber, sanitizeNumber } from "utils/formats";

import ApplicationServices from "services/application.services";
import AuthServices from "services/auth.services";

import type { UUID } from "@lib/types/global";

interface WelcomePropTypes {
  applicationId: UUID;
  defaultTabValue: number;
  handleNext: any;
  handleCreateApplication: (phoneNumber: string) => void;
}

const WelcomeContent = styled(Stack)({
  alignItems: "center",
  margin: "2rem 1.75rem 0 1.75rem",
});

const WelcomeButton = styled(Button)({
  width: "85%",
  margin: "1.75rem auto",
  padding: ".625rem 0",
});

const CheckboxIcon = styled(Checkbox)(({ theme }) => ({
  paddingLeft: 0,
  paddingTop: "8px",
  color: theme.palette.grey[700],
}));

const LoginForm: FC<WelcomePropTypes> = ({
  applicationId,
  defaultTabValue,
  handleNext,
  handleCreateApplication,
}) => {
  const loginTabValue = 0;
  const signUpTabValue = 1;
  const [tabValue, setTabValue] = React.useState<number | null>(defaultTabValue);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const { isAuthed, login, logout } = useAuth();

  // clear user profile storage
  if (sessionStorage.getItem("profile") || sessionStorage.getItem("onboarding")) {
    sessionStorage.clear();
  }

  useEffect(() => {
    if (!isAuthed()) return;

    // make sure a user is actually logged out
    if (isAuthed()) {
      logout(false);
    }
  }, [isAuthed]);

  const handleOpenModal = async () => {
    try {
      if (hasError || !phoneNumber) return;
      setError("");
      setLoading(true);
      if (tabValue === loginTabValue) {
        await AuthServices.requestMFACode(`+1${sanitizeNumber(phoneNumber)}`);
      } else {
        await handleCreateApplication(`+1${sanitizeNumber(phoneNumber)}`);
      }
      setOpen(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err);
    }
  };

  const handleLogin = async (mfa: string) => {
    const results = await AuthServices.login(`+1${sanitizeNumber(phoneNumber)}`, mfa);

    login(results.token);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleVerificationSuccess = async (mfa: string) => {
    await ApplicationServices.getApplicationToken(applicationId, mfa);
    handleNext();
  };

  // @ts-ignore
  const handleTabChange = (event: React.SyntheticEvent, newTabValue: number | null) => {
    setTabValue(newTabValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length > 14) return event.preventDefault();
    setPhoneNumber(formatPhoneNumber(value));
  };

  const onBlur = () => {
    setHasError(!InputValidator.isValidPhoneNumber(phoneNumber));
  };

  const onFocus = () => {
    setHasError(false);
  };

  const isButtonDisabled = () => {
    const isValidInput = hasError || !phoneNumber;
    if (tabValue === loginTabValue) return isValidInput || loading;
    if (tabValue === signUpTabValue) return isValidInput || loading || !checked;
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleOpenModal();
  };

  const changeTabs = (event: React.MouseEvent, tab: "LOGIN" | "SIGN_UP") => {
    event.preventDefault();

    const tabValue = tab === "LOGIN" ? loginTabValue : signUpTabValue;

    setTabValue(tabValue);
    setHasError(false);
    setError("");
  };

  return (
    <>
      <Card containerClassName="max-sm:shadow-none w-[440px]" className="px-10 pt-4 pb-8">
        <div className="flex flex-col justify-center items-center text-center mt-7">
          <img
            alt="Backpack Logo"
            className="mb-7 h-7 w-auto shrink-0"
            src={BackpackLogo}
          />

          <h1 data-cy="welcome-title" className="pb-4 font-bold text-xl">
            {tabValue === loginTabValue ? "Log in to your account" : "Hello! Let's get you started"}
          </h1>

          {/* Toggle b/w Login and Sign up */}
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              data-cy="welcome-login-tab"
              label="Log in"
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="text-sm"
            />
            <Tab
              data-cy="welcome-signup-tab"
              label="Sign up"
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="text-sm"
            />
          </Tabs>

          <WelcomeContent>
            <form id="login-signup-form" data-cy="login-signup-form" onSubmit={handleFormSubmit}>
              <FormControl>
                {/* Phone number */}
                <FormGroup>
                  <TextField
                    inputProps={{ "className": "data-hj-allow" }}
                    sx={{ width: "18rem" }}
                    data-cy="welcome-mobile-input"
                    value={phoneNumber}
                    label="Mobile number"
                    placeholder="Enter mobile number"
                    helperText={hasError ? "Error: Invalid Number" : ""}
                    error={hasError}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <Typography mt={1} variant="legal">
                  We’ll send you a verification code via SMS
                </Typography>

              <WelcomeButton
                form="login-signup-form"
                type="submit"
                variant="contained"
                data-cy="welcome-submit-button"
                onClick={handleFormSubmit}
                disabled={isButtonDisabled()}>
                {loading ? (
                  <CircularProgress size="1.7rem" sx={{ color: "white" }} />
                ) : tabValue === loginTabValue ? (
                  "Log in"
                ) : (
                  "Get Started"
                )}
              </WelcomeButton>
            </FormControl>

            <When condition={!!error}>
              <div className="mb-4 text-error">
                <p>{error}</p>

                <When condition={error === "Application was already completed."}>
                  <p className="text-blue mt-1">
                    Click <a href="#" className="font-bold hover:text-blue-800 underline" onClick={(event) => changeTabs(event, "LOGIN")}>here</a> to sign in.
                  </p>
                </When>
              </div>
            </When>

              {/* Sign up */}

              {tabValue === signUpTabValue && (
                <Stack spacing={1} flexDirection="row" alignItems="flex-start">
                  <CheckboxIcon
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    aria-label="Terms and Policies"
                    aria-describedby="policy-acceptance"
                    data-cy="terms-and-policies-checkbox"
                    disableRipple
                  />
                  <Typography
                    // @ts-ignore
                    onClick={(e) => setChecked((check) => !check)}
                    id="policy-acceptance"
                    data-cy="welcome-disclaimer"
                    variant="legal"
                    sx={{ textAlign: "justify" }}>
                    By checking this box, I agree to receive text messages to the number I have
                    provided for account verification purposes. Message and data rates may apply. I
                    also agree that I have read, understand and consent to Backpack’s{" "}
                    <a
                      href="https://www.backpack529.com/legal/terms-of-service"
                      target="_blank"
                      rel="noreferrer">
                      <b>Terms of Service</b>
                    </a>
                    ,{" "}
                    <a
                      href="https://www.backpack529.com/legal/privacy-policy"
                      target="_blank"
                      rel="noreferrer">
                      <b>Privacy Policy</b>
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.backpack529.com/legal/e-sign-consent-agreement"
                      target="_blank"
                      rel="noreferrer">
                      <b>ESIGN Policy</b>
                    </a>
                    .
                  </Typography>
                </Stack>
              )}
            </form>
          </WelcomeContent>
        </div>
      </Card>

      <MFAVerificationModal
        title={"We just texted you."}
        description={`We sent a 6-digit code to your phone number ending in ${phoneNumber.substring(phoneNumber.length - 4)}`}
        open={open}
        handleCloseModal={handleCloseModal}
        handleNext={tabValue === loginTabValue ? handleLogin : handleVerificationSuccess}
        retry={handleOpenModal}
      />
    </>
  );
};

export default LoginForm;