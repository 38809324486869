import Papa from "papaparse";

type CSVDownloadProps = {
  header: string[];
  data: object[];
}

export function generateCSVDownloadURL({ header, data }: CSVDownloadProps) {
  const csv = Papa.unparse({
    fields: header,
    data
  });

  return "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
}

export default { generateCSVDownloadURL };