import { BASE_ROUTE, RouteType } from "routes/constants";
import type { SearchParams } from "@lib/types/global";

export type RouterBuilderProps = {
  step: string | null;
  nestedStep?: string | null;
  routeType?: typeof RouteType[keyof typeof RouteType];
  searchParams?: SearchParams | null;
}

type ParsePathtoStepsProps = {
  path: string;
  routeType: typeof RouteType[keyof typeof RouteType];
}

const BASE_URL = import.meta.env.VITE_APP_BASE_URL;

export const parsePathtoSteps = ({ path, routeType }: ParsePathtoStepsProps) => {
  const url = new URL(path, BASE_URL);
  const pathNames = url.pathname.split("/");
  const routeIndex = pathNames.findIndex((part) => part === routeType);

  const allSteps = routeIndex !== -1
    ? pathNames.slice(routeIndex + 1)
    : [];

  const step = allSteps[0] || null;
  const nestedStep = allSteps[1] || null;

  const searchParams = url.searchParams.size >= 1
    ? Object.fromEntries(url.searchParams.entries())
    : null;

  return {
    step,
    nestedStep,
    searchParams
  };
};

export const buildSearchParams = (
  url: string,
  params: SearchParams
) => {
  const newUrl = new URL(url);

  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      newUrl.searchParams.set(key, value.toString());
    }
  });

  return Object.fromEntries(newUrl.searchParams.entries());
};

export const routeBuilder = ({
  searchParams,
  step,
  nestedStep = null,
  routeType = RouteType.DASHBOARD
}: RouterBuilderProps) => {
  let path = BASE_ROUTE[routeType];

  if (!step || step === undefined || step === "undefined") return path;

  if (step) {
    path += `/${step}`;
  }

  if (nestedStep) {
    path += `/${nestedStep}`;
  }

  if (searchParams) {
    const search = new URLSearchParams(searchParams).toString();
    path += search ? `?${search}` : "";
  }

  return path;
};