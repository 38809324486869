import React from "react";

import { classnames } from "@utils/classnames";

/**
 * Props for the InputDescription component.
 *
 * @property {string} id - This id is used with "aria-describedby" on an input / form element
 * @property {string} children - The description text to be displayed. Must be a string.
 * @property {boolean} [error=false] - Indicates whether the description is an error message and shows error styles.
 * @property {boolean} [helper=true] - Indicates whether the description is a helper text. Defaults to true.
 */
type InputDescriptionProps = {
  children: string;
  id: string;
  error?: boolean;
  helper?: boolean;
}

/**
 * A memoized component that displays a description to be used as helper text or an error message for inputs and form elements.
 *
 * This component conditionally applies styles based on the `error` and `helper` props.
 *
 * @param {InputDescriptionProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * // Example of error message
 * <InputDescription
 *   error
 *   id="username-error"
 * >
 *  Username must be at least 6 characters long.
 * </InputDescription>
 */
const InputDescription = React.memo(function InputDescription({
  children,
  id,
  error = false,
  helper = true
}: InputDescriptionProps) {
  const memoizedClassName = React.useMemo(() => {
    return classnames("input-description", error && "text-error");
  }, [error, helper]);

  return (
    <p
      data-testid="input-description-component"
      id={id}
      className={memoizedClassName}
    >
      {children}
    </p>
  );
});

export default InputDescription;