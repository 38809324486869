import ContactSupport from "commons/Error/ContactSupport";
import { Stack } from "@mui/material";
import Card from "commons/Card/Vertical";

interface DefaultFallBackRenderProps {
  error: Error;
  resetErrorBoundary: () => void;
}

// @ts-expect-error
const defaultFallbackRender = ({ error, resetErrorBoundary }: DefaultFallBackRenderProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Card goBack={null}>
        <ContactSupport title="Unexpected Error" link="https://www.backpack529.com/contact" />
      </Card>
    </Stack>
  );
};

export default defaultFallbackRender;
