import { useMemo } from "react";

import { IconButton } from "@mui/material";
import CheckCircleIcon from "@components/Icons/CheckCircleIcon";
import WarningIcon from "@components/Icons/WarningIcon";
import XCircleIcon from "@components/Icons/XCircleIcon";
import InformationCircleIcon from "@components/Icons/InformationCircleIcon";
import XMarkIcon from "@components/Icons/XMarkIcon";

import { classnames } from "@lib/utils/classnames";

import type { Notification, NotificationOptions } from "@lib/types/notification";


type NotificationMessage = Notification & {
  type: NotificationOptions["type"];
  onClose?: () => void;
}

const NotificationMessage = ({
  message,
  type,
  onClose,
}: NotificationMessage) => {
  const iconColor = useMemo(() => {
    switch (type) {
      case "success":
        return "text-success-icon";

      case "warning":
        return "text-warning-icon";

      case "error":
        return "text-error-icon";

      case "info":
        return "text-blue-400";

      default:
        return "text-primary";
    }
  }, [type]);

  const iconClassNames = classnames("shrink-0 size-6", iconColor);

  const icon = useMemo(() => {
    switch (type) {
      case "success":
        return <CheckCircleIcon aria-hidden="true" className={iconClassNames} outline />;

      case "warning":
        return <WarningIcon aria-hidden="true" className={iconClassNames} />;

      case "error":
        return <XCircleIcon aria-hidden="true" className={iconClassNames} />;

      case "info":
      default:
        return <InformationCircleIcon aria-hidden="true" className={iconClassNames} />;
    }
  }, [type]);

  return (
    <div className="p-4 flex justify-between items-center gap-4">
      <div className="flex items-center gap-3">
        <>
          {icon}
        </>

        <p className="text-md font-medium text-primary">
          {message}
        </p>
      </div>

      {onClose &&
      <div className="shrink-0">
        <IconButton
          data-testid="notification-close-btn"
          type="button"
          className="inline-flex rounded-md icon-button focus:ring-offset-2"
          onClick={() => onClose()}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon aria-hidden="true" className="size-5" />
        </IconButton>
      </div>
      }
    </div>
  );
};

export default NotificationMessage;