import { create } from "@stores/utils";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  Flow,
  OnboardingSteps,
  TuitionPaymentSteps,
  ManualLink529Steps
} from "@lib/enums/flows";
import { ProfileType } from "@lib/enums/userProfile";

import { checkProfileStatus } from "@helpers/checkProfileStatus";

import { useUserProfileStore } from "@stores/userProfileStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";
import { useLink529Store } from "@stores/link529Store";

import type { ProgressStep } from "@components/Stepper/ProgressStepper";

export type OnboardingStep = {
  name: OnboardingSteps;
} & Partial<ProgressStep>;

type OnboardingStepperVariants = {
  [key: string]: {
    [key: string]: Partial<Pick<ProgressStep, "title" | "description">>;
  };
};

type OnboardingStatus = {
  [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.SKIPPED | TuitionPaymentSteps.CREATED | null;
  [Flow.LINK_529]: ManualLink529Steps.MANUAL | ManualLink529Steps.SKIPPED | null;
}

type OnboardingState = {
  step: OnboardingStep | Pick<OnboardingStep, "name"> | null;
  stepCount: number;
  steps: OnboardingStep[];
  nestedFlow: Flow | null;
  status: OnboardingStatus;
};

type OnboardingAction = {
  initializeOnboarding: (redirectURL?: string) => void;
  goToNextStep: () => void;
  goToPayTuition: () => void;
  goToLink529Manual: () => void;
  skipTuitionPayment: () => void;
  skipManualLinking: () => void;
  setNestedFlow: (nestedFlow: OnboardingState["nestedFlow"]) => void;
  goToFinalScreen: () => void;
  clearStorage: () => void;
};

const ONBOARDING_STEP = {
  [OnboardingSteps.WELCOME]: {
    step: 1,
    name: OnboardingSteps.WELCOME,
    title: "Welcome to Backpack!",
    description: "Get up and running in 3 mins",
  },
  [OnboardingSteps.STUDENT_DETAILS]: {
    step: 2,
    name: OnboardingSteps.STUDENT_DETAILS,
    title: "Add student details",
    description: "Enter your 529's beneficiary details",
  },
  [OnboardingSteps.CONFIRM_ATTENDANCE]: {
    step: 3,
    name: OnboardingSteps.CONFIRM_ATTENDANCE,
    title: "Confirm attendance",
    description: "Let your University know that you are paying with your 529",
  },
  [OnboardingSteps.LINK_529]: {
    step: 4,
    name: OnboardingSteps.LINK_529,
    title: "Complete your payment",
    description: "Withdraw 529 funds to Backpack",
  },
  [OnboardingSteps.FINAL_SCREEN]: {
    name: OnboardingSteps.FINAL_SCREEN,
  },
  [OnboardingSteps.DONE]: {
    name: OnboardingSteps.DONE
  }
};

const ONBOARDING_DESCRIPTION_VARIANT: OnboardingStepperVariants = {
  [OnboardingSteps.STUDENT_DETAILS]: {
    completed: {
      description: "Beneficiary successfully added"
    },
  },
  [OnboardingSteps.CONFIRM_ATTENDANCE]: {
    created: {
      description: "Tuition payment setup",
    },
    skipped: {
      description: "Skipped for now"
    },
  },
  [OnboardingSteps.LINK_529]: {
    skipped: {
      title: "Link your 529 to Backpack",
      description: "Connect your 529 for seamless payments",
    },
    skipped_linking: {
      description: "529 Linking skipped for now"
    },
    completed: {
      description: "529 Linked and awaiting withdrawal"
    }
  }
};

const DEFAULT_STEPS = [
  ONBOARDING_STEP[OnboardingSteps.WELCOME],
  ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS],
  ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE],
  ONBOARDING_STEP[OnboardingSteps.LINK_529],
];

const TUITION_PAYMENT_CREATED_STEPS = [
  ONBOARDING_STEP[OnboardingSteps.WELCOME],
  {
    ...ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS],
    description: ONBOARDING_DESCRIPTION_VARIANT[OnboardingSteps.STUDENT_DETAILS].completed.description,
  },
  {
    ...ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE],
    description: ONBOARDING_DESCRIPTION_VARIANT[OnboardingSteps.CONFIRM_ATTENDANCE].created.description,
  },
  ONBOARDING_STEP[OnboardingSteps.LINK_529]
];

const DEFAULT_INITIAL_STATE = {
  step: null,
  stepCount: 0,
  steps: [...DEFAULT_STEPS],
  nestedFlow: null,
  status: {
    [Flow.TUITION_PAYMENT]: null,
    [Flow.LINK_529]: null,
  }
};

/**
 *
 * @param steps the current progress stepper steps
 * @param step the step description to update
 * @param variant if there is a variant description to use, otherwise default is completed
 * @returns updated steps to use with progress stepper
 */
const updateProgressStepper = ({ steps, step, variant = "completed" }: {
  steps: OnboardingStep[];
  step: OnboardingSteps;
  variant?: "created" | "completed" | "skipped" | "skipped_linking";
}): OnboardingStep[] => {
  return steps.map((onboardingStep) => {
    if (onboardingStep.name !== step) return onboardingStep;

    const updatedStep = { ...onboardingStep };

    const title = ONBOARDING_DESCRIPTION_VARIANT[step][variant].title;
    const description = ONBOARDING_DESCRIPTION_VARIANT[step][variant].description;

    if (title) updatedStep.title = title;
    if (description) updatedStep.description = description;

    return updatedStep;
  });
};

export const useOnboardingStore = create<OnboardingState & OnboardingAction>()(
  persist(
    (set, get, api) => ({
      ...DEFAULT_INITIAL_STATE,

      initializeOnboarding: (redirectURL) => {
        const profile = useUserProfileStore.getState().profiles.onboarding;
        const status = get().status;
        let redirectStep: (OnboardingSteps | null) = null;

        if (redirectURL) {
          const redirectPathNames = redirectURL.split("/");
          redirectStep = redirectPathNames[redirectPathNames.length - 1] as OnboardingSteps;
        }

        const {
          hasBeneficiary,
          hasInstitutionPayments,
          hasTransactions,
          hasLinked529,
          hasSkippedTuitionPayment
        } = profile;

        // set the default currentStep and stepCount
        // to the Welcome screen (first step)
        // unless the redirect is to student_details
        let step: OnboardingState["step"] = ONBOARDING_STEP[OnboardingSteps.WELCOME];
        let stepCount = ONBOARDING_STEP[OnboardingSteps.WELCOME].step;
        let updatedSteps: OnboardingStep[] = [...DEFAULT_STEPS];
        let updatedStatus = { ...status };

        // update the step if the redirect step is to student_details
        if (redirectStep === OnboardingSteps.STUDENT_DETAILS) {
          step = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS];
          stepCount = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS].step;
        }

        // if a user has a beneficiary
        // but has no transactions and no pending institution payments
        // show the confirm attendance screen
        if (hasBeneficiary
          && !hasTransactions
          && !hasInstitutionPayments
          && !hasLinked529
          && !hasSkippedTuitionPayment
        ) {
          step = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE];

          stepCount = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step;

          updatedSteps = updateProgressStepper({
            steps: DEFAULT_STEPS,
            step: OnboardingSteps.STUDENT_DETAILS
          }) as OnboardingStep[];
        }

        if (hasBeneficiary
          && hasInstitutionPayments
          && !hasTransactions
          && !hasLinked529
        ) {
          step = ONBOARDING_STEP[OnboardingSteps.LINK_529];
          stepCount = ONBOARDING_STEP[OnboardingSteps.LINK_529].step;
          updatedSteps = [...TUITION_PAYMENT_CREATED_STEPS];

          updatedStatus = {
            ...status,
            [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED,
          };
        }

        // if everything inside the onboarding profile is true
        // or if the user has transactions or institutions payments
        // then set the step to null aka do not show onboarding
        if (checkProfileStatus(ProfileType.ONBOARDING, profile) || hasSkippedTuitionPayment) {
          step = null;
          stepCount = 0;
        }

        set({
          stepCount,
          step,
          steps: updatedSteps,
          status: updatedStatus,
        });
      },

      goToNextStep: () => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const userProfileStore = useUserProfileStore.getState();

        const currentStep: OnboardingState["step"] = get().step;
        const steps = get().steps;
        const status = get().status;

        let nextStep = ONBOARDING_STEP[OnboardingSteps.DONE];
        let stepCount = steps.length + 1;
        let updatedSteps = [...steps];
        let updatedStatus = { ...status };

        switch (currentStep?.name) {
          case OnboardingSteps.WELCOME:
          default:
            nextStep = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS];
            stepCount = ONBOARDING_STEP[OnboardingSteps.STUDENT_DETAILS].step;
            break;

          case OnboardingSteps.STUDENT_DETAILS:
            nextStep = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE];

            stepCount = ONBOARDING_STEP[OnboardingSteps.CONFIRM_ATTENDANCE].step;

            updatedSteps = updateProgressStepper({
              steps,
              step: OnboardingSteps.STUDENT_DETAILS
            }) as OnboardingStep[];

            break;

          case OnboardingSteps.CONFIRM_ATTENDANCE:
            // reset tuition payment store to default state
            tuitionPaymentStore.reset();

            nextStep = ONBOARDING_STEP[OnboardingSteps.LINK_529];

            stepCount = ONBOARDING_STEP[OnboardingSteps.LINK_529].step;

            updatedSteps = updateProgressStepper({
              steps,
              step: OnboardingSteps.CONFIRM_ATTENDANCE,
              variant: "created"
            }) as OnboardingStep[];

            updatedStatus = {
              ...status,
              [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.CREATED,
            };

            break;

          case OnboardingSteps.LINK_529:
            userProfileStore.updateProfileStatus({ onboarding: true });

            nextStep = ONBOARDING_STEP[OnboardingSteps.DONE];
            stepCount = steps.length + 1;
            break;
        }

        set({
          stepCount,
          step: nextStep,
          steps: updatedSteps,
          status: updatedStatus,
          nestedFlow: null,
        });
      },

      goToPayTuition: () => {
        set({ nestedFlow: Flow.TUITION_PAYMENT });
      },

      goToLink529Manual: () => {
        set((state) => ({
          nestedFlow: Flow.LINK_529,
          status: {
            ...state.status,
            [Flow.LINK_529]: ManualLink529Steps.MANUAL
          }
        }));
      },

      skipTuitionPayment: () => {
        const tuitionPaymentStore = useTuitionPaymentsStore.getState();
        const steps = get().steps;

        const updatedSteps = updateProgressStepper({
          steps,
          step: OnboardingSteps.CONFIRM_ATTENDANCE,
          variant: "skipped"
        });

        // reset tuition payment store to default state
        tuitionPaymentStore.reset();

        set((state) => ({
          step: ONBOARDING_STEP[OnboardingSteps.LINK_529],
          stepCount: ONBOARDING_STEP[OnboardingSteps.LINK_529].step,
          steps: updateProgressStepper({
            steps: updatedSteps,
            step: OnboardingSteps.LINK_529,
            variant: "skipped"
          }),
          status: {
            ...state.status,
            [Flow.TUITION_PAYMENT]: TuitionPaymentSteps.SKIPPED
          },
          nestedFlow: null,
        }));
      },

      skipManualLinking: () => {
        const link529Store = useLink529Store.getState();
        const steps = get().steps;

        // reset link 529 store to default state
        link529Store.reset();

        set((state) => ({
          step: ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN],
          stepCount: steps.length + 1,
          steps: updateProgressStepper({
            steps,
            step: OnboardingSteps.LINK_529,
            variant: "skipped_linking"
          }),
          status: {
            ...state.status,
            [Flow.LINK_529]: ManualLink529Steps.SKIPPED
          },
          nestedFlow: null,
        }));
      },

      setNestedFlow: (nestedFlow) => {
        set({ nestedFlow });
      },

      goToFinalScreen: () => {
        const steps = get().steps;
        let updatedSteps = [...steps];

        updatedSteps = updateProgressStepper({
            steps,
            step: OnboardingSteps.LINK_529,
            variant: "completed"
          }) as OnboardingStep[];

        set((state) => ({
          ...state,
          stepCount: steps.length + 1,
          step: ONBOARDING_STEP[OnboardingSteps.FINAL_SCREEN],
          steps: updatedSteps,
          status: {
            ...state.status,
            [Flow.LINK_529]: null,
          },
          nestedFlow: null,
        }));
      },

      clearStorage: () => {
        api.persist.clearStorage();
      }
    }),
    {
      name: "onboarding",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);