import CardLoadingSkeleton from "@components/LoadingSkeleton/CardLoadingSkeleton";
import TransactionLoadingSkeleton from "@components/LoadingSkeleton/TransactionLoadingSkeleton";

const DashboardLoadingSkeleton = () => {
  return (
    <div className="flex flex-col lg:flex-row size-full max-w-7xl xl:mx-auto gap-4 xl:gap-8 p-6 xl:p-8 overflow-y-scroll bg-white">
      <h1 className="sr-only">Dashboard</h1>

      <main className="flex flex-col flex-1 gap-6 min-w-0 h-fit pb-8">
        <CardLoadingSkeleton />

        <CardLoadingSkeleton />

        <TransactionLoadingSkeleton />
      </main>

      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default DashboardLoadingSkeleton;