import { useMemo } from "react";
import { classnames } from "@utils/classnames";

type CardProps = {
  /**
   * Can be used to provide a label for screen readers when a card content's cannot be read properly by the screen reader
   */
  ariaLabel?: string;
  /**
   * Can be used provide a label to screen reader when a card content's cannot be read properly by the screen reader
   * This needs to be the ID of the label
   */
  ariaLabelledby?: string;
  /**
   * Overrides card body classes
   */
  className?: string;
  /**
   * Overrides container element classes
   */
  containerClassName?: string;
  /**
   * Enables default card styles for border-radius and background-color for the card container
   *
   * Default is true
   */
  defaultCard?: boolean;
  children: React.ReactNode;
}

const Card = ({
  ariaLabel,
  ariaLabelledby,
  className,
  containerClassName,
  children,
  defaultCard = true
}: CardProps) => {
  const cardContainerClassName = useMemo(() => {
    return classnames(defaultCard ? "card" : "card-base", containerClassName);
  }, [defaultCard, containerClassName]);

  const cardBodyClassName = useMemo(() => {
    return classnames("card-body", className);
  }, [className]);

  return (
    <div
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      className={cardContainerClassName}
    >
      <div className={cardBodyClassName}>
        {children}
      </div>
    </div>
  );
};

export default Card;
