import instance from "config/fetch.config";
import { QueryError } from "@utils/errors";

import type { UUID } from "@lib/types/global";

export const getApplicationNextStep = async (applicationId: UUID) => {
  try {
    const { data: { data } } = await instance.get(`/applications/${applicationId}/next-step`);

    return data;
  } catch (error: any) {
    throw new QueryError(error.response.data);
  }
};