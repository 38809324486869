/**
 * Enum for transaction type
 *
 * DEPOSIT, WITHDRAWAL, HOLD, HOLD_RELEASE
 */
export enum TransactionType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
  HOLD = "HOLD",
  HOLD_RELEASE = "HOLD_RELEASE",
}

/**
 * Enum for transaction payment status
 *
 * DONE, FAILED, PENDING, RETURNED, CANCELLED, PROCESSING, ERROR, SENT
 */
export enum TransactionStatus {
  DONE = "DONE",
  FAILED = "FAILED",
  PENDING = "PENDING",
  RETURNED = "RETURNED",
  CANCELLED = "CANCELLED",
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
  SENT = "SENT"
}

/**
 * Enum for transaction method
 *
 * ACH, BANK, BOOK, CARD, CHECK, WIRE
 */
export enum TransactionMethod {
  ACH = "ACH",
  BANK = "BANK",
  BOOK = "BOOK",
  CARD = "CARD",
  CHECK = "CHECK",
  WIRE = "WIRE"
}