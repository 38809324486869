import Badge from "@components/Badge/Badge";
import { CHIP_BUTTON_COLOR, CHIP_BUTTON_ICON_COLOR } from "@components/Chip/constants";
import XMarkIcon from "@components/Icons/XMarkIcon";

import type { Color } from "@lib/types/theme";
import { classnames } from "@lib/utils/classnames";

type ChipProps = {
  children: React.ReactNode;
  buttonLabel: string;
  "aria-description"?: string;
  color?: Color;
  onRemoveChip: () => void;
}

const Chip = ({
  children,
  buttonLabel,
  "aria-description": ariaDescription,
  color = "gray",
  onRemoveChip
}: ChipProps) => {
  return (
    <Badge
      primary
      className="text-sm py-1.5 px-2.5"
      color={color}
      aria-description={ariaDescription}
    >
      {children}
      <button
        type="button"
        className={classnames(
          "group relative size-4 rounded-sm",
          CHIP_BUTTON_COLOR[color]
        )}
        data-testid="chip-remove-button"
        onClick={onRemoveChip}
      >
        <span className="sr-only">{buttonLabel}</span>

        <XMarkIcon
          aria-hidden="true"
          className={classnames("size-4", CHIP_BUTTON_ICON_COLOR[color])}
        />
      </button>
    </Badge>
  );
};

export default Chip;