import NotificationMessage from "@components/Notification/NotificationMessage";
import { Transition } from "@headlessui/react";

import { useNotificationStore } from "@stores/notificationStore";

import { classnames } from "@lib/utils/classnames";

const NotificationContainer = () => {
  const hasNotification = useNotificationStore((state) => !!Object.keys(state.notification).length);
  const message = useNotificationStore((state) => state.notification.message);
  const position = useNotificationStore((state) => state.notification.position);
  const type = useNotificationStore((state) => state.notification.type);
  const clearNotification = useNotificationStore((state) => state.clearNotification);

  if (!hasNotification) return null;

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex px-4 py-6 items-start sm:p-6 z-[1000]"
      data-testid="notification"
    >
      <div className={classnames(
        "flex w-full flex-col space-y-4 items-center", {
          "sm:items-end": position === "right",
          "sm:items-center": position === "center",
          "sm:items-start": position === "left"
        }
      )}>
        <Transition show={!!message}>
          <div className="notification-card transitions-notification">
            <NotificationMessage
              message={message}
              type={type}
              onClose={() => clearNotification()}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default NotificationContainer;