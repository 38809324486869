import React from "react";

import Modal from "commons/Modal";
import LinkList from "components/LinkList";
import Detail from "components/Settings/DetailBlock";
import Spinner from "@components/Spinner/Spinner";
import Legal from "components/Settings/LegalAgreement";
import AccountInformation from "components/Settings/AccountInformation";
import Statements from "components/Settings/Statements";
import { Links } from "components/LinkList";

import AccountServices from "services/account.services";
import BeneficiaryServices from "services/beneficiaries.services";
import ApplicationServices from "services/application.services";

import { useAuth } from "@hooks/useAuth";

import type { AccountOwner, BankAccount } from "@lib/types/account";
import type { BeneficiaryResponse } from "@lib/types/beneficiary";
import type { Institution } from "@lib/types/institution";
import type { State529Plan } from "@lib/types/state529";

import { formatAccount, formatBeneficiary } from "./utils";

type screenOptions = "account" | "statements" | "legal" | null;

function Settings() {
  const { logout } = useAuth();

  const [accountOwner, setAccountOwner] = React.useState<AccountOwner | null>(null);
  const [bankAccount, setBankAccount] = React.useState<BankAccount>({} as BankAccount);
  const [beneficiary, setBeneficiary] = React.useState<BeneficiaryResponse | null>(null);
  const [institutions, setInstitutions] = React.useState<Institution[] | null>(null);
  const [plans, setPlans] = React.useState<State529Plan[] | null>(null);
  const [state, setState] = React.useState("initial");
  const [screen, setScreen] = React.useState<screenOptions>(null);
  const [error, setError] = React.useState<Error>();

  const formatedAccount = React.useMemo(
    () => (accountOwner ? formatAccount(accountOwner) : []),
    [accountOwner],
  );

  const formatedBeneficiary = React.useMemo(() => {
    if (beneficiary && institutions && plans) {
      return formatBeneficiary({ beneficiary, institutions, plans });
    }
    return [];
  }, [beneficiary, institutions, plans]);

  const initializeSettingsPage = async () => {
    setState("loading");
    try {
      const accountPromise = AccountServices.getAccountOwner().then(setAccountOwner);
      const bankPromise = AccountServices.getAccountOwnerBankAccounts().then(setBankAccount);
      const beneficiaryPromise = BeneficiaryServices.getBeneficiaries().then((beneficiary) =>
        setBeneficiary(beneficiary[0]),
      );
      const institutionPromise = ApplicationServices.getInstitutions().then(setInstitutions);
      const plansPromise = ApplicationServices.get529Plans().then(setPlans);

      await Promise.all([
        accountPromise,
        bankPromise,
        beneficiaryPromise,
        institutionPromise,
        plansPromise,
      ]);

      setState("success");
    } catch (error: any) {
      setError(error);
      setState("error");
    }
  };

  // Get account owner and beneficiary details
  React.useEffect(() => {
    if (!accountOwner) {
      initializeSettingsPage();
    }
  }, []);

  const handleClose = () => {
    setScreen(null);
  };

  const openScreen = (screen: screenOptions) => {
    setScreen(screen);
  };

  const viewMap = {
    account: <AccountInformation handleClose={handleClose} account={bankAccount} />,
    statements: <Statements handleClose={handleClose} bankAccount={bankAccount} />,
    legal: <Legal handleClose={handleClose} />,
  };

  const links: Links = [
    { label: "Account and Routing Number", screen: "account" },
    { label: "Statements", screen: "statements" },
    { label: "Legal Agreements", screen: "legal" },
  ];

  if (state === "error") {
    if (error?.message === "ACCESS_DENIED_EXCEPTION") {
      logout(true);
    }
  }

  if (state !== "success") return <Spinner fullScreen />;

  return (
    <>
      <div className="size-full overflow-y-scroll pb-20 md:pb-8">
        <h1 className="sr-only">Settings</h1>

        <main className="flex flex-col w-full max-w-7xl xl:mx-auto gap-8 md:gap-12 p-6 pb-8 xl:p-8">
          <div className="flex max-md:flex-col justify-between gap-6 md:gap-8">
            <Detail title="Account Details" properties={formatedAccount} />

            <Detail title="Beneficiary Details" properties={formatedBeneficiary} />
          </div>

          <LinkList openScreen={openScreen} links={links} />
        </main>
      </div>

      <Modal open={!!screen} handleClose={handleClose}>
        {screen && viewMap[screen]}
      </Modal>
    </>
  );
}

export default Settings;