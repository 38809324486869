import { Case, Switch } from "react-if";
import { useShallow } from "zustand/react/shallow";

import ModalDialog from "@components/Modal/ModalDialog";
import Button from "@components/Button/Button";
import LinkYour529Screen from "@components/Link529/Link529Manual/screens/LinkYour529Screen";
import AccountVerificationScreen from "@components/Link529/Link529Manual/screens/AccountVerificationScreen";
import InitiateWithdrawalScreen from "@components/Link529/Link529Manual/screens/InitiateWithdrawalScreen";

import { ManualLink529Steps } from "@lib/enums/flows";
import { ProfileType } from "@lib/enums/userProfile";

import { useLink529Store } from "@stores/link529Store";
import { useOnboardingStore } from "@stores/onboardingStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useCurrentUserStore } from "@stores/currentUserStore";
import { Suspense, useEffect } from "react";
import Spinner from "@components/Spinner/Spinner";

const Link529Manual = () => {
  const beneficiary = useCurrentUserStore(useShallow((state) => state.currentUser.beneficiaries[0]));

  const screen = useLink529Store(useShallow((state) => state.screen.current));
  const currentStep = useLink529Store(useShallow((state) => state.step.current));
  const planType = useLink529Store(useShallow((state) => state.planType));

  const updateProfile = useUserProfileStore((state) => state.updateProfile);

  const initializeLink529Store = useLink529Store((state) => state.initializeLink529Store);
  const goToPrevStep = useLink529Store((state) => state.goToPrevStep);
  const skipManualLinking = useOnboardingStore((state) => state.skipManualLinking);

  const reset = useLink529Store((state) => state.reset);
  const setNestedFlow = useOnboardingStore((state) => state.setNestedFlow);

  const handleSkip = () => {
    updateProfile({
      type: ProfileType.ONBOARDING,
      update: { hasSkippedManualLinking: true }
    });

    skipManualLinking();
  };

  useEffect(() => {
    if (!beneficiary.id || planType) return;

    initializeLink529Store(beneficiary.state_529_plan_id);
  }, [beneficiary]);

  useEffect(() => {
    if (currentStep) return;

    if (!currentStep) {
      reset();
      setNestedFlow(null);
    }
  }, [currentStep]);

  return (
    <Suspense fallback={<Spinner size="lg"/>}>
      <Switch>
        <Case condition={screen === ManualLink529Steps.MANUAL}>
          <LinkYour529Screen />
        </Case>

        <Case condition={screen === ManualLink529Steps.VERIFICATION}>
          <AccountVerificationScreen />
        </Case>

        <Case condition={screen === ManualLink529Steps.WITHDRAWAL}>
          <InitiateWithdrawalScreen />
        </Case>
      </Switch>

      <ModalDialog
        open={currentStep === ManualLink529Steps.SKIPPED}
        onClose={goToPrevStep}
      >
        <h3 className="text-xl font-montserrat font-bold">Linked your 529 yet?</h3>

        <p className="mt-4 text-base">Some 529 plans have a hold period before funds can be withdrawn to new accounts, so linking early helps avoid tuition payment delays.</p>

        <div className="mt-4">
          <Button
            primary
            rounded
            fullWidth
            data-testid="modal-dialog-skip-button"
            onClick={goToPrevStep}
          >
            Link 529 now
          </Button>

          <Button
            text
            rounded
            size="sm"
            className="mt-1.5 font-medium"
            data-testid="modal-dialog-go-back-button"
            onClick={handleSkip}
          >
            No, Skip
          </Button>
        </div>
      </ModalDialog>
    </Suspense>
  );
};

export default Link529Manual;