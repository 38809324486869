import { When } from "react-if";

import InputDescription from "@components/Input/InputDescription";

import { INPUT_TYPE, useInputAttributes } from "@hooks/useInputAttributes";

import { classnames } from "@utils/classnames";

export type SelectOption = {
  label: string;
  value: string;
}

type SelectProps = {
  id: string;
  label: string;
  options: SelectOption[];
  selected?: string;
  name?: string;
  error?: string;
  helper?: string;
  hideLabel?: boolean;
  placeholder?: string;
} & React.InputHTMLAttributes<HTMLSelectElement>;

const Select = ({
  id,
  label,
  name,
  options,
  placeholder,
  selected,
  error,
  helper,
  className,
  hideLabel = false,
  ...selectAttrs
}: SelectProps) => {
  const {
    labelClassName,
    inputClassName,
    ariaDescribedBy,
    description
  } = useInputAttributes({
    id,
    error,
    helper,
    hideLabel,
    type: INPUT_TYPE.SELECT
  });

  return (
    <div>
      <label
        className={labelClassName}
        data-testid="select-component-label"
        htmlFor={id}
      >
        {label}
      </label>

      <select
        data-testid="select-component"
        id={id}
        name={name || id}
        className={classnames(inputClassName, className)}
        value={selected}
        {...selectAttrs}
      >
        {!!placeholder &&
          <option
            disabled
            hidden
            value=""
            data-testid="select-component-option-placeholder"
          >
            {placeholder}
          </option>
        }

        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            data-testid="select-component-option"
          >
            {option.label}
          </option>
        ))}
      </select>

      <When condition={!!helper || !!error}>
        <InputDescription
          id={ariaDescribedBy || ""}
          helper={!!helper}
          error={!!error}
        >
          {description}
        </InputDescription>
      </When>
    </div>
  );
};

export default Select;