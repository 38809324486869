import { create } from "@stores/utils";

import { DEFAULT_CURRENT_USER } from "@lib/constants/currentUser";

import type { CurrentUser } from "@lib/types/currentUser";
import type { Beneficiary, Enrollment } from "@lib/types/beneficiary";
import type { State529Connection } from "@lib/types/state529";

type CurrentUserState = {
  currentUser: CurrentUser;
};

type UpdateBeneficiaryEnrollment = {
  beneficiary_id: Beneficiary["id"];
  enrollment: Enrollment;
}

type UpdateState529Connection = {
  beneficiary_id: Beneficiary["id"];
  state_529_connection: State529Connection;
}

type CurrentUserAction = {
  setCurrentUser: (currentUser: CurrentUser) => void;
  setBeneficiaries: (beneficiaries: Beneficiary[]) => void;
  updateBeneficiaryEnrollment: (update: UpdateBeneficiaryEnrollment) => void;
  updateBeneficiary529Connection: (update: UpdateState529Connection) => void;
};

export const useCurrentUserStore = create<CurrentUserState & CurrentUserAction>()((set, get) => ({
  currentUser: { ...DEFAULT_CURRENT_USER },

  setCurrentUser: (initialCurrentUser) => {
    set((state) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        ...initialCurrentUser
      }
    }));
  },

  setBeneficiaries: (beneficiaries) => {
    set((state) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        beneficiaries
      }
    }));
  },

  updateBeneficiaryEnrollment: (payload) => {
    const beneficiaries = get().currentUser.beneficiaries;

    const updatedBeneficiaries = beneficiaries.map((beneficiary) => {
      if (beneficiary.id !== payload.beneficiary_id) {
        return beneficiary;
      }

      const updatedEnrollments = beneficiary.enrollments.map((enrollment) => {
        if (enrollment.id !== payload.enrollment.id) {
          return enrollment;
        }

        const {
          id,
          status,
          email,
          student_id,
          institution_id
        } = payload.enrollment;

        return {
          id,
          status,
          email,
          student_id,
          institution_id
        };
      });

      return {
        ...beneficiary,
        enrollments: updatedEnrollments
      };
    });

    set((state) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        beneficiaries: updatedBeneficiaries
      }
    }));
  },

  updateBeneficiary529Connection: (payload) => {
    const beneficiaries = get().currentUser.beneficiaries;

    const updatedBeneficiaries = beneficiaries.map((beneficiary) => {
      if (beneficiary.id !== payload.beneficiary_id) return beneficiary;

      const updatedBeneficiary = {
        ...beneficiary,
        state_529_plan: {
          ...beneficiary.state_529_plan,
          state_529_connection: payload.state_529_connection
        }
      };

      return updatedBeneficiary;
    });

    set((state) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        beneficiaries: updatedBeneficiaries
      }
    }));
  },
}));