import * as Sentry from "@sentry/react";
import instance from "config/fetch.config";

import { WithdrawalStatus } from "@lib/enums/withdrawal";

import type { UUID } from "@lib/types/global";
import type { State529Plan } from "@lib/types/state529";
import type { Beneficiary } from "@lib/types/beneficiary";

import { errorMapper } from "utils/errors";

type CreateWithdrawalRequest = {
  state529PlanId: State529Plan["id"];
  payload: {
    amount_in_cents: number;
    beneficiary_id: Beneficiary["id"];
  }
}

type SubmitWithdrawalRequest = {
  withdrawalRequestId: UUID,
  state529PlanId: State529Plan["id"],
  payload: { mfa_code: string },
}

class WithdrawalServices {
  static async createWithdrawalRequest({
    state529PlanId,
    payload
  }: CreateWithdrawalRequest): Promise<{
    id: UUID;
    status: WithdrawalStatus;
  }> {
    try {
      const {
        data: { data },
      } = await instance.post(
        `/state-529-connections/${state529PlanId}/withdrawal-requests`,
        payload,
      );

      return data.state_529_withdrawal_request;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }

  static async submitWithdrawalRequest({
    withdrawalRequestId,
    state529PlanId,
    payload
  }: SubmitWithdrawalRequest): Promise<{
    id: string;
    status: WithdrawalStatus;
  }> {
    try {
      const {
        data: { data },
      } = await instance.patch(
        `/state-529-connections/${state529PlanId}/withdrawal-requests/${withdrawalRequestId}/submit`,
        payload,
      );

      return data.state_529_withdrawal_request;
    } catch (err: any) {
      Sentry.captureException(err);
      const processedError = err.response.data;
      throw errorMapper(processedError);
    }
  }
}

export default WithdrawalServices;
