/**
 * Sanitizes a given amount by removing any non-numeric characters
 * except for periods and leading minus sign if present, and trims any surrounding whitespace.
 *
 * @param {string | number} amount - The amount to be sanitized, either as a string or number.
 * @returns {string} The sanitized amount as a string, containing only numbers and periods.
 */
export const sanitizeAmount = (amount: string | number): string => {
  return amount
    .toString()
    .replace(/[^0-9.-]+/g, "")
    .replace(/(?!^)-/g, "")
    .trim();
};

/**
 * Formats value to amount in cents
 *
 * @param {string | number} currency - currency can be string or number
 * @returns {number} if valid, returns amount in cents
 */
export const formatAmountToCents = (currency: string | number):number => {
  // Parse the sanitized string to a float
  const floatAmount = parseFloat(sanitizeAmount(currency));

  // Convert to cents and return as an integer
  return floatAmount * 100;
};

/**
 * Formats an amount in cents to a currency string.
 *
 * @param {string | number} amount - The amount in cents, either as a string or a number.
 * @param {boolean} [showDollarSign=false] - A flag indicating whether to include a dollar sign in the output. Defaults to false.
 * @returns {string} The formatted currency string.
 *
 */
export const formatAmountInCentsToCurrency = (
  amount: string | number,
  showDollarSign = false
): string => {
  // Parse the sanitized string to a float
  const floatAmount = parseFloat(sanitizeAmount(amount));

  const dollarAmount = floatAmount / 100;

  return formatCurrency(dollarAmount, showDollarSign);
};

/**
 * Formats a currency value as a string, optionally prepending a dollar sign.
 *
 * @param {string|number} amount - Amount as a string or number
 * @param {boolean} [showDollarSign=true] - A flag indicating whether to include a dollar sign in the output. Defaults to true.
 * @returns {string} The formatted currency value as a string. If `showDollarSign` is true, the string will include a dollar sign; otherwise, it will be just the formatted number with 2 decimal places.
 */
export const formatCurrency = (
  amount: string | number,
  showDollarSign = true
):string => {
  const floatAmount = parseFloat(sanitizeAmount(amount));

  const currency = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(floatAmount);

  return showDollarSign ? `$${currency}` : currency;
};