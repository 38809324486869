import { useTransactionsStore } from "@stores/transactionsStore";

import TransactionsTableRow from "@components/Transactions/TransactionsTableRow";

const TransactionsTable = () => {
  const transactions = useTransactionsStore((state) => state.transactions);

  if (!transactions) return null;

  return (
    <div className="overflow-hidden shadow ring-1 ring-black/5 rounded-lg" data-testid="transactions-table-component">
      <table className="table" data-testid="transactions-table">
        <thead>
          <tr>
            <th scope="col" className="whitespace-nowrap">
              <span className="hidden lg:block">Date</span>
              <span className="block lg:hidden">Details</span>
            </th>

            <th scope="col" className="hidden lg:table-cell">
              To/From
            </th>

            <th scope="col" className="whitespace-nowrap max-sm:text-right">
              Amount
            </th>

            <th scope="col" className="hidden sm:table-cell">
              Beneficiary
            </th>
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction) => (
            <TransactionsTableRow key={transaction.id} transaction={transaction} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsTable;