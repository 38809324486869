import { useMemo } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";

import {
  DEFAULT_QUERY_OPTIONS,
  FOREVER_STALE_QUERY_OPTIONS,
  QUERY_KEY,
  STALE_TIME_5_MINS
} from "@lib/queries/constants";
import { getInstitutions } from "@lib/queries/institutionQueries";
import { getState529Connection, getState529Plans } from "@lib/queries/state529Queries";

import type { Beneficiary, BeneficiaryResponse } from "@lib/types/beneficiary";

type UseFormatBeneficiariesProps = {
  beneficiaries?: BeneficiaryResponse[];
  enable?: boolean;
  gcTime?: number;
}

export const useFormatBeneficiaries = ({
  gcTime,
  beneficiaries = [],
  enable = false,
}: UseFormatBeneficiariesProps) => {
  const enabledQueries = useMemo(() => {
    return enable && !!beneficiaries.length;
  }, [enable, beneficiaries]);

  const updatedQueryOptions = useMemo(() => {
    if (!gcTime) return {
      ...DEFAULT_QUERY_OPTIONS,
      staleTime: STALE_TIME_5_MINS
    };

    return {
      gcTime,
      staleTime: STALE_TIME_5_MINS,
      retry: false,
    };
  }, [gcTime]);

  let formattedBeneficiaries: (null | Beneficiary[]) = null;

  const queryResults = useQueries({
    queries: [
      {
        queryKey: [QUERY_KEY.INSTITUTIONS],
        queryFn: getInstitutions,
        enabled: enabledQueries,
        ...FOREVER_STALE_QUERY_OPTIONS
      },
      {
        queryKey: [QUERY_KEY.STATE_529_PLANS],
        queryFn: getState529Plans,
        enabled: enabledQueries,
        ...FOREVER_STALE_QUERY_OPTIONS
      }
    ]
  });

  const institutions = queryResults[0].data || [];
  const state_529_plans = queryResults[1].data || [];

  const state_529_plan_id = useMemo(() => {
    if (!!beneficiaries
      && !!beneficiaries.length
      && !!beneficiaries[0].state_529_plan_id
    ) return beneficiaries[0].state_529_plan_id;

    return "";
  }, [beneficiaries]);

  const {
    data: state_529_connection,
    isFetching: isState529ConnectionFetching,
    isSuccess: isState529ConnectionSuccess
  } = useQuery({
    queryKey: [QUERY_KEY.STATE_529_CONNECTION, state_529_plan_id],
    queryFn: () => getState529Connection(state_529_plan_id),
    enabled: enabledQueries && !!state_529_plan_id,
    throwOnError: true,
    ...updatedQueryOptions,
  });

  const isSuccess = useMemo(() => {
    if (!enabledQueries
      || !institutions.length
      || !state_529_plans.length
      || (enabledQueries && isState529ConnectionFetching)
    ) return false;

    return true;
  }, [
    enabledQueries,
    institutions,
    state_529_plans,
    isState529ConnectionFetching,
    isState529ConnectionSuccess,
  ]);

  if (enabledQueries && isSuccess) {
    formattedBeneficiaries = beneficiaries.map((beneficiary) => {
      const state_529_plan = state_529_plans.find((plan) => plan.id === beneficiary.state_529_plan_id);
      const enrolled_institutions = new Map();

      beneficiary.enrollments.forEach((enrollment) => {
        const institution = institutions?.find((institution) => institution.id === enrollment.institution_id);

        if (!enrolled_institutions.has(institution?.id)) {
          enrolled_institutions.set(institution?.id, institution);
        }
      });

      return {
        ...beneficiary,
        enrolled_institutions: [...enrolled_institutions.values()],
        state_529_plan: {
          ...state_529_plan,
          state_529_connection
        }
      } as Beneficiary;
    });
  }

  return {
    isFetching: enable && isState529ConnectionFetching,
    isSuccess,
    formattedBeneficiaries
  };
};