import { useEffect, useMemo, type NamedExoticComponent } from "react";
import { useShallow } from "zustand/react/shallow";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  type NavigateOptions,
  type URLSearchParamsInit
} from "react-router-dom";
import { Case, Default, Else, If, Switch, Then } from "react-if";

import BackpackLogo from "assets/images/BackpackLogoHorizontal.svg";

import CheckCircleIcon from "@components/Icons/CheckCircleIcon";
import UserPlusIcon from "@components/Icons/UserPlusIcon";
import SparklesIcon from "@components/Icons/SparklesIcon";
import AcademicCapIcon from "@components/Icons/AcademicCapIcon";
import LinkIcon from "@components/Icons/LinkIcon";

import Spinner from "@components/Spinner/Spinner";
import Modal from "@components/Modal/Modal";
import ProgressStepper, { type ProgressStep } from "@components/Stepper/ProgressStepper";
import StudentDetailsScreen from "@components/Onboarding/screens/StudentDetailsScreen";
import WelcomeScreen from "@components/Onboarding/screens/WelcomeScreen";
import ConfirmAttendanceScreen from "@components/Onboarding/screens/ConfirmAttendanceScreen";
import Link529Screen from "@components/Onboarding/screens/Link529Screen";
import TuitionPayment from "@components/TuitionPayment/TuitionPayment";
import Link529Manual from "@components/Link529/Link529Manual/Link529Manual";
import FinalScreen from "@components/Onboarding/screens/FinalScreen";

import { BASE_ROUTE } from "routes/constants";

import {
  Flow,
  ManualLink529Steps,
  OnboardingSteps
} from "@lib/enums/flows";

import { routeBuilder, type RouterBuilderProps } from "@helpers/routeBuilder";

import { useOnboardingStore } from "@stores/onboardingStore";
import { useTuitionPaymentsStore } from "@stores/tuitionPaymentStore";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useLink529Store } from "@stores/link529Store";

type OnboardingStepIcons = {
  active: NamedExoticComponent;
  inactive: NamedExoticComponent;
  completed: NamedExoticComponent;
};

const PROGRESS_STEPPER_ICONS: Partial<Record<OnboardingSteps, OnboardingStepIcons>> = {
  [OnboardingSteps.WELCOME]: {
    active: SparklesIcon,
    inactive: SparklesIcon,
    completed: CheckCircleIcon,
  },
  [OnboardingSteps.STUDENT_DETAILS]: {
    active: UserPlusIcon,
    inactive: UserPlusIcon,
    completed: CheckCircleIcon,
  },
  [OnboardingSteps.CONFIRM_ATTENDANCE]: {
    active: AcademicCapIcon,
    inactive: AcademicCapIcon,
    completed: CheckCircleIcon,
  },
  [OnboardingSteps.LINK_529]: {
    active: LinkIcon,
    inactive: LinkIcon,
    completed: CheckCircleIcon,
  },
};

const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { step } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const hasInitializedProfile = useUserProfileStore(useShallow((state) => state.initialized));
  const isOnboardingCompleted = useUserProfileStore(useShallow((state) => state.statusComplete.onboarding));

  const currentStep = useOnboardingStore(useShallow((state) => state.step?.name));
  const stepCount = useOnboardingStore(useShallow((state) => state.stepCount));

  const steps = useOnboardingStore(useShallow((state) => state.steps));
  const nestedFlow = useOnboardingStore(useShallow((state) => state.nestedFlow));
  const setNestedFlow = useOnboardingStore(useShallow((state) => state.setNestedFlow));

  const tuitionPaymentStatus = useOnboardingStore(useShallow((state) => state.status[Flow.TUITION_PAYMENT]));
  const manualLinkStatus = useOnboardingStore(useShallow((state) => state.status[Flow.LINK_529]));

  const tuitionFlowParam = useTuitionPaymentsStore(useShallow((state) => state.queryParam));
  const manualLinkParam = useLink529Store(useShallow((state) => state.screen.current));

  const goToPrevTuitionPaymentStep = useTuitionPaymentsStore(useShallow((state) => state.goToPrevStep));
  const resetTuitionFlow = useTuitionPaymentsStore(useShallow((state) => state.reset));

  const initializeOnboarding = useOnboardingStore((state) => state.initializeOnboarding);

  const progressStepperSteps = useMemo(() => {
    return [...steps].map((step) => ({
      ...step,
      ...(PROGRESS_STEPPER_ICONS[step.name] && { icons: PROGRESS_STEPPER_ICONS[step.name] }),
    })) as ProgressStep[];
  }, [steps]);

  const buildSearchParams = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);

    if (tuitionPaymentStatus && stepCount >= 4) {
      updatedSearchParams.set(Flow.TUITION_PAYMENT, tuitionPaymentStatus);
    }

    if (!tuitionPaymentStatus) {
      updatedSearchParams.delete(Flow.TUITION_PAYMENT);
    }

    if (manualLinkStatus === ManualLink529Steps.SKIPPED) {
      updatedSearchParams.set(Flow.LINK_529, manualLinkStatus);
    } else {
      updatedSearchParams.delete(Flow.LINK_529);
    }

    return updatedSearchParams;
  };

  const updateRoute = async () => {
    const stepName = currentStep ? currentStep.toString() : null;
    const updatedSearchParams = await buildSearchParams();

    const routeBuilderParams = {
      routeType: "onboarding",
      step: stepName,
      nestedStep: null,
      searchParams: updatedSearchParams
    } as RouterBuilderProps;

    if (nestedFlow === Flow.LINK_529) {
      routeBuilderParams.nestedStep = manualLinkParam;
    }

    const path = await routeBuilder({ ...routeBuilderParams });

    navigate(path, { replace: true });
  };

  const goToDashboard = (options?: NavigateOptions) => {
    const routeOptions: NavigateOptions = { replace: true, ...options };

    navigate(BASE_ROUTE.dashboard, routeOptions);
  };

  useEffect(() => {
    if (!hasInitializedProfile) return;

    // if a user has completed onboarding
    // redirect back to dashboard
    if (isOnboardingCompleted) {
      return goToDashboard();
    }

    // default state of currentStep is null
    if (!currentStep) {
      // pass along onboarding deep linking url
      const path = location.pathname.startsWith("/onboarding")
        ? `${location.pathname}${location.search}`
        : "";

      initializeOnboarding(path);
    }
  }, [hasInitializedProfile]);

  // navigate to next step
  useEffect(() => {
    // if the current step is done,
    // include a router param to open the link 529 modal
    // to be used in the dashboard after redirect
    if (currentStep === OnboardingSteps.DONE) {
      const options: NavigateOptions = isOnboardingCompleted
        ? { state: { openLink529Modal: true }}
        : {};

      return goToDashboard(options);
    }

    // if onboarding is complete
    // or the current step in the store is the same as the url
    // there is nothing to do
    if (isOnboardingCompleted) return;
    if (currentStep === step) return;

    updateRoute();
  }, [currentStep, navigate]);

  // handle nested flows
  useEffect(() => {
    if (step === OnboardingSteps.WELCOME
      || step === OnboardingSteps.STUDENT_DETAILS
      || step === OnboardingSteps.DONE
    ) return;

    if (nestedFlow === Flow.TUITION_PAYMENT) {
      setSearchParams(tuitionFlowParam as URLSearchParamsInit);
      return;
    }

    if (tuitionPaymentStatus) {
      setSearchParams({ [Flow.TUITION_PAYMENT]: tuitionPaymentStatus }, { replace: true });
    }

    if (nestedFlow === Flow.LINK_529) {
      updateRoute();
      return;
    }
  }, [
    nestedFlow,
    tuitionFlowParam,
    manualLinkParam,
    tuitionPaymentStatus,
    navigate
  ]);

  // handle the effects of the searchParams (url queries) changing
  // aka when users go back and forth between nested flow screens
  useEffect(() => {
    if (!nestedFlow && !searchParams.size) return;

    // if user ends up on a screen without a tuition payment param
    // while in the tuition payment flow
    // reset the tuition payment flow
    if (!searchParams.size && nestedFlow === Flow.TUITION_PAYMENT) {
      resetTuitionFlow();
      setNestedFlow(null);
      return;
    }

    // if the user clicks the back button on the browser
    // while they were in the tuition payment flow
    // go back to the first step of tuition payment flow
    if ((searchParams.get(Flow.TUITION_PAYMENT) !== tuitionFlowParam[Flow.TUITION_PAYMENT])) {
      goToPrevTuitionPaymentStep();
    }
  }, [searchParams, navigate]);

  if (isOnboardingCompleted || !hasInitializedProfile) return null;

  return (
    <Modal
      className="max-w-4xl sm:max-h-[calc(100%-20px)] h-full"
      maxWidth="xl"
      open={!isOnboardingCompleted}
      disableBackdrop={true}
      showCloseButton={false}
    >
      <div className="bg-white flex flex-col sm:flex-row max-sm:mb-6 p-6 sm:px-4 sm:py-3.5 size-full max-w-[930px] sm:h-[765px] max-sm:overflow-auto">
        <div className="sm:bg-gray-100 rounded-2xl sm:max-lg:basis-2/5 lg:max-w-[392px] w-full shrink-0 sm:h-full">
          <img
            alt="Backpack Logo"
            className="w-28 h-auto shrink-0 sm:hidden"
            src={BackpackLogo}
          />

          <div className="hidden sm:flex flex-col items-center justify-between pt-14 pb-9 px-7 h-full">
            <div className="sm:basis-1/2 flex flex-col justify-center sm:justify-end w-full">
              <ProgressStepper
                active={stepCount || 1}
                steps={progressStepperSteps}
                className="w-full self-start"
              />
            </div>

            <img
              alt="Backpack Logo"
              className="w-36 h-auto shrink-0 mb-1.5 max-sm:hidden"
              src={BackpackLogo}
            />
          </div>
        </div>

        <div className="mt-5 sm:mx-10 sm:mt-14 sm:mb-9 md:ml-12.5 md:mr-14 max-sm:h-full w-full">
          <Switch>
            <Case condition={currentStep === OnboardingSteps.WELCOME}>
              <WelcomeScreen />
            </Case>

            <Case condition={currentStep === OnboardingSteps.STUDENT_DETAILS}>
              <StudentDetailsScreen />
            </Case>

            <Case condition={currentStep === OnboardingSteps.CONFIRM_ATTENDANCE}>
              <If condition={nestedFlow === Flow.TUITION_PAYMENT}>
                <Then>
                  <TuitionPayment />
                </Then>

                <Else>
                  <ConfirmAttendanceScreen />
                </Else>
              </If>
            </Case>

            <Case condition={currentStep === OnboardingSteps.LINK_529}>
              <If condition={nestedFlow === Flow.LINK_529}>
                <Then>
                  <Link529Manual />
                </Then>

                <Else>
                  <Link529Screen />
                </Else>
              </If>
            </Case>

            <Case condition={currentStep === OnboardingSteps.FINAL_SCREEN}>
              <FinalScreen />
            </Case>

            <Default>
              <div className="size-full min-w-96 flex flex-col items-center justify-center">
                <Spinner size="lg" />
              </div>
            </Default>
          </Switch>
        </div>
      </div>
    </Modal>
  );
};

export default Onboarding;