import Chip from "@components/Chip/Chip";
import { useTransactionsStore } from "@stores/transactionsStore";
import { startCase } from "utils/formats";

const TransactionsTableFilterChips = () => {
  const hasFilters = useTransactionsStore((state) => !!state.appliedFiltersAmt);

  const transactionTypeFilter = useTransactionsStore((state) => state.filters.type);
  const typeFilter = startCase(transactionTypeFilter);

  const transactionDateFilter = useTransactionsStore((state) => state.filters.date);
  const dateFilterStart = useTransactionsStore((state) => state.filters.date?.startDate);
  const dateFilterEnd = useTransactionsStore((state) => state.filters.date?.endDate);
  const isDateRange = useTransactionsStore((state) => state.filters.date?.startDate && state.filters.date?.endDate);

  const removeFilter = useTransactionsStore((state) => state.removeFilter);

  const dateOptions: Intl.DateTimeFormatOptions = { month: "short", day: "numeric", year: undefined };

  if (isDateRange && (dateFilterStart?.getFullYear() !== dateFilterEnd?.getFullYear()) ) {
    dateOptions.year = "numeric";
  }

  const startDate = dateFilterStart
    ? dateFilterStart.toLocaleDateString("en-US", dateOptions)
    : null;

  const endDate = dateFilterEnd
    ? dateFilterEnd.toLocaleDateString("en-US", dateOptions)
    : null;

  let dateFilter;

  if ((startDate && !endDate)
    || (!startDate && endDate)
    || (startDate && endDate && (startDate === endDate))) {
    dateFilter = startDate || endDate;
  }

  if (isDateRange && (startDate !== endDate)) {
    dateFilter = `${startDate} - ${endDate}`;
  }

  const showDateChip = dateFilterStart && dateFilterEnd ? dateFilterStart <= dateFilterEnd : true;

  if (!hasFilters) return null;

  return (
    <div className="flex items-center gap-2.5 flex-wrap xl:flex-nowrap xl:h-9" data-testid="transaction-filter-chips">
      {transactionTypeFilter !== "ALL" &&
        <Chip
          aria-description={`transactions filtered by ${typeFilter}`}
          buttonLabel={`Remove ${typeFilter} filter`}
          onRemoveChip={() => removeFilter("type")}
        >
          {typeFilter}
        </Chip>
      }

      {!!transactionDateFilter && showDateChip &&
        <Chip
          aria-description={`transactions filtered by ${dateFilter}`}
          buttonLabel="Remove date filter"
          onRemoveChip={() => removeFilter("date")}
        >
          {dateFilter}
        </Chip>
      }

      <button
        type="button"
        className="rounded bg-white px-2.5 py-1.5 text-xs font-medium text-blue hover:bg-sky-50"
        onClick={() => removeFilter()}
      >
        Reset Filters
      </button>
    </div>
  );
};

export default TransactionsTableFilterChips;