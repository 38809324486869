import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import Tooltip from "components/Tooltip";
import AccountDetailCard from "components/AccountDetailsCard";

import type { AccountDetailCardProps } from "components/AccountDetailsCard";

type DetailBlockProps = AccountDetailCardProps & {
  title: string;
};

const TooltipText = () => (
  <Stack>
    <Typography variant="subtitle1" fontWeight="bold">
      Need to change something?
    </Typography>
    <Typography variant="caption">
      Click “update” to start a chat with our support team, or email support@backpack529.com and our
      team will help you with your request.
    </Typography>
  </Stack>
);

const DetailBlock: FC<DetailBlockProps> = ({ title, properties }) => {
  return (
    <div className="flex flex-col flex-1 gap-4">
      <header className="flex justify-between items-center">
        <p className="text-base flex items-start gap-1">
          {title}
          <Tooltip placement="right-start" title={<TooltipText />}>
            <HelpOutlineIcon className="text-xxxs" />
          </Tooltip>
        </p>

        <a
          href="https://www.backpack529.com/contact"
          id="intercom_support"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-xs font-bold flex items-center text-blue underline-none">
            Update <KeyboardArrowRightIcon className="text-base" />
          </p>
        </a>
      </header>

      <AccountDetailCard properties={properties} />
    </div>
  );
};

export default DetailBlock;
