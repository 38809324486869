import AccountDetailsNumbers from "@components/AccountDetails/AccountDetailsNumbers";
import Card from "@components/Card";
import BackpackIcon from "@components/Icons/BackpackIcon";

type AccountDetailsCardProps = {
  className?: string;
  showIcon?: boolean;
  title?: string;
}

const AccountDetailsCard = ({
  className,
  showIcon = false,
  title = "Your Backpack account details"
}: AccountDetailsCardProps) => {

  return (
    <Card containerClassName={className} className="p-5">
      <h3 className="flex items-center gap-1.5 text-sm">
        {showIcon && <BackpackIcon />}{title}
      </h3>

      <AccountDetailsNumbers
        showAccountType
        containerClassName="pt-4"
      />
    </Card>
  );
};

export default AccountDetailsCard;